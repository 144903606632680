import "./HcpDocumentTabComponent.scss";
import CardComponent from "../../../../shared/components/card/CardComponent";
import {ImageConfig, Misc} from "../../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {useParams} from "react-router-dom";
import ToolTipComponent from "../../../../shared/components/tool-tip/ToolTipComponent";
import LinkComponent from "../../../../shared/components/link/LinkComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import DrawerComponent from "../../../../shared/components/drawer/DrawerComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FilePickerComponent from "../../../../shared/components/file-picker/FilePickerComponent";
import _ from "lodash";
import ErrorComponent from "../../../../shared/components/error/ErrorComponent";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import FormikDatePickerComponent
    from "../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import * as Yup from "yup";
import {ImageSupportedFile, OtherFilesSupported} from "../../../../data";

interface HcpDocumentTabComponentProps {
    onDownload: (fileKey: string) => void;
    onDelete: (document: string, fileKey: string) => void;
    documentList: any[];
    getDocumentLists: () => void;
}

const allDocumentList = [{
    document_name: "Resume",
    document_type: "Resume",
    document_key: "resume",
    expiry_date: null,
    is_expiry_date: false,
},
    {
        document_name: "Nursing License",
        document_type: "Nursing License",
        document_key: "nursing_license",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "State ID / Drivers License",
        document_type: "State ID / Drivers License",
        document_key: "state_drivers",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "CPR Cert",
        document_type: "CPR Cert",
        document_key: "cpr_cert",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "BLS Cert",
        document_type: "BLS Cert",
        document_key: "bls_cert",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "Vaccination Record",
        document_type: "Vaccination Record",
        document_key: "vaccination_record",
        is_active: false,
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "Covid Vaccination Record",
        document_type: "Covid Vaccination Record",
        document_key: "covid_vaccination",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "Covid Booster",
        document_type: "Covid Booster",
        document_key: "covid_booster",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "Physical Exam",
        document_type: "Physical Exam",
        document_key: "physical_exam",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "TB Exam",
        document_type: "TB Exam",
        document_key: "tb_exam",
        expiry_date: null,
        is_expiry_date: true,
    },
    {
        document_name: "Background Check",
        document_type: "Background Check",
        document_key: "background_check",
        expiry_date: null,
        is_expiry_date: false,
    },
    {
        document_name: "DOJ Livescan",
        document_type: "DOJ Livescan",
        document_key: "doj_livescan",
        expiry_date: null,
        is_expiry_date: false,
    },

    {
        document_name: "Profile Picture",
        document_type: "Profile Picture",
        document_key: "profile_picture",
        expiry_date: null,
        is_expiry_date: false,
    },];

const documentValidationSchema = Yup.object({
        document: Yup.mixed().required("File is required"),
        expiry_date: Yup.mixed().when('attachment_type', {
            is: (attachment: any) => {
                return ["tb_test", "cpr_bls_card", "driver_license", "covid_certificate"].includes(attachment);
            },
            then: Yup.mixed().required("Expiry Date is required"),
        }),
        // expiry_date: Yup.mixed().required("Expected Expiry Date is required")
    }
);

interface contractFormInitialValuesFormSchema {
    document: File | null,
    expiry_date?: any,
    attachment_type: string,
}

const documentFormInitialValues: contractFormInitialValuesFormSchema = {
    document: null,
    attachment_type: "",
};

const HcpDocumentTabComponent = (props: HcpDocumentTabComponentProps) => {
    const {onDownload, onDelete, documentList, getDocumentLists} = props;
    const {hcp_id} = useParams();
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [isError, setIsError] = useState<boolean>(false);
    const [drawerType, setDrawerType] = useState<string>("");
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [initialValuesDocument, setInitialValuesDocument] = useState<any>(documentFormInitialValues);
    const onFileUploadRef = React.useRef<any>(null);
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
    const [attachmentType, setAttachmentType] = useState<string>("");
    const [isFileUploadLoading, setIsFileUploadLoading] = useState<any>(false);

    const onFileUpload = useCallback((values: any, {
        setErrors,
        setSubmitting
    }: FormikHelpers<any>) => {
        setSubmitting(true);
        setIsFileUploadLoading(true);
        const formData = CommonService.getFormDataFromJSON(values);

        if (values?.expiry_date) {
            formData.append("expiry_date", CommonService.convertDateFormat(values?.expiry_date, "YYYY-MM-DD"));
        }
        CommonService._hcp.uploadHcpDocumentAPICall(hcp_id, formData)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getDocumentLists();
                handleDrawerClose();
                setIsFileUploadLoading(false);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setIsFileUploadLoading(false);
            }).finally(() => {
            setSubmitting(false);
            setIsFileUploadLoading(false);
        });
    }, [hcp_id, getDocumentLists]);

    const handleDrawerOpen = (type: string) => {
        setDrawerType(type);
        setIsDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };
    useEffect(() => {
        if (["TB Exam", "Physical Exam", "BLS Cert", "CPR Cert", "Nursing License"].includes(attachmentType)) {
            setInitialValuesDocument({
                ...initialValuesDocument,
                expiry_date: ""
            });
        } else {
            setInitialValuesDocument(documentFormInitialValues);
        }
    }, [attachmentType, initialValuesDocument]);

    return (
        <>
            <div className={'hcp-document-tab-component'}>
                <CardComponent>
                    <div className={'card-header-nw'}>
                        Documents
                    </div>
                    <div className="details-screen hcp-details-document-wrapper">
                        {
                            documentList.map((document: any, index: number) => {
                                const transformedName = CommonService.transformName(document?.attachment_type);
                                return (
                                    <div className="document-view-card" key={index}>
                                        <div className="document-type">{transformedName}</div>
                                        <div className="document-name">{<ToolTipComponent
                                            tooltip={document?.file_name || "-"}>
                                            {
                                                <div className={"ellipses-text"}>
                                                    {document?.file_name || "-"}
                                                </div>
                                            }
                                        </ToolTipComponent>}</div>
                                        <div className="document-expiry">{document?.expiry_date}</div>
                                        <div className="document-actions">
                                            <LinkComponent onClick={() => {
                                                onDownload(document?.file_key);
                                            }}>
                                                <ImageConfig.DownloadIcon/>
                                            </LinkComponent>
                                            <div className="delete-box"
                                                 onClick={() => onDelete(transformedName, document?.id)}
                                            >
                                                <ImageConfig.DeleteIconPreview/>
                                            </div>
                                            <div
                                                className="preview-box"
                                                onClick={() => {
                                                    window.open(document?.url, '_blank');
                                                }}
                                            >
                                                <ImageConfig.PreviewIconPdf/>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        {
                            allDocumentList.filter((allDocument: any) => {
                                return !documentList.some((fetched: any) => {
                                    return fetched?.attachment_type === allDocument?.document_type;
                                });
                            }).map((document: any, index: number) => {
                                const transformedName = CommonService.transformName(document?.document_name);
                                return (
                                    <div
                                        key={index}
                                        className="document-upload-card">
                                        <div className="document-type">{transformedName}</div>
                                        <div className="document-actions">
                                            <ButtonComponent
                                                variant={"outlined"}
                                                className={"upload-button"}
                                                onClick={() => {
                                                    handleDrawerOpen(document?.document_type);
                                                    setAttachmentType(document?.document_type);
                                                }}
                                            >
                                                Upload
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </CardComponent>
            </div>
            <DrawerComponent
                isOpen={isDrawerOpen}
                className={"edit-drawer"}
            >
                <>
                    <div className={'d-flex justify-content-space-between pdd-20'}>
                        <div className={'form-header-text'}>
                            {drawerType}
                        </div>
                        <div className={'icon-button'}>
                            <ImageConfig.CrossIcon onClick={handleDrawerClose}/>
                        </div>
                    </div>
                    <HorizontalLineComponent/>
                    <div className="edit-contract-wrapper mrg-left-15 mrg-right-15">
                        <div className="edit-content">
                            <Formik
                                validationSchema={documentValidationSchema}
                                initialValues={initialValuesDocument}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize={true}
                                validateOnMount={true}
                                onSubmit={onFileUpload}
                                innerRef={onFileUploadRef}
                            >
                                {({
                                      values,
                                      errors,
                                      validateForm,
                                      touched,
                                      isValid,
                                      setFieldValue,
                                      isSubmitting
                                  }) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                        if (values.document) {
                                            setIsFileSelected(true);
                                        } else {
                                            setIsFileSelected(false);
                                        }
                                        setFieldValue('attachment_type', attachmentType);
                                    }, [validateForm, values, setFieldValue]);
                                    return (
                                        <Form className="t-form" noValidate={true}>
                                            <div
                                                className="add-component-content">
                                                {
                                                    (!values.document) && <>
                                                        <FilePickerComponent maxFileCount={1}
                                                                             height={'470px'}
                                                                             id={"sc_upload_btn"}
                                                                             onFilesDrop={(acceptedFiles, rejectedFiles) => {
                                                                                 if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                     const file = acceptedFiles[0];
                                                                                     setFieldValue('document', file);
                                                                                 }
                                                                             }}
                                                                             acceptedFileTypes={drawerType === "Profile Picture" ? ImageSupportedFile : OtherFilesSupported}
                                                                             uploadDescription={"(upload only one file)"}
                                                                             acceptedFilesText={drawerType !== "Profile Picture" ? "Accepted file types: PDF, JPG, PNG" : "Accepted file types: JPG, PNG"}
                                                        />
                                                        {
                                                            (_.get(touched, "document") && !!(_.get(errors, "document"))) &&
                                                            <ErrorComponent
                                                                errorText={(_.get(errors, "document"))}/>
                                                        }
                                                    </>
                                                }
                                                <>
                                                    {
                                                        (values.document) && <>
                                                            <div className="file-preview-wrapper">
                                                                <div className="file-name">
                                                                    {values.document.name}
                                                                </div>
                                                                <div className="action-button">
                                                                    <div
                                                                        className={'center-element mrg-left-25 contract-action-text icon-button delete-button'}
                                                                        onClick={() => {
                                                                            setFieldValue('document', '');
                                                                        }}
                                                                    >
                                                                        <ImageConfig.DeleteOutlineIcon/><span
                                                                        className={'mrg-left-10'}>Delete</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                ["TB Exam", "Physical Exam", "BLS Cert", "CPR Cert", "Nursing License"].includes(attachmentType) && (
                                                                    <>
                                                                        <HorizontalLineComponent/>
                                                                        <div className={'d-flex'}>
                                                                            <div className={'flex-1'}>
                                                                                <LabelComponent title={'Expiry Date'}/>
                                                                                <Field name={'expiry_date'}>
                                                                                    {
                                                                                        (field: FieldProps) => (
                                                                                            <FormikDatePickerComponent
                                                                                                // minDate={new Date()}
                                                                                                placeholder={'Select Date'}
                                                                                                required={true}
                                                                                                formikField={field}
                                                                                                fullWidth={true}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                </Field>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    }
                                                </>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                        <div className="action-button mrg-top-100">
                            {
                                isFileSelected && <ButtonComponent
                                    className={"save-btn"}
                                    isLoading={onFileUploadRef?.current?.isSubmitting || isFileUploadLoading}
                                    disabled={onFileUploadRef?.current?.isSubmitting || isFileUploadLoading}
                                    onClick={() => {
                                        if (onFileUploadRef.current) {
                                            onFileUploadRef.current.submitForm();
                                        }
                                    }}
                                >
                                    Save Details
                                </ButtonComponent>
                            }
                        </div>
                    </div>
                </>
            </DrawerComponent>
        </>
    );

};

export default HcpDocumentTabComponent;
