import "./TextWithIconComponent.scss";
import React from "react";

interface TextWithIconComponentProps {
    children?: React.ReactNode;
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;

}

const TextWithIconComponent = (props: TextWithIconComponentProps) => {
    const {children, suffix, prefix} = props;

    return (
        <div className={'text-with-icon-component'}>
            {prefix && <div className="prefix">{prefix}</div>}
            <div className="content">{children}</div>
            {suffix && <div className="suffix">{suffix}</div>}
        </div>
    );

};

export default TextWithIconComponent;
