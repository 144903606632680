import {call, put, takeEvery} from "redux-saga/effects";
import {CommonService} from "../../shared/services";
import {
    GET_ADDITIONAL_RATES,
    GET_HCP_TYPES, GET_STAFF_SPECIALITY_LIST, GET_STATE_LIST,
    setAdditionalRates,
    setHcpTypesList,
    setStaffSpecialityList, setStateList
} from "../actions/meta.action";


function* getHcpList() {
    try {
        //@ts-ignore
        const resp = yield call(CommonService._meta.hcpTypesListAPICall);
        const modifiedData = resp?.data?.map((item: any) => {
            const {_id, title, ...rest} = item;

            return {
                ...rest,
                title: title,
                code: _id,
            };
        });
        yield put(setHcpTypesList(modifiedData));
    } catch (error: any) {
        yield put(setHcpTypesList([]));
    }
}

function* getStateList() {
    try {
        //@ts-ignore
        const resp = yield call(CommonService._meta.stateListAPICall);
        const modifiedData = resp?.data?.map((item: any) => {
            const {code, title, ...rest} = item;

            return {
                ...rest,
                title: title,
                code: code,
            };
        });
        yield put(setStateList(modifiedData));
    } catch (error: any) {
        yield put(setStateList([]));
    }
}

function* getAdditionalRatesTypeList() {
    try {
        //@ts-ignore
        const resp = yield call(CommonService._meta.additionalRatesListAPICall);
        yield put(setAdditionalRates(resp?.data || []));
    } catch (error: any) {
        yield put(setAdditionalRates([]));
    }
}

function* getStaffSpecialityList(action: any) {
    console.log("api calling")
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.staffTypeSpecialityListAPICall, action.staffTypeId);
        const modifiedData = resp?.data?.map((item: any) => {
            const {_id, name, title, ...rest} = item; // Destructure the "name" property and keep the rest of the object

            return {
                ...rest,     // Keep the other properties unchanged
                name: name,
                title: title,
                code: _id,// Add the new key "newName" with the value of the old "name"
            };
        });
        yield put(setStaffSpecialityList(modifiedData || []));
    } catch (error: any) {
        yield put(setStaffSpecialityList([]));
    }
}


export default function* metaDataSaga() {
    yield takeEvery(GET_HCP_TYPES, getHcpList);
    yield takeEvery(GET_ADDITIONAL_RATES, getAdditionalRatesTypeList);
    yield takeEvery(GET_STAFF_SPECIALITY_LIST, getStaffSpecialityList);
    yield takeEvery(GET_STATE_LIST, getStateList)
}
