import React, {useMemo} from 'react';
import {CommonService} from "../../../shared/services";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {ImageConfig} from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import {TableColumnsType} from "antd";

export const useHCPListColumn = (onHCPActivateDeactivate: any): TableColumnsType => {
    return useMemo(() => {
        return [
            {
                title: "Staff Name",
                fixed: 'left',
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.first_name?.length + item?.last_name?.length) > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.first_name + " " + item?.last_name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Staff ID",
                fixed: 'left',
                width: 200,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.hcp_uniqueId)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.hcp_uniqueId}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.hcp_uniqueId}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.hcp_uniqueId || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Email ID",
                width: 200,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.email)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.email}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.email}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.email || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Onboarded On",
                width: 137,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>{CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: "Staff Type",
                width:250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.hcp_type_details?.title)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.hcp_type_details?.title}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.hcp_type_details?.title}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.hcp_type_details?.title || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: "Staff Type",
                width:120,
                render: (item: any) => {
                    return <>
                        {item?.is_float ? <>
                            <div style={{
                                color: "#37B96B"
                            }}>Floating
                            </div>
                        </> : <>
                            <div style={{
                                color: "#518FC9"
                            }}>Internal
                            </div>
                        </>}
                    </>;
                }
            },
            {
                title: "Phone Number",
                key: 'phone-number',
                dataIndex: 'phone_number',
                width: 140,
                render: (item: any) => {
                    return <>
                        {CommonService.formatPhoneNumber(item?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                    </>;
                }
            },
            {
                title: "Location",
                key: 'location',
                dataIndex: 'location',
                width: 200,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.address?.city)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.address?.city}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.address?.city}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.address?.city || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: 'Active/Inactive',
                align: 'center',
                width: 140,
                render: (item: any) => {
                    return <div className={'display-center'}>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onHCPActivateDeactivate(item);
                        }}/>
                    </div>;
                }
            },
            {
                title: "View Details",
                align: 'center',
                width: 130,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent route={CommonService._routeConfig.hcpViewDetailsRoute(item?._id)}>
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ];
    }, [onHCPActivateDeactivate]);
};