import "./InputComponent.scss";
import {useCallback} from "react";
import {IInputFieldProps} from "../../../models/form-controls.model";
import {CommonService} from "../../../services";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";
import LabelComponent from "../../label/LabelComponent";

export interface InputComponentProps extends IInputFieldProps {
    name?: string;
    value?: string;
    errorMessage?: any;
    hasError?: boolean;
    maxLength?: number;
    minValue?: number;
}

const InputComponent = (props: InputComponentProps) => {

    const {
        label,
        titleCase,
        errorMessage,
        hasError,
        className,
        inputProps,
        disabled,
        required,
        value,
        validationPattern,
        fullWidth,
        size,
        maxLength,
        onChange,
        onClick,
        suffix,
        prefix,
        minValue,
        labelTooltip,
        ...otherProps
    } = props;

    const inputSize = props.size || "medium";
    const type = props.type || "text";
    const placeholder = props.placeholder || label;


    const handleOnChange = useCallback((event: any) => {
        let nextValue = event.target.value;
        // Truncate the value if it exceeds maxLength
        if (maxLength && nextValue.length > maxLength) {
            nextValue = nextValue.slice(0, maxLength);
        }
        if (titleCase) {
            nextValue = CommonService.Capitalize(nextValue);
        }
        if (onChange) {
            if (validationPattern) {
                const reg = RegExp(validationPattern);
                if (nextValue === "" || reg.test(nextValue)) {
                    onChange(nextValue);
                } else {
                    // console.log(nextValue, reg, reg.test(nextValue), "regex failed");
                }
            } else {
                onChange(nextValue);
            }
        }
    }, [maxLength, titleCase, onChange, validationPattern]);

    return (
        <div className={`input-component ${fullWidth ? "fullWidth" : ''} ${disabled ? 'disabled' : ''}`}>
            {label && <LabelComponent title={label} required={required} tooltip={labelTooltip}/>}
            <input type={type}
                   min={minValue}
                   maxLength={maxLength}
                   placeholder={placeholder}
                   required={required}
                   onClick={onClick}
                   value={value || ""}
                   disabled={disabled}
                   className={'input-field ' + (hasError ? "has-error" : '') + (suffix ? "suffix-padding" : 'input-padding') + ' ' + className + ' ' + inputSize}
                   onChange={(event) => {
                       handleOnChange(event);
                   }}
                   {...inputProps}
                   {...otherProps}
            />
            <>
                {
                    prefix && <span className="input-prefix" onClick={onClick}>{prefix}</span>
                }
                {
                    suffix && <span className="input-suffix" onClick={onClick}>{suffix}</span>
                }
            </>
            {(errorMessage && hasError) && (
                <ErrorTextComponent error={errorMessage}/>
            )}
        </div>
    );

};

export default InputComponent;
