import {combineReducers} from "redux";
import AccountReducer, {IAccountReducerState} from "./account.reducer";
import NavigationReducer, {INavigationReducerState} from "./navigation.reducer";
import StaticDataReducer, {IStaticDataReducerState} from "./static-data.reducer";
import UserReducer, {IUserReducerState} from "./user.reducer";
import PropertyReducer, {IPropertyReducerState} from "./property.reducer";
import MetaDataReducer, {IMetaDataReducerState} from "./meta.reducer";


export interface IRootReducerState {
    account: IAccountReducerState,
    navigation: INavigationReducerState,
    staticData: IStaticDataReducerState,
    user: IUserReducerState,
    property:IPropertyReducerState,
    meta:IMetaDataReducerState
}

const rootReducer = combineReducers({
    account: AccountReducer,
    navigation: NavigationReducer,
    staticData: StaticDataReducer,
    user: UserReducer,
    property: PropertyReducer,
    meta:MetaDataReducer
});

export default rootReducer;
