import './DatePickerComponent.scss';
import React, {useCallback, useEffect, useState} from 'react';
import FormControl from "@mui/material/FormControl";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {FormHelperText, TextField, TextFieldProps} from "@mui/material";
import {ITimePickerProps} from "../../../models/form-controls.model";
import {DatePicker} from "@mui/x-date-pickers";


interface DatePickerComponentProps extends ITimePickerProps {
    name: string;
    onChange?: (time: string) => void;
    hasError?: boolean;
    errorMessage?: any;
    value?: any;
    size?: 'small' | 'medium';
    color?: 'primary' | 'secondary';
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean
}

const DatePickerComponent = (props: DatePickerComponentProps) => {

    const {
        name,
        id,
        label,
        required,
        hasError,
        errorMessage,
        size,
        fullWidth,
        disabled
    } = props;

    const [value, setValue] = useState<string | any>('');
    //  const [open, setOpen] = useState<boolean>(false);

    let {placeholder, color, variant, onChange} = props;
    if (!placeholder) placeholder = label;
    if (!variant) variant = "outlined";

    const handleChange = useCallback((newValue: string | null) => {
        console.log(newValue)
        setValue(newValue);
        if (onChange) onChange(newValue || '');
    }, [onChange, setValue]);

    useEffect(() => {
        setValue(props.value ? new Date(props.value) : null);
    }, [props.value]);

    return (
        <FormControl variant={variant}
                     className="date-picker-component-wrapper"
                     fullWidth={fullWidth}
                     error={hasError}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/*<div className={'clock-icon'}>*/}
                {/*    <ImageConfig.CalendarTimePicker/>*/}
                {/*</div>*/}
                <DatePicker closeOnSelect={true}
                            renderInput={
                                (params: JSX.IntrinsicAttributes & TextFieldProps) => {
                                    // console.log({...params})
                                    return (<TextField
                                        {...params}
                                        required={required}
                                        className={'time-picker-input'}
                                        id={id}
                                        // value={(moment(value).format('hh:mm a'))}
                                        fullWidth={fullWidth}
                                        size={size}
                                        name={name}
                                        color={color}
                                        placeholder={placeholder}
                                        error={hasError}
                                    />);
                                }
                            }
                            value={value}
                            onChange={handleChange}
                            label={label}
                            disabled={disabled}
                />
            </LocalizationProvider>
            <FormHelperText>
                {hasError && errorMessage}
            </FormHelperText>
        </FormControl>
    );
};

export default DatePickerComponent;
