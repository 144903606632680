import React, {useCallback, useEffect, useRef, useState} from 'react';
import './AntTableComponent.scss';
import {CommonService} from "../../services";
import {IAPIResponseType} from "../../models/api.model";
import {AXIOS_REQUEST_CANCELLED} from "../../services/api.service";
import _ from "lodash";
import {Table, TableProps} from "antd";
import Lottie from "react-lottie";
import loadingAnimationData from "../../../assets/animations/Loading.json";

interface DataType {
    key: React.Key;
}

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

interface AntTableComponentProps {
    extraPayload?: any;
    method?: any;
    url?: any;
    isRowSelection?: any;
    columns?: any;
    selectedRowKeys?: any;
    setSelectedRowKeys?: any
    noDataText?:any;
    data?:any[];
}


const AntTableComponent = (props: AntTableComponentProps) => {
    const {url, method, extraPayload, isRowSelection, columns, selectedRowKeys, setSelectedRowKeys,noDataText,data:propData} = props;
    //   const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [globalSelectedRows, setGlobalSelectedRows] = useState<any[]>([]);  // Track globally selected rows
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 15, total: 0});
    const APICallSubscription = useRef<any>(null);
    const [data, setData] = useState<any>([]);

    const getListData = useCallback(() => {
        if (propData) {
            // If propData is available, skip API call and set it directly
            setData(propData.map((item, index) => ({ ...item, key: item.key || index })));
            setPagination((prev) => ({
                ...prev,
                total: propData.length,
            }));
            return;
        }
        const cancelTokenSource = CommonService.getCancelToken();
        const payload = _.cloneDeep({...extraPayload, page: pagination.current, limit: pagination.pageSize});
        let apiCall = method === "post" ? CommonService._api.post : CommonService._api.get;
        if (APICallSubscription.current) {
            APICallSubscription.current.cancel();
        }
        APICallSubscription.current = cancelTokenSource;
        setIsDataLoading(true);
        apiCall(url, payload, {}, {cancelToken: cancelTokenSource.token})
            .then((response: IAPIResponseType<any>) => {
                if (response.data) {
                    const modifiedData = response.data.docs.map((item: any) => {
                        const {...rest} = item;
                        return {...rest};
                    });
                    // Update the data and pagination
                    setData(modifiedData);
                    setPagination((prev) => ({
                        ...prev,
                        total: response.data.total || 0, // Ensure this matches the total number of records
                    }));
                }
                setIsDataLoading(false);
            })
            .catch((error) => {
                if (error.reason !== AXIOS_REQUEST_CANCELLED) {
                    setData([]);
                    setIsDataLoading(false);
                }
            });
        //eslint-disable-next-line
    }, [extraPayload, method, url, pagination.current, pagination.pageSize]);

    useEffect(() => {
        getListData();
    }, [getListData]);

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: DataType[]) => {
        // Merge selected rows with global state
        const mergedSelectedRows = [...globalSelectedRows, ...selectedRows].filter(
            (row, index, self) => self.findIndex(r => r.key === row.key) === index
        );

        setGlobalSelectedRows(mergedSelectedRows);  // Update globally selected rows
        setSelectedRowKeys(newSelectedRowKeys);  // Update local selected row keys for the current page
    };

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,  // Preserves the selected row keys across pages
    };

    const handleTableChange = useCallback((pagination: any) => {
        setPagination(pagination);
    }, []);


    const loadingRow = (
        <tr>
            <td colSpan={columns.length} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '450%' }}>
                <div className={'data-loading-wrapper'}>
                    <div className="loader">
                        {/*<LoaderComponent type={"spinner"} color={"primary"}/>*/}
                        <Lottie width={200} height={200} speed={1}
                                options={{animationData: loadingAnimationData}}/>
                    </div>
                </div>
            </td>
        </tr>
    );

    return (
        <div className="responsive-table-container">
            <Table
                bordered
                size={'middle'}
                rowSelection={isRowSelection ? rowSelection : undefined}
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['15', '50', "100", "150"],
                    total: pagination.total,
                    position:['bottomLeft'],
                    showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={handleTableChange}  // This will trigger on page change or page size change
                scroll={{y: 240, x: '100%'}}
                locale={{
                    emptyText: isDataLoading? '' : noDataText? noDataText : "No Data Found", // Show loadingRow if loading
                }}
                loading={isDataLoading ? {
                    indicator: loadingRow,
                    spinning: isDataLoading
                } : false}
            />
        </div>
    );
};

export default AntTableComponent;

//use case
// if there is no selection with checkbox
// <AntTableComponent columns={columns}/>

// if there is  selection with checkbox
// <AntTableComponent columns={columns} isRowSelection={true}/>
