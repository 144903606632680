import React, {useMemo} from 'react';
import ToolTipComponent from "../components/tool-tip/ToolTipComponent";
import {CommonService} from "../services";
import {shiftTypeLayoutRepresentation} from "../../data";
import {TableColumnsType} from "antd";

export const useUserListColumn = (): TableColumnsType => {
    return useMemo(() => {
        return [
            {
                title: 'Shift Date',
                width: 150,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return <>{CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title:'Shift Time',
                width: 150,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_timings) {
                        return <>{CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.start_time) + "-" + CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.end_time)}</>;
                    } else {
                        return <>{CommonService.convertMinutesToTime(item?.expected?.shift_start_time) + "-" + CommonService.convertMinutesToTime(item?.expected?.shift_end_time)}</>;
                    }
                }
            },
            {
                title:'Wing',
                width: 200,
                align: 'left',
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.facility_wing_details?.name)?.length > 10 ?
                                    <ToolTipComponent
                                        tooltip={item?.facility_wing_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-calender-card-data property-name"}>
                                            {item?.facility_wing_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.facility_wing_details?.name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: 'Shift Type',
                key: 'shift_type',
                dataIndex: 'shift_type',
                width: 200,
                render: (item: any) => {
                    return <div className={'d-flex justify-content-center align-items-center'}>
                        {
                            (() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === item?.shift_type);
                                if (foundShiftType) {
                                    return <>
                                        <div
                                            className={'mrg-right-5 mrg-top-5'}>
                                            {foundShiftType.icon}
                                        </div>
                                        <div className={foundShiftType.color}>
                                            {item?.shift_type}
                                        </div>
                                    </>;
                                } else {
                                    return 'N/A';
                                }
                            })()
                        }
                    </div>;
                }
            },

        ];
    }, []);
};