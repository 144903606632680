import {Outlet} from "react-router-dom";

interface HcpManagementComponentProps {

}

const HcpManagementComponent = (props: HcpManagementComponentProps) => {
    return (
        <>
            <Outlet/>
        </>
    );
};

export default HcpManagementComponent;

