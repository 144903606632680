// AUTH ROUTES
const LOGIN_ROUTE = "/login";
const FORGOT_PASSWORD_ROUTE = "/forgot-password";
//VARS-HEALTH
const DASHBOARD = "/dashboard";
//FACILITY MODULE ROUTES
const FACILITY = "/facility";

const SCHEDULER = '/scheduler';
const FACILITY_lIST = "/facility/list";
const EDIT_FACILITY = "/facility/edit";
const ADD_FACILITY = "/facility/add";
const FACILITY_DETAILS = "/facility/details";
//AGENCY MODULE ROUTES
const AGENCY = "/agency";
const AGENCY_LIST = "/agency/list";
const AGENCY_DETAILS = "/agencyDetails";
const ADD_AGENCY = "/agency/add";
//HCP-MANAGEMENT ROUTES
const HCP_MANAGEMENT = "/hcp-management";
const HCP_MANAGEMENT_LIST = "/hcp-management/list";
const ADD_HCP = "/hcp/add";
//SHIFT-REQUIREMENT ROUTES
const SHIFT_REQUIREMENT = "/shift-requirement";
const HCP_FAVORITE="/hcp";
const HCP_MANAGEMENT_FAVORITE_LIST = "/hcp/favorite";
const HCP_VIEW_DETAILS = "/hcp/view-details";
const SHIFT_REQUIREMENT_LIST = "/shift-requirement/list";
const SCHEDULER_CALENDER_VIEW = "calender/view";
const ADD_SHIFT_REQUIREMENT = "/shift-requirement/add";
const SHIFT_REQUIREMENT_VIEW_DETAILS = "/shift-requirement/view-details";
//VARS-HEALTH-END

const USER_MANAGEMENT = '/user-management';
const USER_MANAGEMENT_LIST = "/user-management/list";
const ADD_USER_MANAGEMENT = '/user-management/add';

// NON-AUTH ROUTES
const NOT_FOUND_ROUTE = "/not-found";
const COMING_SOON_ROUTE = "/coming-soon";


export {
    //vars health
    LOGIN_ROUTE,
    DASHBOARD,
    FACILITY,
    AGENCY,
    COMING_SOON_ROUTE,
    NOT_FOUND_ROUTE,
    FACILITY_DETAILS,
    FACILITY_lIST,
    ADD_FACILITY,
    EDIT_FACILITY,
    FORGOT_PASSWORD_ROUTE,
    AGENCY_LIST,
    AGENCY_DETAILS,
    ADD_AGENCY,
    HCP_MANAGEMENT,
    HCP_MANAGEMENT_LIST,
    ADD_HCP,
    SHIFT_REQUIREMENT,
    SHIFT_REQUIREMENT_LIST,
    ADD_SHIFT_REQUIREMENT,
    SHIFT_REQUIREMENT_VIEW_DETAILS,
    SCHEDULER, SCHEDULER_CALENDER_VIEW,
    ADD_USER_MANAGEMENT,
    USER_MANAGEMENT,
    USER_MANAGEMENT_LIST,
    HCP_MANAGEMENT_FAVORITE_LIST,
    HCP_FAVORITE,
    HCP_VIEW_DETAILS
};
