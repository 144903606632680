import "./EditAgencyAdditionalRateComponent.scss";
import {useSelector} from "react-redux";
import React, {useCallback, useEffect} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {IRootReducerState} from "../../../../../store/reducers";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import LoaderComponent from "../../../../../shared/components/loader/LoaderComponent";
import SwitchComponent from "../../../../../shared/components/form-controls/switch/SwitchComponent";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import HorizontalLineComponent
    from "../../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Patterns} from "../../../../../constants";

interface AgencyHCPRatesProps {
    selectedAgencyDetails: any;
    agencyId: any;
    closeAgencyAdditionalRateEditDrawer: () => void;
    getAgencyDetails: () => void;
}

const EditAdditionalRateOfAgencyComponent = (props: AgencyHCPRatesProps) => {
    const {
        isAdditionalRatesListLoaded,
        isAdditionalRatesListLoading,
        AdditionalRatesList
    } = useSelector((state: IRootReducerState) => state.meta);
    const {
        selectedAgencyDetails,
        agencyId,
        closeAgencyAdditionalRateEditDrawer,
        getAgencyDetails
    } = props;

    const mergedAdditonalRateList = AdditionalRatesList.map((type: any) => {
        const matchingPreference = selectedAgencyDetails?.addl_rates?.find((rate: any) => rate.addl_rate_id === type._id);
        return {
            ...type,
            enabled: !!matchingPreference,
            value: matchingPreference ? matchingPreference?.rate : 0
        };
    });


    const handleAdditionalRateSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const modifiedData = values?.rates?.filter((item: any) => item.enabled).map((item: any) => ({
            addl_rate_id: item._id,
            rate: Number(item.value)
        }));
        const payload: any = {};

        payload.addl_rates = modifiedData;

        CommonService._agency.EditHcpTypesRateAPICall(agencyId, payload)
            .then((response: IAPIResponseType<any>) => {
                getAgencyDetails();
                closeAgencyAdditionalRateEditDrawer();
            }).catch((error: any) => {
        }).finally(() => {
            setSubmitting(false);
        });

    }, [agencyId, getAgencyDetails, closeAgencyAdditionalRateEditDrawer]);

    return (<>
            <div className={'edit-agency-additional-rate-screen'}>
                <div className={'edit-agency-additional-rate-header'}>
                    <div className={'form-header-text'}>Edit Additional Rates</div>
                    <div className={'icon-button'} onClick={closeAgencyAdditionalRateEditDrawer}>
                        <ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>
                <div className={"agency-additional-rate-component"}>
                    <div className={'rate-form-container'}>
                        <div className={'rate-form'}>
                            <Formik
                                initialValues={{rates: mergedAdditonalRateList}}
                                onSubmit={handleAdditionalRateSubmit}
                            >
                                {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                    }, [validateForm, values]);
                                    return (
                                        <Form className={'rate-normal-from'}>
                                            {isAdditionalRatesListLoading && <LoaderComponent/>}
                                            <div>
                                                <div className={"agency-additional-rate-wrapper"}>
                                                    {isAdditionalRatesListLoaded &&
                                                        <>
                                                            {
                                                                values.rates.map((rate, index) => (
                                                                    <div key={index} className={'list-item'}>
                                                                        <div className={'center-right-element'}>
                                                                            <label
                                                                                style={{
                                                                                    minWidth: "250px",
                                                                                    maxWidth: "250px"
                                                                                }}>{rate.title}</label>
                                                                            <SwitchComponent
                                                                                label={''}
                                                                                checked={rate.enabled}
                                                                                onChange={(checked) =>
                                                                                    setFieldValue(`rates[${index}].enabled`, checked)
                                                                                }
                                                                            />
                                                                            {rate.enabled && (
                                                                                <div>
                                                                                    <Field
                                                                                        type="number"
                                                                                        name={`rates[${index}].value`}
                                                                                    >
                                                                                        {
                                                                                            (field: FieldProps) => (
                                                                                                <FormikInputComponent
                                                                                                    className={"mrg-bottom-set-zero"}
                                                                                                    label={''}
                                                                                                    placeholder={'Rates'}
                                                                                                    validationPattern={Patterns.NATURAL_INTEGER_DECIMALS}
                                                                                                    type={"number"}
                                                                                                    required={true}
                                                                                                    formikField={field}
                                                                                                    fullWidth={true}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </Field>
                                                                                    {/*<ErrorMessage*/}
                                                                                    {/*    name={`rates[${index}].value`}*/}
                                                                                    {/*    component="div"*/}
                                                                                    {/*/>*/}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <HorizontalLineComponent/>
                                            <div className={'button-wrapper center-element'}>
                                                <ButtonComponent
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isSubmitting}
                                                    type={'submit'}
                                                    className={"mrg-top-5"}
                                                    style={{backgroundColor: "#192938", color: "white"}}
                                                >
                                                    Save Details
                                                </ButtonComponent>
                                            </div>
                                        </Form>);
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditAdditionalRateOfAgencyComponent;