import "./OnboardingCompletedComponent.scss";
import {ImageConfig} from "../../../constants";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import React from "react";
import {useNavigate} from "react-router-dom";
import {CommonService} from "../../../shared/services";


const OnboardingCompletedComponent = () => {
    const navigator = useNavigate();

    return (
        <>
            <div className={"onboarding-component center-element "}>
                <div className={'ts-justify-content-center'}>
                    <div className={' d-flex ts-justify-content-center'}>
                        <ImageConfig.OmboardingCompletedIcon/>
                    </div>
                    <div className={'d-flex ts-justify-content-center alert-text-display'}>
                        Onboarding Completed
                    </div>
                    <div className={'information-text d-flex ts-justify-content-center'}
                         style={{width: "600px", wordWrap: "break-word"}}>
                        You have verified all the details entered by the facility.
                        Now you will be able to assign Staff to the shifts hosted by this facility.
                    </div>
                    <div className={'d-flex justify-content-center'} style={{marginTop: '32px'}}>
                        <ButtonComponent
                            onClick={() => {
                                navigator(CommonService._routeConfig.AgencyRoute());
                            }}
                            type={'submit'}
                            style={{
                                backgroundColor: "#192938",
                                color: "white",
                            }}
                            suffixIcon={<ImageConfig.BackArrow/>}
                        >
                            Back to Home
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </>
    );
};
export default OnboardingCompletedComponent;
