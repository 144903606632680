import "./pageLoaderComponent.scss";
import React from 'react';
import LoadingStarter from "../../../assets/animations/cycleLoading.json";
import Lottie from "react-lottie";

interface PageLoaderComponentProps {

}

const PageLoaderComponent = (props: PageLoaderComponentProps) => {
    return (
        <div className="page-loading">
            <Lottie width={300} height={300} speed={1}
                    options={{animationData: LoadingStarter}}/>
            <div className={'pdd-top-20 loading-starter-text'}>Just a moment, we are getting things ready for you...</div>
        </div>
    )
};

export default PageLoaderComponent;