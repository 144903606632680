import "./LoginScreen.scss";
import * as Yup from "yup";
import {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikPasswordInputComponent
    from "../../../shared/components/form-controls/formik-password-input/FormikPasswordInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {CommonService} from "../../../shared/services";
import {setLoggedInUserData, setLoggedInUserToken} from "../../../store/actions/account.action";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {IAccountLoginCredentials, ILoginResponse} from "../../../shared/models/account.model";
import {useDispatch} from "react-redux";
import {ENV, ImageConfig, Misc} from "../../../constants";
import LinkComponent from "../../../shared/components/link/LinkComponent";

interface LoginScreenProps {

}

const loginFormValidationSchema = Yup.object({
    username: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
    password: Yup.string()
        .min(6, "Password must be 6 characters")
        .max(16, "Password must be max 16 characters")
        .required("Password is required")
});

const LoginScreen = (props: LoginScreenProps) => {

    const [loginFormInitialValues, setLoginFormInitialValues] = useState<IAccountLoginCredentials>({
        username: "",
        password: "",
    });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ENV.ENV_MODE === "dev") {
            setLoginFormInitialValues({
                username: "niketnayan13@gmail.com",
                password: "123456",
            });
        }
    }, []);

    const onSubmit = useCallback((values: any, {setErrors}: FormikHelpers<any>) => {
        setIsLoggingIn(true);
        CommonService._account.LoginAPICall(values)
            .then((response: IAPIResponseType<ILoginResponse>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                dispatch(setLoggedInUserData(response?.data?.user));
                dispatch(setLoggedInUserToken(response?.data?.token));
            })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error, "error");
            }).finally(() => {
            setIsLoggingIn(false);
        });
    }, [dispatch]);

    return (
        <div className="login-screen">
            {/*Login Screen Component*/}
            <div className="login-form-container">
                <div className="login-form-helper-text">
                    Welcome Back
                </div>
                <div className={'login-form-info-text'}>
                    Sign in to seamlessly connect and optimize
                </div>
                <div className={'login-form-info-text mrg-bottom-30'}>
                    your staffing process.
                </div>
                <Formik
                    validationSchema={loginFormValidationSchema}
                    initialValues={loginFormInitialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {(formik) => {
                        const {values, validateForm} = formik;
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form className="t-form" noValidate={true}>
                                <div className="t-form-controls">
                                    <Field name={'username'}>
                                        {
                                            (field: FieldProps) => (
                                                <FormikInputComponent
                                                    label={'Email ID'}
                                                    placeholder={'Enter Email ID'}
                                                    type={"email"}
                                                    required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                    id={"email_input"}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={'password'}>
                                        {
                                            (field: FieldProps) => (
                                                <FormikPasswordInputComponent
                                                    label={'Password'}
                                                    placeholder={'Enter Password'}
                                                    required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                    canToggle={true}
                                                    id={"password_input"}
                                                />
                                            )
                                        }
                                    </Field>
                                    <div className={'forget-password-text-container'}>
                                        <LinkComponent
                                            route={CommonService._routeConfig.forgotPasswordRoute()}>
                                            Forgot Password ?
                                        </LinkComponent>
                                    </div>
                                </div>
                                <div className="t-form-actions mrg-top-25">
                                    <ButtonComponent
                                        isLoading={isLoggingIn}
                                        type={"submit"}
                                        fullWidth={true}
                                        id={"login_btn"}
                                        suffixIcon={<ImageConfig.ArrowCircleRightIcon/>}
                                    >
                                        {isLoggingIn ? "Logging in" : "Login"}
                                    </ButtonComponent>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );

};

export default LoginScreen;
