import ENV from "./ENV";

interface IAPIConfig {
    [k: string]: {
        URL: string | Function | any,
        METHOD: "get" | "post" | "delete" | "put"
    };
}

const APIConfig: IAPIConfig = {
    //meta start
    GENDER_LIST: {
        URL: ENV.API_URL + "/gender",
        METHOD: "get"
    },
    LANGUAGE_LIST: {
        URL: ENV.API_URL + "/language",
        METHOD: "get"
    },
    SHIFTS_CALENDAR_LIST: {
        URL: ENV.API_URL + '/shifts/calendar',
        METHOD: "post"
    },
    SHIFT_CALENDAR_LIST_BY_DATE: {
        URL: ENV.API_URL + '/shift/dayShifts',
        METHOD: "post"
    },
    // USER_ROLE_LIST: {
    //     URL: ENV.API_URL + "/userRole",
    //     METHOD: "get"
    // },

    TOGGLE_FACILITY: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId,
        METHOD: 'put'
    },
    //meta end

    // authentication start
    LOGIN: {
        URL: ENV.API_URL + "/login",
        METHOD: "post"
    },
    CHECK_LOGIN: {
        URL: ENV.API_URL + "/checkLogin",
        METHOD: "get"
    },
    LOGOUT: {
        URL: ENV.API_URL + "/login",
        METHOD: "delete"
    },
    FORGOT_PASSWORD: {
        URL: ENV.API_URL + "/forgotPassword",
        METHOD: "put"
    },
    SEND_OTP: {
        URL: ENV.API_URL + "/sendOtp",
        METHOD: "post"
    },
    VERIFY_OTP: {
        URL: ENV.API_URL + "/verifyOtp",
        METHOD: "post"
    },
    SET_NEW_PASSWORD: {
        URL: ENV.API_URL + "/resetPassword",
        METHOD: "post"
    },
    // authentication end

    //facility start vars health
    ADD_FACILITY: {
        URL: ENV.API_URL + '/facility',
        METHOD: 'post'
    },
    ADD_WING: {
        URL: ENV.API_URL + "/wing",
        METHOD: 'post'
    },
    FACILITY_LIST: {
        URL: ENV.API_URL + '/facility',
        METHOD: 'get'
    },
    WING_LIST: {
        URL: ENV.API_URL + '/wing/lite',
        METHOD: 'get'
    },
    WING_DETAILS: {
        URL: (wingId: string) => ENV.API_URL + '/wing/' + wingId,
        METHOD: 'get'
    },
    FACILITY_LIST_LITE: {
        URL: ENV.API_URL + '/facility/lite',
        METHOD: 'get'
    },
    FACILITY_DETAILS: {
        URL: ENV.API_URL + '/facility',
        METHOD: 'get'
    },
    EDIT_FACILITY: {
        URL: ENV.API_URL + '/facility',
        METHOD: 'put'
    },
    WINGS_LIST_LITE: {
        URL: ENV.API_URL + '/wing',
        METHOD: 'get'
    },
    //Agency api urls
    ADD_AGENCY: {
        URL: ENV.API_URL + "/agency",
        METHOD: 'post'
    },
    AGENCY_LIST: {
        URL: ENV.API_URL + '/agency',
        METHOD: 'get'
    },
    ADD_SHIFT_TIMINGS: {
        URL: ENV.API_URL + "/shifttimings",
        METHOD: 'post'
    },
    EDIT_WING: {
        URL: (wingId: any) => ENV.API_URL + "/wing/" + wingId,
        METHOD: 'put'
    },
    HCP_FAVORITE_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/hcp/favourites',
        METHOD: 'get'
    },
    AGENCY_LIST_LITE: {
        URL: ENV.API_URL + '/agency/lite',
        METHOD: 'post'
    },
    AGENCY_DETAILS: {
        URL: (agencyId: string) => ENV.API_URL + "/agency/" + agencyId,
        METHOD: 'get'
    },
    EDIT_AGENCY: {
        URL: (agencyId: string) => ENV.API_URL + "/facilityagency/" + agencyId,
        METHOD: 'put'
    },
    EDIT_AGENCY_BASIC_DETAILS: {
        URL: (agencyId: string) => ENV.API_URL + "/agency/" + agencyId,
        METHOD: 'put'
    },
    LINK_AGENCY_FACILITY: {
        URL: (agencyId: string) => ENV.API_URL + "/agency/" + agencyId + "/link",
        METHOD: 'post'
    },
    GET_LINKED_FACILITY_AGENCY: {
        URL: (agencyId: string) => `${ENV.API_URL}/agency/${agencyId}/facility/`,
        METHOD: 'get'
    },

    GET_AGENCY_RATE_WITH_FACILITY_ID: {
        URL: (agencyId: string) => ENV.API_URL + "/facilityagency/" + agencyId,
        METHOD: 'get'
    },


    EDIT_AGENCY_ADMIN_DETAILS: {
        URL: (agencyId: string) => ENV.API_URL + '/agencyadmin/' + agencyId,
        METHOD: 'put'
    },


    EDIT_HCP_TYPES_RATE: {
        URL: (agencyId: string) => ENV.API_URL + "/facilityagency/" + agencyId,
        METHOD: 'put'
    },
    CONTRACT_UPLOAD_AGENCY_FACILITY: {
        URL: (agencyId: string) => ENV.API_URL + "/agency/" + agencyId + "/contract",
        METHOD: 'post'
    },
    //hcp-management-apis
    HCP_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/hcp/list',
        METHOD: 'post'
    },
    HCP_MANAGEMENT_LIST_LITE: {
        URL: ENV.API_URL + '/hcp/lite',
        METHOD: 'get'
    },
    ONBOARD_HCP: {
        URL: ENV.API_URL + '/hcp',
        METHOD: 'post'
    },

    HCP_DETAILS: {
        URL: (HCPId: string) => ENV.API_URL + "/hcp/" + HCPId,
        METHOD: 'get'
    },
    HCP_DOCUMENTS: {
        URL: (HCPId: string) => ENV.API_URL + "/hcp/" + HCPId + "/document",
        METHOD: 'get'
    },
    MARK_HCP_AS_FAVORITE: {
        URL: (HCPId: string) => ENV.API_URL + "/hcp/" + HCPId + "/favourite",
        METHOD: 'post'
    },
    REMOVE_AS_FAVORITE: {
        URL: (HCPId: string) => ENV.API_URL + "/hcp/" + HCPId + "/favourite",
        METHOD: 'delete'
    },

    //shift-management-apis
    SHIFT_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/requirement/list',
        METHOD: 'post'
    },
    ADD_NEW_SHIFT: {
        URL: ENV.API_URL + '/requirement',
        METHOD: 'post'
    },
    ADD_NEW_OPEN_SHIFT: {
        URL: ENV.API_URL + '/requirement',
        METHOD: 'post'
    },
    ADD_CONTRACT_SHIFT: {
        URL: ENV.API_URL + '/requirement/contract',
        METHOD: 'post'
    },

    SHIFT_REQUIREMENT_DETAILS: {
        URL: (shiftId: any) => ENV.API_URL + "/requirement/" + shiftId,
        METHOD: 'get'
    },
    SHIFT_ASSIGNED_DETAILS: {
        URL: (shiftId: any) => ENV.API_URL + "/shift/" + shiftId + '/view',
        METHOD: 'get'
    },
    APPROVE_SHIFT_REQUIREMENT_APPLICATION: {
        URL: (shiftId: any) => ENV.API_URL + "/application/" + shiftId + "/approve",
        METHOD: 'post'
    },
    REJECT_SHIFT_REQUIREMENT_APPLICATION: {
        URL: (shiftId: any) => ENV.API_URL + "/application/" + shiftId + "/reject",
        METHOD: 'put'
    },
    MARK_APPROVED_SHIFT_TO_COMPLETED: {
        URL: ENV.API_URL + '/shifts/markAsCompleted',
        METHOD: 'put'
    },
    MARK_COMPLETED_SHIFT_TO_CONFIRMED: {
        URL: ENV.API_URL + '/shifts/markAsConfirmed',
        METHOD: 'put'
    },
    UPLOAD_TIMESHEET: {
        URL: (shiftId: any) => ENV.API_URL + "/shift/" + shiftId + "/timesheet",
        METHOD: 'post',
    },
    SHIFT_MANAGEMENT_LIST_AFTER_APPROVAL: {
        URL: ENV.API_URL + '/shifts/list',
        METHOD: 'post'
    },
    CANCEL_APPROVED_SHIFT_TO_CANCELLED: {
        URL: ENV.API_URL + '/shifts/cancel',
        METHOD: 'put'
    },
    //shift-management-apis for application

    HCP_ASSIGNED_TO_SHIFT: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/applications",
        METHOD: 'get'
    },


    //meta-apis -vars
    GET_HCP_TYPES: {
        URL: ENV.API_URL + '/hcpTypes',
        METHOD: 'get'
    },
    GET_STATE_LIST_API: {
        URL: ENV.API_URL + '/states',
        METHOD: 'get'
    },
    GET_ADDITIONAL_RATES_TYPE: {
        URL: ENV.API_URL + '/addlRates',
        METHOD: 'get'
    },
    ADD_NEW_HCP_TYPE: {
        URL: ENV.API_URL + '/hcpTypes',
        METHOD: 'post'
    },
    ADD_NEW_ADDITIONAL_RATE: {
        URL: ENV.API_URL + '/addlRates',
        METHOD: 'post'
    },
    DOCUMENT_DELETE: {
        URL: ENV.API_URL + '/deleteAttachment',
        METHOD: 'delete'
    },
    DOCUMENT_DOWNLOAD: {
        URL: ENV.API_URL + '/downloadAttachment',
        METHOD: 'post'
    },
    TOGGLE_FACILITY_ADMIN: {
        URL: (facilityId: string) => ENV.API_URL + '/facilityadmin/' + facilityId + "/toggle",
        METHOD: 'put'
    },
    USER_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/facilityadmin/list',
        METHOD: 'get'
    },
    EDIT_FACILITY_ADMIN_DETAILS: {
        URL: (facilityId: string) => ENV.API_URL + '/facilityadmin/' + facilityId,
        METHOD: 'put'
    },
    ADD_FACILITY_ADMIN_UNDER_USER_MANAGEMENT: {
        URL: ENV.API_URL + '/facilityadmin/user',
        METHOD: 'post'
    },
    CANCEL_SHIFT_DETAILS: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/cancel",
        METHOD: 'put'
    },
    TOGGLE_WING: {
        URL: (wingId: string) => ENV.API_URL + '/wing/' + wingId + '/toggle',
        METHOD: 'put'
    },
    DOWNLOAD_SHIFT_DATA_CSV: {
        URL: ENV.API_URL + '/shifts/download',
        METHOD: 'post'
    },
    DOWNLOAD_OPEN_SHIFT_DATA_CSV: {
        URL: ENV.API_URL + '/requirement/download',
        METHOD: 'post'
    },
    REGION_LIST: {
        URL: ENV.API_URL + '/regions',
        METHOD: 'get'
    },
    DOWNLOAD_AGENCY_DATA_CSV: {
        URL: ENV.API_URL + '/agency/download',
        METHOD: 'post'
    },

    DOWNLOAD_HCP_DATA_CSV: {
        URL: ENV.API_URL + '/favourites/download',
        METHOD: 'post'
    },
    DOWNLOAD_INTERNAL_HCP_DATA_CSV: {
        URL: ENV.API_URL + '/hcp/download',
        METHOD: 'post'
    },
    DOWNLOAD_USER_DATA_CSV: {
        URL: ENV.API_URL + '/facilityadmin/download',
        METHOD: 'post'
    },

    TOGGLE_AGENCY: {
        URL: (agencyadminid: string) => ENV.API_URL + '/agencyadmin/' + agencyadminid + '/toggle',
        METHOD: 'put'
    },
    UPLOAD_HCP_DOCUMENT: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId + "/document",
        METHOD: 'post'
    },
    CONTRACT_UPLOAD_HCP: {
        URL: (hcpId: any) => ENV.API_URL + "/hcp/" + hcpId + "/contract",
        METHOD: 'post'
    },
    EDIT_HCP_DETAILS: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId,
        METHOD: 'put'
    },
    HCP_DOCUMENT_DELETE: {
        URL: (hcpId: any, documentId: any) => ENV.API_URL + '/hcp/' + hcpId + "/document/" + documentId,
        METHOD: 'delete'
    },
    HCP_DOCUMENT_DOWNLOAD: {
        URL: ENV.API_URL + '/downloadAttachment',
        METHOD: 'post'
    },
    ADD_NEW_APPLICATIONS_TO_SHIFT: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/applications",
        METHOD: 'post'
    },
    HCP_LIST_REQUIREMENT_BASED: {
        URL: ENV.API_URL + '/hcp/lite',
        METHOD: 'get'
    },
    DELETE_SHIFT_TIMINGS: {
        URL: (shiftTimingId: any) => ENV.API_URL + "/shifttimings/" + shiftTimingId,
        METHOD: "delete"
    },
    DELETE_WING: {
        URL: (wingId: any) => ENV.API_URL + "/wing/" + wingId,
        METHOD: "delete"
    },
    SHIFT_MANAGEMENT_PENDING_LIST: {
        URL: ENV.API_URL + '/application/list',
        METHOD: 'post'
    },
    INTERNAL_SHIFTS_CALENDAR_LIST: {
        URL: ENV.API_URL + '/shifts/internalCalendar',
        METHOD: "post"
    },
    INTERNAL_SHIFT_CALENDAR_LIST_BY_DATE: {
        URL: ENV.API_URL + '/facilityShift/internalDayShifts',
        METHOD: "post"
    },
    INTERNAL_SHIFT_ASSIGNED_DETAILS: {
        URL: (shiftId: any) => ENV.API_URL + "/facilityShift/" + shiftId + '/view',
        METHOD: 'get'
    },
    ADD_APPROVED_NEW_SHIFT: {
        URL: ENV.API_URL + '/facilityshift',
        METHOD: 'post'
    },
    ADD_NEW_APPROVED_SHIFTS_FOR_INTERNAL_STAFFS: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/internalShifts",
        METHOD: 'post'
    },
    TOGGLE_LIST_AGENCY: {
        URL: (agencyadminid: string) => ENV.API_URL + '/agency/' + agencyadminid + "/toggle",
        METHOD: 'put'
    },
    TOGGLE_HCP: {
        URL: (facilityId: string) => ENV.API_URL + '/hcp/' + facilityId + "/toggle",
        METHOD: 'put'
    },
    FACILITY_HCP_SHIFTS: {
        URL: ENV.API_URL + '/shifts/list',
        METHOD: 'post'
    },
    SHIFTS_CALENDAR_WEEK_LIST: {
        URL: ENV.API_URL + '/shift/weekView',
        METHOD: "post"
    },
    STAFF_SPECIALITY_LIST: {
        URL: (hcpTypeId: string) => ENV.API_URL + "/hcpType/" + hcpTypeId + "/specialities",
        METHOD: 'get'
    },
    ADD_NEW_HOME_HEALTH_HOSPICE_SHIFT: {
        URL: ENV.API_URL + '/requirement/home',
        METHOD: 'post'
    },
    APPROVE_HOME_HEALTH_APPLICATION: {
        URL: ENV.API_URL + '/applications/approve/home',
        METHOD: 'post'
    },
    APPROVE_TRAVEL_CONTRACT_APPLICATION: {
        URL: ENV.API_URL + '/applications/approve/contract',
        METHOD: 'post'
    },
    ADD_PRE_APPROVED_OPEN_SHIFT:{
        URL: ENV.API_URL + '/requirement/open/preApproved',
        METHOD: 'post'
    },
    ADD_PRE_APPROVED_HOME_SHIFT:{
        URL: ENV.API_URL + '/requirement/home/preApproved',
        METHOD: 'post'
    },
    ADD_PRE_APPROVED_CONTRACT_SHIFT:{
        URL: ENV.API_URL + '/requirement/contract/preApproved',
        METHOD: 'post'
    },
};

export default APIConfig;
