import ButtonComponent from "../button/ButtonComponent";
import React from "react";
import {ImageConfig} from "../../../constants";
import "./AddNewTypeComponent.scss";
import InputComponent from "../form-controls/input/InputComponent";

interface RejectCancelledCommonComponentProps {
    setOpenDialog: any;
    addType: any;
    setTypeName: any;
    onSubmit: any;
    typeName: any;
    isLoading?: any;
}

const AddNewTypeComponent = (props: RejectCancelledCommonComponentProps) => {
    const {
        setOpenDialog,
        setTypeName,
        onSubmit,
        typeName,
        isLoading,
        addType
    } = props;
    return (
        <>
            <div className={'reject-cancelled-component'} style={{
                backgroundImage: `url(${ImageConfig.AddNewTypeDialogGradient}`,
                backgroundRepeat: "no-repeat"
            }}>
                <div className={'mrg-top-45 d-flex ts-justify-content-center reject-upper-text mrg-bottom-15'}>
                    Add new {addType}
                </div>
                <div className={'d-flex ts-justify-content-center reject-lower-text'}>
                    Enter the name of new {addType}
                </div>
                <div className={'d-flex ts-justify-content-center reject-lower-text'}>
                    below
                </div>
                <div className={'mrg-right-45 mrg-left-45 mrg-bottom-40 mrg-top-25'}>
                    <div className={'mrg-bottom-40'}>
                        <InputComponent    required={true}
                                           value={typeName}
                                           placeholder={"Enter name here"}
                                           fullWidth
                                           label={""} onChange={(e) => {
                            setTypeName(e);
                        }}/>
                    </div>
                    <div className={'d-flex ts-justify-content-sm-between'}>
                        <>
                            <div className={'d-flex center-the-element-content'}>
                                <ButtonComponent
                                    onClick={() => {
                                        setOpenDialog(false);
                                    }}
                                    color={'download-button'}
                                    id={"no-btn"}
                                    className={"pdd-left-60 pdd-right-60"}
                                >
                                    {'No, Go Back'}
                                </ButtonComponent>
                                &nbsp;&nbsp;&nbsp;
                            </div>
                        </>

                        <div className={'d-flex center-the-element-content'}>
                            <ButtonComponent
                                disabled={!typeName}
                                isLoading={isLoading}
                                onClick={onSubmit}
                                id={"yes-btn"}
                                className={"pdd-left-60 pdd-right-60 confirmation-dialog-yes-btn"}
                            >
                                &nbsp;     &nbsp;   &nbsp;   &nbsp; Save    &nbsp;    &nbsp;    &nbsp;   &nbsp;
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNewTypeComponent;