import React, {useEffect, useState} from "react";
import ToolTipComponent from "../../../../../shared/components/tool-tip/ToolTipComponent";
import {ImageConfig} from "../../../../../constants";

interface ContactPreviewFileComponentProps {
    file?: any;
    onRemove?: () => void;
}

const ContactPreviewFileComponent = (props: ContactPreviewFileComponentProps) => {
    const {file, onRemove} = props;
    const [fileName, setFileName] = useState<string>("");

    useEffect(() => {
        if (file instanceof File) {
            const name = file.name;
            setFileName(name);
        } else {
            setFileName(file.name);
        }
    }, [file]);
    return (
        <>
            <div className={'file-wrapper-after-upload'}>
                <div className={'d-flex'} style={{justifyContent: "space-between"}}>
                    <div className={'d-flex'}>
                        <div className={'mrg-right-10'}>
                            <ImageConfig.PreviewIcon/>
                        </div>
                        <ToolTipComponent tooltip={fileName}>
                            <div className="file-name ellipses-for-calender-card-data">
                                {fileName}
                            </div>
                        </ToolTipComponent>
                    </div>
                    <div className={'remove-icon icon-button'} onClick={onRemove}>
                        <ImageConfig.CrossIcon height={16} width={16}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactPreviewFileComponent;