import {
    GET_USER_ROLE_LIST,
    SET_USER_ROLE_LIST,
    GET_FACILITY_LIST_LITE,
    SET_FACILITY_LIST_LITE,
    GET_AGENCY_LIST_LITE,
    SET_AGENCY_LIST_LITE,
    GET_WINGS_LIST_LITE,
    SET_WINGS_LIST_LITE, GET_REGION_LIST_API, SET_REGION_LIST_API
} from "../actions/static-data.action";

import {IActionModel} from "../../shared/models/action.model";

export interface IStaticDataReducerState {
    isUserRoleListLoading: boolean,
    isUserRoleListLoaded: boolean,
    userRoleList: any[],
    isFacilityLoading: boolean,
    isFacilityListLoaded: boolean,
    facilityList: any,
    isAgencyLoading: boolean,
    isAgencyListLoaded: boolean,
    agencyList: any,
    isWingsLoading: boolean,
    isWingsListLoaded: boolean,
    wingsList: any,
    isRegionListLoading: any,
    isRegionListLoaded: any,
    regionList: any,
}

const initialData: IStaticDataReducerState = {

    isUserRoleListLoading: false,
    isUserRoleListLoaded: false,
    userRoleList: [],

    isFacilityLoading: false,
    isFacilityListLoaded: false,
    facilityList: [],

    isAgencyLoading: false,
    isAgencyListLoaded: false,
    agencyList: [],

    isWingsLoading: false,
    isWingsListLoaded: false,
    wingsList: [],
    isRegionListLoading: false,
    isRegionListLoaded: false,
    regionList: []

};

const StaticDataReducer = (state = initialData, action: IActionModel): IStaticDataReducerState => {
    switch (action.type) {
        case GET_FACILITY_LIST_LITE:
            state = {
                ...state,
                isFacilityListLoaded: false,
                isFacilityLoading: true
            };
            return state;

        case SET_FACILITY_LIST_LITE:
            state = {
                ...state,
                isFacilityListLoaded: true,
                isFacilityLoading: false,
                facilityList: action.payload.facilityList
            };
            return state;
        case GET_AGENCY_LIST_LITE:
            state = {
                ...state,
                isAgencyListLoaded: false,
                isAgencyLoading: true
            };
            return state;

        case SET_AGENCY_LIST_LITE:
            state = {
                ...state,
                isAgencyListLoaded: true,
                isAgencyLoading: false,
                agencyList: action.payload.agencyList
            };
            return state;
        case GET_REGION_LIST_API:
            state = {
                ...state,
                isRegionListLoaded: false,
                isRegionListLoading: true
            };
            return state;

        case SET_REGION_LIST_API:
            state = {
                ...state,
                isRegionListLoaded: true,
                isRegionListLoading: false,
                regionList: action.payload.regionList
            };
            return state;

        case GET_WINGS_LIST_LITE:
            state = {
                ...state,
                isWingsListLoaded: false,
                isWingsLoading: true
            };
            return state;

        case SET_WINGS_LIST_LITE:
            state = {
                ...state,
                isWingsListLoaded: true,
                isWingsLoading: false,
                wingsList: action.payload.wingsList
            };
            return state;

        case GET_USER_ROLE_LIST:
            state = {
                ...state,
                isUserRoleListLoading: true,
                isUserRoleListLoaded: false,
            };
            return state;
        case SET_USER_ROLE_LIST:
            state = {
                ...state,
                isUserRoleListLoading: false,
                isUserRoleListLoaded: true,
                userRoleList: action.payload.userRoleList,
            };
            return state;

        default:
            return state;
    }
};

export default StaticDataReducer;
