import "./PaginationComponent.scss";
import {TablePagination} from "@mui/material";

interface PaginationComponentProps {
    id?: string;
    paginationOptions: number[];
    totalResults: number;
    page: number;
    limit: number;
    onPageChange: any;
    onRowsPerPageChange: any;
    selectedQuantity?: any;
    downLoadButton?: any;
}

const PaginationComponent = (props: PaginationComponentProps) => {

    const {
        selectedQuantity,
        paginationOptions,
        page,
        limit,
        totalResults,
        onPageChange,
        onRowsPerPageChange,
        downLoadButton
    } = props;
    const id = props.id || "table-pagination";

    return (
        <>
            <div style={{display: "flex", justifyContent: "space-between", borderTop: "1px solid #DDDDDD"}}>
                <div className={'center-element'}>
                    {(selectedQuantity > 0) &&
                        <div
                            className={'table-footer-selected-value mrg-left-25'}>{selectedQuantity + " of " + totalResults + " selected"}</div>}
                    {downLoadButton}
                </div>
                <div>
                    <TablePagination
                        id={id}
                        rowsPerPageOptions={paginationOptions}
                        component="div"
                        labelDisplayedRows={(paginationInfo) => {
                            return <>
                                <span id={`${id}_from`}>{paginationInfo.from}</span> - <span
                                id={`${id}_to`}>{paginationInfo.to}</span> of <span
                                id={`${id}_total_count`}>{paginationInfo.count !== -1 ? paginationInfo.count : 'more than' + paginationInfo.count}</span>
                            </>;
                        }}
                        // showFirstButton={true}
                        // showLastButton={true}
                        count={totalResults}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </div>
            </div>
        </>
    );

};

export default PaginationComponent;
