import React, {useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface DropdownOption {
    label: string;
    value: string | number;
}

interface DropdownProps {
    options: DropdownOption[];
    loadingOption?: string | number | null;
    onChange: (value: string | number) => void;
    open?: any;
    setOpen?: any;
}

const ReusableDropdown: React.FC<DropdownProps> = ({
                                                       options,
                                                       loadingOption = null,
                                                       onChange,
                                                       open,
                                                       setOpen
                                                   }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOptionClick = (value: string | number) => {
        onChange(value);
    };

    return (
        <div style={{display: 'inline-block', position: 'relative'}}>
            <div
                onClick={handleClick}
                style={{
                    minWidth: "200px",
                    cursor: 'pointer',
                    border: '1px solid #E7E7E7',
                    backgroundColor: "#FFFFFF",
                    borderRadius: '8px',
                    padding: '6.5px 12px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div style={{flexGrow: 1}}>
          <span>
            {options.find((option) => option.value === loadingOption)?.label || 'Select Action'}
          </span>
                </div>
                <ArrowDropDownIcon/>
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{minWidth: "200px"}}>
                {options.map((option, index) => (
                    <MenuItem key={index}
                              onClick={() => handleOptionClick(option.value)}
                              sx={{minWidth: "200px"}}>
                        {option.value === loadingOption ? (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {/*<CircularProgress size={16} style={{marginRight: '8px'}}/>*/}
                                {option.label}
                            </div>
                        ) : (
                            option.label
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ReusableDropdown;
