import "./TimesheetUploadPreview.scss";
import {ImageConfig, Misc} from "../../../../constants";
import {useCallback} from "react";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";

interface TimesheetUploadPreviewFile {
    timesheetFile: any;
    previewPDF?: () => void;
    getShiftDetails?: any;
    setFilterData?: any;
    filterData?: any;
    shiftType?:any;
    isonCalender?:any;
}

const TimeSheetUploadPreviewFile = (props: TimesheetUploadPreviewFile) => {
    const {timesheetFile, getShiftDetails, setFilterData, filterData,shiftType,isonCalender} = props;

    const deleteHcpDocument = useCallback((hcpDocumentFileKey: string) => {
        console.log(hcpDocumentFileKey);
        const payload: any = {};
        payload.file_type = "timesheet";
        payload.file_key = hcpDocumentFileKey;
        CommonService._meta.DeleteDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                if(isonCalender){
                    setFilterData({...filterData});
                    getShiftDetails();
                }else{
                    setFilterData({...filterData, status: shiftType});
                    getShiftDetails();
                }
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.message || "Failed To delete Document", "error");
        });
    }, [getShiftDetails, isonCalender, setFilterData, filterData, shiftType]);

    const downloadHcpDocument = useCallback((hcpDocumentFileKey: string) => {
        const payload: any = {};
        payload.file_key = hcpDocumentFileKey;
        CommonService._meta.DownloadDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                const link = document.createElement('a');
                link?.setAttribute('href', response?.data?.data);
                document.body.appendChild(link);
                link.click();
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To download Document", "error");
        });
    }, []);

    return (
        <>
            <div className={'preview-component'}>
                <div className={'preview-file-name'}>
                    <div className={'mrg-right-10'}>
                        <ImageConfig.StepperCompletedIcon/>
                    </div>
                    <div  className={'preview-file-name-text'}>
                        Timesheet Uploaded
                    </div>
                </div>
                <div className={'btn-wrapper'}>
                    <div className={"download-box"} onClick={() => {
                        downloadHcpDocument(timesheetFile?.key);
                    }}>
                        <ImageConfig.DownloadIconPreview/>
                    </div>
                    <div className={"delete-box"} onClick={() => {
                        deleteHcpDocument(timesheetFile?.key);
                    }}>
                        <ImageConfig.DeleteIconPreview/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TimeSheetUploadPreviewFile;