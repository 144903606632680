import * as Yup from 'yup';
import React, {useCallback, useEffect, useState} from 'react';
import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import {useNavigate} from 'react-router-dom';
import DatePickers from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import './AddPreApprovedShiftDetailsComponent.scss';
import moment from 'moment';
import { useSelector} from 'react-redux';
import {IRootReducerState} from '../../../../store/reducers';
import {CommonService} from '../../../../shared/services';
import {IAPIResponseType} from '../../../../shared/models/api.model';
import LabelComponent from '../../../../shared/components/label/LabelComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import { shift_type} from '../../../../data';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import ErrorComponent from '../../../../shared/components/error/ErrorComponent';
import RadioButtonGroupComponent
    from '../../../../shared/components/form-controls/radio-button-group/RadioButtonGroupComponent';
import {addMonths} from 'date-fns'; // Import addMonths function from date-fns library
import FormikSelectDropdownComponent
    from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormikTextAreaComponent
    from '../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import FormikTimePickerComponent
    from "../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent";
import {Misc} from "../../../../constants";
import PreApprovedHomeHealthComponent from "./pre-approved-home-health-hospice/PreApprovedHomeHealthComponent";
import PreApprovedContractTravelComponent from "./pre-approved-contract-travel/PreApprovedContractTravelComponent"
// Calculate the date 6 months from now
const sixMonthsFromNow = addMonths(new Date(), 6);

interface AddPreApprovedShiftDetailsComponentProps {
    setPreApprovedShiftHeader?: any;
    onCalender?: any;
    selectedDateFromCalender?: any;
    isShiftCreationForSingleDate?: any;
    schedulingListFilterState?: any;
    setSchedulingListFilterState?: any;
    setOpenDialog?: any;
}

const shiftDetailsValidationSchema = Yup.object().shape({
    shift_type: Yup.string().required('Shift Type is required'),
    start_time:Yup.string().required('Start time is required'),
    end_time:Yup.string().required('End time is required'),
});

interface formInitialValuesFormSchema {
    facility_id: string;
    facility_wing_id: any;
    shift_type: any;
    note:string;
    shift_dates:any;
    start_time:any;
    end_time:any;
    shift_rate:any;
    hcp_ids:any;
}

const AddPreApprovedShiftDetailsComponent = (props: AddPreApprovedShiftDetailsComponentProps) => {
    const {
        setPreApprovedShiftHeader,
        onCalender,
        selectedDateFromCalender,
        setSchedulingListFilterState,
        schedulingListFilterState,
        setOpenDialog,
    } = props;
    const formInitialValues: formInitialValuesFormSchema = {
        facility_id: "",
        facility_wing_id: null,
        shift_type: "",
        note:"",
        shift_dates: selectedDateFromCalender ? selectedDateFromCalender : null,
        start_time:"",
        end_time:"",
        shift_rate:"",
        hcp_ids:"",
    };
    const [initialValues] =
        useState<formInitialValuesFormSchema>(formInitialValues);
    const { wingsList} = useSelector(
        (state: IRootReducerState) => state.staticData,
    );
    const navigate = useNavigate();
    const [modifiedWingsList, setModifiedWingsList] = useState<any>(null);
    const [value, setValue] = useState<any>(
        onCalender ? [selectedDateFromCalender] : null,
    );
    const [selectedFacilityDetails, setSelectedFacilityDetails] =
        useState<any>(null);
    const [selectedShiftType, setSelectedShiftType] = useState<any>(null);
    const [selectedHcpType, setSelectedHcpType] = useState<any>(null);
    const [internalHcpListRequirementBased, setInternalHcpListRequirementBased] =
        useState<any>([]);
    const [floatHcpListRequirementBased, setFloatHcpListRequirementBased] =
        useState<any>([]);
    const [
        modifiedInternalHcpListRequirementBased,
        setModifiedInternalHcpListRequirementBased,
    ] = useState<any>([]);
    const [
        modifiedFloatHcpListRequirementBased,
        setModifiedFloatHcpListRequirementBased,
    ] = useState<any>([]);
    const [selectedShiftRequirementType, setSelectedShiftRequirementType] =
        useState<any>('open-shift');
    const [selectedStaff,setSelectedStaff]=useState<any>("")


    const getFacilityDetails = useCallback(() => {
        const payload = {};
        CommonService._facility
            .FacilityDetailsAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setSelectedFacilityDetails(response.data);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        if (selectedShiftRequirementType === 'open-shift') {
            getFacilityDetails();
        }
    }, [getFacilityDetails, selectedShiftRequirementType]);

    useEffect(() => {
        if (wingsList) {
            const modifiedData = wingsList?.map((item: any) => {
                const {_id, name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

                return {
                    ...rest, // Keep the other properties unchanged
                    title: name,
                    code: _id, // Add the new key "newName" with the value of the old "name"
                };
            });
            setModifiedWingsList(modifiedData);
        } else {
            setModifiedWingsList(null);
        }
    }, [wingsList]);

    const onSubmit = useCallback(
        (values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
            let shift_dates;
            shift_dates = value.map((item: any) => {
                let mm = item?.month?.number;
                let dd = item?.day;
                let yyyy = item?.year;
                return moment(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
            });
            const payload={
                ...values,
                hcp_ids:[values?.hcp_ids],
                start_time: CommonService.convertToAdjustedMinutes(
                    values.start_time,
                ),
                end_time: CommonService.convertToAdjustedMinutes(
                    values.end_time,
                ),
                shift_rate:parseInt(values?.shift_rate) || 0,
                shift_dates:shift_dates,
            }
            let apiCall: any = {};
            apiCall =
                CommonService._shift_management.preApprovedOpenShiftAPICall(
                    payload,
                );
            apiCall
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    if (onCalender) {
                        setOpenDialog(false);
                        setSchedulingListFilterState({...schedulingListFilterState});
                        // navigate(CommonService._routeConfig.SchedulerRoute());
                    } else {
                        navigate(CommonService._routeConfig.ShiftManagementRoute());
                    }
                })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    CommonService._alert.showToast(error?.error, 'error');
                })
                .finally(() => {
                    setSubmitting(false);
                });
            //eslint-disable-next-line
        },
        [
            navigate,
            value,
            onCalender,
            setSchedulingListFilterState,
            schedulingListFilterState,
            setOpenDialog
        ],
    );

    function handleDatePicker(value: any) {
        // console.log(value);
        setValue(value);
    }

    const handleInternalHCPListSubmit = useCallback(() => {
        const payload: any = {
            //  agency_id: selectedAgencyForHcp?.selectedAgency
        };
        payload.is_float = false;
        payload.is_internal_hcp = true;
        payload.hcp_type_id = selectedHcpType?.code;
        CommonService._hcp
            .HcpListRequirementBasedAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setInternalHcpListRequirementBased(response?.data);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [ selectedHcpType]);

    const handleFloatHcpListSubmit = useCallback(() => {
        const payload: any = {
            //  agency_id: selectedAgencyForHcp?.selectedAgency
        };
        payload.is_float = true;
        payload.is_internal_hcp = false;
        payload.hcp_type_id = selectedHcpType?.code;
        CommonService._hcp
            .HcpListRequirementBasedAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setFloatHcpListRequirementBased(response?.data);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [selectedHcpType]);

    useEffect(() => {
        if ( selectedShiftRequirementType === 'open-shift') {
            handleInternalHCPListSubmit();
            handleFloatHcpListSubmit();
        }
    }, [
        handleFloatHcpListSubmit,
        handleInternalHCPListSubmit,
        selectedShiftRequirementType,
    ]);

    useEffect(() => {
        if (internalHcpListRequirementBased) {
            const modifiedData = internalHcpListRequirementBased?.map((item: any) => {
                const {_id, first_name, last_name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

                return {
                    ...rest, // Keep the other properties unchanged
                    title: first_name + ' ' + last_name,
                    code: _id, // Add the new key "newName" with the value of the old "name"
                };
            });
            setModifiedInternalHcpListRequirementBased(modifiedData);
        } else {
            setModifiedInternalHcpListRequirementBased(null);
        }
    }, [internalHcpListRequirementBased]);

    useEffect(() => {
        if (floatHcpListRequirementBased) {
            const modifiedData = floatHcpListRequirementBased?.map((item: any) => {
                const {_id, first_name, last_name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

                return {
                    ...rest, // Keep the other properties unchanged
                    title: first_name + ' ' + last_name,
                    code: _id, // Add the new key "newName" with the value of the old "name"
                };
            });
            setModifiedFloatHcpListRequirementBased(modifiedData);
        } else {
            setModifiedFloatHcpListRequirementBased(null);
        }
    }, [floatHcpListRequirementBased]);

    // const shiftTimings =
    //     selectedFacilityDetails?.shift_timings?.map(
    //         (item: { shift_type: any }) => item.shift_type,
    //     ) || [];

    // const filteredShifts = shift_type.filter((shift: { code: any }) =>
    //     shiftTimings.includes(shift?.code),
    // );

    useEffect(() => {
        console.log(selectedShiftType);
        console.log(selectedFacilityDetails);
        if (selectedShiftType) {
            const restructuredData = selectedFacilityDetails?.shift_timings
                ?.filter(
                    (item: { shift_type: any }) => selectedShiftType === item?.shift_type,
                )
                ?.map(
                    (item: {
                        shift_start_time: any;
                        shift_end_time: any;
                        shift_type: any;
                    }) => ({
                        title: `${CommonService.convertMinutesToTime(
                            item.shift_start_time,
                        )} - ${CommonService.convertMinutesToTime(item.shift_end_time)} (${
                            item.shift_type
                        }-Shift)`,
                        timings: {
                            start_time: item.shift_start_time,
                            end_time: item.shift_end_time,
                        },
                    }),
                );

            console.log(restructuredData);
        } else {

        }
    }, [selectedFacilityDetails, selectedShiftType]);


    const handleStaffSelection=useCallback((value:string,setFieldValue:any)=>{
        setSelectedStaff(value)
        setFieldValue('hcp_ids',"")
    },[])

    return (
        <div
            className={
                'add-shift-requirement-details-screen details-screen add-screen dialog-large-width'
            }
        >

            <div className={'pdd-right-25 pdd-left-25 mrg-top-20'}>
                <RadioButtonGroupComponent
                    options={[
                        {
                            title: 'Per Diem',
                            code: 'open-shift',
                        },
                        {title: 'Per Job/Visit', code: 'home-health-hospice'},
                        {title: 'Contract / Travel', code: 'contract-travel'},
                    ]}
                    valueExtractor={(option: any) => option.code}
                    onChange={(e) => {
                        setSelectedShiftRequirementType(e);
                        setSelectedHcpType(null);
                        setSelectedShiftType(null);
                        setValue(null);
                        setSelectedStaff("");
                        if (e === "open-shift") {
                            setPreApprovedShiftHeader("Create Pre Approved Shift Requirement")
                        } else if (e === "home-health-hospice") {
                            setPreApprovedShiftHeader("Create Pre Approved Per Job Visit")
                        } else if (e === "contract-travel") {
                            setPreApprovedShiftHeader("Create Pre Approved Contract / Travel Visit")
                        }
                    }}
                    value={selectedShiftRequirementType}
                />
            </div>

            <>
                {selectedShiftRequirementType === 'open-shift' && (
                    <>
                        <Formik
                            validationSchema={shiftDetailsValidationSchema}
                            initialValues={initialValues}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={onSubmit}
                        >
                            {({
                                  touched,
                                  values,
                                  errors,
                                  validateForm,
                                  isValid,
                                  setFieldValue,
                                  isSubmitting,
                                  handleBlur,
                              }) => {
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                useEffect(() => {
                                    validateForm();
                                }, [validateForm, values]);
                                return (
                                    <Form className='t-form scroll-content' noValidate={true}>
                                        {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                        <div className='add-component-content'>
                                            <>
                                                <div className={'body-min-height'}>
                                                    <div className={'requirement-form-body-wrapper '}>
                                                        <div className={'ts-row'}>
                                                            <div className='ts-col-4'>
                                                                <Field name={'facility_wing_id'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={modifiedWingsList}
                                                                            displayWith={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            valueExtractor={(option: any) =>
                                                                                option.code
                                                                            }
                                                                            label={'Wing'}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-4'>
                                                                <Field name={'shift_type'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikSelectDropdownComponent
                                                                            options={shift_type}
                                                                            displayWith={(option: any) =>
                                                                                option.title
                                                                            }
                                                                            valueExtractor={(option: any) =>
                                                                                option.code
                                                                            }
                                                                            label={'Shift Type*'}
                                                                            placeholder={'Select'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            onUpdate={(e: any) => {
                                                                                setSelectedShiftType(e);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='ts-col-4'>
                                                                <Field name={'shift_rate'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Shift Rate'}
                                                                            placeholder={'Enter value ($/hr)'}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                            type={'number'}
                                                                            validationPattern={new RegExp(/^(?:[0-9]{1,3}(?:\.[0-9]{1,3})?)?$/)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                'shift-requirement-divider-line mrg-bottom-20'
                                                            }
                                                        />
                                                        <LabelComponent
                                                            title={'Select Shift Timings'}
                                                            isBold={true}
                                                        />
                                                        <div className={'ts-row mrg-top-10'}>
                                                            <div className={'ts-col-4'}>
                                                                <div>
                                                                    <LabelComponent
                                                                        title={'Shift Date(s)*'}
                                                                    />
                                                                    <div
                                                                        className='shift-calender mrg-bottom-20'>
                                                                        <Field
                                                                            required={true}
                                                                            onUpdate={(e: any) => {
                                                                                setFieldValue('shift_dates', []);
                                                                            }}
                                                                            inputClass='custom-input'
                                                                            className='rmdp-mobile'
                                                                            plugins={[
                                                                                <DatePanel eachDaysInRange/>,
                                                                            ]}
                                                                            format='MM/DD/YYYY'
                                                                            //  range={mode === "range" ? true : false}
                                                                            multiple={true}
                                                                            onChange={handleDatePicker}
                                                                            value={value}
                                                                            variant='inline'
                                                                            inputVariant='outlined'
                                                                            placeholder={'Select'}
                                                                            id='input_shift_requirement_shift_datepicker'
                                                                            name='shift_dates'
                                                                            InputLabelProps={{shrink: true}}
                                                                            component={DatePickers}
                                                                            minDate={new Date()}
                                                                            maxDate={sixMonthsFromNow}
                                                                        />
                                                                        <div
                                                                            className={
                                                                                'd-flex justify-content-right'
                                                                            }
                                                                        >
                                                                            {touched.shift_dates &&
                                                                            (!value ||
                                                                                (value && value?.length === 0)) ? (
                                                                                // <div>{errors.hcp_type_id}</div>
                                                                                <ErrorComponent
                                                                                    errorText={
                                                                                        'Shift Dates is required'
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'ts-col-4'}>
                                                                <LabelComponent title={'Start Time*'}/>
                                                                <Field name={'start_time'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikTimePickerComponent
                                                                            placeholder={'Select'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className={'ts-col-4'}>
                                                                <LabelComponent title={'End Time*'}/>
                                                                <Field name={'end_time'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikTimePickerComponent
                                                                            placeholder={'Select'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                'shift-requirement-divider-line mrg-bottom-20'
                                                            }
                                                        />
                                                        <LabelComponent
                                                            title={'Add Staff to the Shift from'}
                                                            isBold={true}
                                                        />
                                                        <div className={'ts-row mrg-top-20 pdd-left-20'}>
                                                            <RadioButtonGroupComponent
                                                                options={
                                                                    [
                                                                        {
                                                                            title: "Internal Staff*",
                                                                            code: "internal-staff"
                                                                        },
                                                                        {
                                                                            title: 'Floating Staff*',
                                                                            code: 'floating-staff'
                                                                        }
                                                                    ]
                                                                }
                                                                value={selectedStaff}
                                                                valueExtractor={(option:any)=>option.code}
                                                                onChange={(value:any)=>{
                                                                    handleStaffSelection(value,setFieldValue)
                                                                }}
                                                            />
                                                        </div>
                                                        {(selectedStaff === 'internal-staff') &&
                                                            <div className={'ts-row pdd-10 mrg-top-10'}>
                                                                <>
                                                                    <Field name={'hcp_ids'}>
                                                                        {(field: FieldProps) => (
                                                                            <FormikSelectDropdownComponent
                                                                                options={modifiedInternalHcpListRequirementBased}
                                                                                displayWith={(option: any) =>
                                                                                    option.title
                                                                                }
                                                                                valueExtractor={(option: any) =>
                                                                                    option.code
                                                                                }
                                                                                placeholder={'Select'}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                                onUpdate={(value)=>{
                                                                                    setFieldValue('hcp_ids',value ? value : "")
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </>
                                                            </div>
                                                        }
                                                        {
                                                            selectedStaff === 'floating-staff' &&
                                                            <div className={'ts-row pdd-10 mrg-top-10'}>
                                                                <>
                                                                    <Field name={'hcp_ids'}>
                                                                        {(field: FieldProps) => (
                                                                            <FormikSelectDropdownComponent
                                                                                options={modifiedFloatHcpListRequirementBased}
                                                                                displayWith={(option: any) =>
                                                                                    option.title
                                                                                }
                                                                                valueExtractor={(option: any) =>
                                                                                    option.code
                                                                                }
                                                                                placeholder={'Select'}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                                onUpdate={(value)=>{
                                                                                    setFieldValue('hcp_ids',value ? value : "")
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </>
                                                            </div>

                                                        }

                                                        <div
                                                            className={
                                                                'shift-requirement-divider-line mrg-bottom-20 mrg-top-10'
                                                            }
                                                        />
                                                        <LabelComponent
                                                            title={'Requirement Details'}
                                                            isBold={true}
                                                        />
                                                        <div className='ts-row mrg-top-20'>
                                                            <div className='ts-col-12 mrg-bottom-20'>
                                                                <Field name={'note'}>
                                                                    {(field: FieldProps) => (
                                                                        <FormikTextAreaComponent
                                                                            label={''}
                                                                            placeholder={'Enter Requirement Details'}
                                                                            rows={3}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            <div
                                                className={
                                                    'mrg-top-15 d-flex ts-justify-content-center'
                                                }
                                            >
                                                <ButtonComponent
                                                    disabled={
                                                        isSubmitting ||
                                                        !isValid ||
                                                        !value ||
                                                        value?.length === 0 ||
                                                        values?.hcp_ids?.length === 0
                                                    }
                                                    isLoading={isSubmitting}
                                                    type={'submit'}
                                                    color={'download-button'}
                                                >
                                                    Create Pre Approved Shift
                                                </ButtonComponent>
                                            </div>

                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
                { selectedShiftRequirementType === 'home-health-hospice' && (
                    <PreApprovedHomeHealthComponent
                        setSelectedHcpType={setSelectedHcpType}
                        selectedHcpType={selectedHcpType}
                        sixMonthsFromNow={sixMonthsFromNow}
                        getFacilityDetails={getFacilityDetails}
                        handleInternalHCPListSubmit={handleInternalHCPListSubmit}
                        handleFloatHcpListSubmit={handleFloatHcpListSubmit}
                        setShiftTypeHeader={setPreApprovedShiftHeader}
                        selectedDateFromCalender={selectedDateFromCalender}
                        onCalender={onCalender}
                        modifiedInternalHcpListRequirementBased={modifiedInternalHcpListRequirementBased}
                        modifiedFloatHcpListRequirementBased={modifiedFloatHcpListRequirementBased}
                        selectedStaff={selectedStaff}
                        setSelectedStaff={setSelectedStaff}
                        setOpenDialog={setOpenDialog}
                        setSchedulingListFilterState={setSchedulingListFilterState}
                        schedulingListFilterState={schedulingListFilterState}
                    />
                )}

                {selectedShiftRequirementType === 'contract-travel' && (
                    <PreApprovedContractTravelComponent
                        setSelectedHcpType={setSelectedHcpType}
                        selectedHcpType={selectedHcpType}
                        sixMonthsFromNow={sixMonthsFromNow}
                        getFacilityDetails={getFacilityDetails}
                        handleInternalHCPListSubmit={handleInternalHCPListSubmit}
                        handleFloatHcpListSubmit={handleFloatHcpListSubmit}
                        setShiftTypeHeader={setPreApprovedShiftHeader}
                        selectedDateFromCalender={selectedDateFromCalender}
                        onCalender={onCalender}
                        modifiedInternalHcpListRequirementBased={modifiedInternalHcpListRequirementBased}
                        modifiedFloatHcpListRequirementBased={modifiedFloatHcpListRequirementBased}
                        selectedStaff={selectedStaff}
                        setSelectedStaff={setSelectedStaff}
                        setOpenDialog={setOpenDialog}
                        setSchedulingListFilterState={setSchedulingListFilterState}
                        schedulingListFilterState={schedulingListFilterState}
                    />
                )}
            </>
        </div>
    );
};

export default AddPreApprovedShiftDetailsComponent;
