import "./CustomToggleComponent.scss";

interface CustomToggleComponentProps {
    toggleHandler: (e: any) => void;
    defaultOn?: boolean;
}

const CustomToggleComponent = (props: CustomToggleComponentProps) => {
    const {defaultOn, toggleHandler} = props;

    return (
        <div
            className={`custom-toggle ${(defaultOn) ? 'active' : ''}`}
            onClick={toggleHandler}
        >
            <div className="switch"></div>
        </div>
    );
};
export default CustomToggleComponent;