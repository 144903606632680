import React, {useState} from 'react';
import "./ToggleComponent.scss";

interface ToggleProps {
    defaultOn?: boolean;
    onToggle?: (isActive: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({defaultOn = false, onToggle,  }) => {
    const [isActive, setIsActive] = useState(defaultOn);

    const handleToggle = () => {
        const updatedValue = !isActive;
            setIsActive(updatedValue);

        if (onToggle) {
            onToggle(updatedValue);
        }
    };

    return (
        <div>
            <label className="toggle-switch">
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={handleToggle}
                />
                <span className="slider round"></span>
            </label>
        </div>
    );
};

export default Toggle;
