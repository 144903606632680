import './TimePickerComponent.scss';
import React, {useCallback, useEffect, useState} from 'react';
import FormControl from "@mui/material/FormControl";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {FormHelperText} from "@mui/material";
import {ITimePickerProps} from "../../../models/form-controls.model";
import {MobileTimePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {ImageConfig} from "../../../../constants";
import moment from "moment";
interface TimePickerComponentProps extends ITimePickerProps {
    name: string;
    onChange?: (time: string) => void;
    hasError?: boolean;
    errorMessage?: any;
    value?: any;
    size?: 'small' | 'medium';
    color?: 'primary' | 'secondary';
    variant?: 'standard' | 'filled' | 'outlined';
}

const TimePickerComponent = (props: TimePickerComponentProps) => {

    const {
        name,
        id,
        label,
        required,
        hasError,
        errorMessage,
        size,
        fullWidth
    } = props;

    const [value, setValue] = useState<string | any>('');
    //  const [open, setOpen] = useState<boolean>(false);

    let {placeholder, color, variant, onChange} = props;
    if (!placeholder) placeholder = label;
    if (!variant) variant = "outlined";

    const handleChange = useCallback((newValue: string | null) => {
        setValue(newValue);
        if (onChange) onChange(newValue || '');
    }, [onChange, setValue]);

    // useEffect(() => {
    //     setValue(props.value ? new Date(props.value) : null);
    // }, [props.value]);

    useEffect(() => {
        //   console.log(props?.value);
        setValue(props.value ? moment(props.value) : null);
    }, [props.value]);

    return (
        <FormControl variant={variant}
                     className="time-picker-component-wrapper"
                     fullWidth={fullWidth}
                     error={hasError}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className={'clock-icon'}>
                    <ImageConfig.CalendarTimePicker/>
                </div>
                <MobileTimePicker closeOnSelect={true}
                                  renderInput={
                                      params => {
                                          // console.log({...params})
                                          return (<TextField
                                              {...params}
                                              required={required}
                                              className={'time-picker-input'}
                                              id={id}
                                              // value={(moment(value).format('hh:mm a'))}
                                              fullWidth={fullWidth}
                                              size={size}
                                              name={name}
                                              color={color}
                                              placeholder={placeholder}
                                              error={hasError}
                                          />);
                                      }
                                  } value={value}
                                  onChange={handleChange}
                                  label={label}
                />
            </LocalizationProvider>
            <FormHelperText>
                {hasError && errorMessage}
            </FormHelperText>
        </FormControl>
    );
};

export default TimePickerComponent;
