import {IActionModel} from "../../shared/models/action.model";
import {IUser} from "../../shared/models/user.model";
import {GET_ALL_USERS_LIST, SET_ALL_USERS_LIST} from "../actions/user.action";

export interface IUserReducerState {
    allUsersList: IUser[],
    isAllUsersListLoading: boolean,
    isAllUsersListLoaded: boolean
}


const initialData: IUserReducerState = {
    allUsersList: [],
    isAllUsersListLoading: false,
    isAllUsersListLoaded: false
};

const UserReducer = (state = initialData, action: IActionModel): IUserReducerState => {
    switch (action.type) {
        case GET_ALL_USERS_LIST:
            state = {
                ...state,
                isAllUsersListLoading: true,
                isAllUsersListLoaded: false,
            };
            return state;
        case SET_ALL_USERS_LIST:
            state = {
                ...state,
                isAllUsersListLoading: false,
                isAllUsersListLoaded: true,
                allUsersList: action.payload.allUsersList
            };
            return state;
        default:
            return state;
    }
};

export default UserReducer;
