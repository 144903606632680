import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import React from "react";
import {ImageConfig} from "../../../constants";

interface StepperComponentsProps {
    activeStep?: any;
    steps?: any;
}

const CustomStepIcon = (props: any) => {
    const {active, completed, step} = props;
    if (completed) {
        return <ImageConfig.StepperCompletedIcon/>;
    } else if (active) {
        return (
            <div className={'stepper-active-container d-flex justify-content-center align-items-center'}>
                <span className={'stepper-step-number'}>{step}</span>
            </div>
        );
    } else {
        return (
            <div className={'stepper-disabled-container d-flex justify-content-center align-items-center'}>
                <span className={'stepper-step-number'}>{step}</span>
            </div>
        );
    }
};


const StepperComponent = (props: StepperComponentsProps) => {
    const {activeStep, steps} = props;
    return (
        <>
            <Box sx={{maxWidth: 400}}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps?.map((step: any, index: any) => (
                        <Step key={step.label}>
                            <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props}
                                                                                     step={index + 1}/>} {...step}>
                                {step?.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>{step?.description}</Typography>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </>
    );
};

export default StepperComponent;