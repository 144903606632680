import './FormikDatePickerComponent.scss';
import React, {useCallback} from 'react';
import _ from "lodash";
import {FieldProps} from "formik";
import {ITimePickerProps} from "../../../models/form-controls.model";
import DatePickerComponent from "../date-picker/DatePickerComponent";

interface FormikTimePickerComponentProps extends ITimePickerProps {
    formikField: FieldProps;
    onUpdate?: any;
    disabled?: boolean;
}

const FormikDatePickerComponent = (props: FormikTimePickerComponentProps) => {

        const {
            formikField,
            onUpdate,
            disabled,
            ...otherProps
        } = props;

        const {field, form} = formikField;
        const {name, value} = field;
        const {setFieldTouched, touched, errors, setFieldValue} = form;
        const hasError = _.get(touched, name) && !!(_.get(errors, name));
        otherProps.id = otherProps.id || name;

        const dateChangeHandler = useCallback((value: string | null) => {
            setFieldValue(name, value);
            setFieldTouched(name);
            if (onUpdate) {
                onUpdate(value);
            }
        }, [setFieldValue, setFieldTouched, name, onUpdate]);

        return (
            <DatePickerComponent
                name={name}
                disabled={disabled}
                value={value}
                onChange={dateChangeHandler}
                hasError={hasError}
                errorMessage={hasError && _.get(errors, name)}
                {...otherProps}
            />
        );
    }

;

export default FormikDatePickerComponent;