import "./CheckBoxComponent.scss";
import {useCallback, useEffect, useState} from "react";
import {ICheckBoxProps} from "../../../models/form-controls.model";
import LabelComponent from "../../label/LabelComponent";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";

interface CheckBoxComponentProps extends ICheckBoxProps {
    checked?: boolean;
    value?: any;
    color?: string;
}

const CheckBoxComponent = (props: CheckBoxComponentProps) => {

    const {
        label,
        checked,
        className,
        disabled,
        id,
        hasError,
        errorMessage,
        onChange,
        required,
        value,
        readOnly,
        inputProps,
        color
    } = props;
    const [tmpChecked, setTmpChecked] = useState(checked);

    useEffect(() => {
        setTmpChecked(checked || false);
    }, [checked]);

    const handleCheckBoxOnChange = useCallback((event: any) => {
        if (readOnly) {
            return;
        }
        const isChecked = event.target.checked;
        setTmpChecked(checked);
        if (onChange) {
            onChange(isChecked);
        }
    }, [readOnly, checked, onChange]);

    return (
        <div
            className={`checkbox-component ${className} ${tmpChecked ? 'checked ' : ''} ${disabled ? 'disabled' : ''} ${hasError ? 'has-error' : ''}`}>
            <label id={id} className={"checkbox-container " + (disabled ? 'disable' : '')}>
                {label && <LabelComponent required={required} title={label}/>}
                <input
                    disabled={disabled}
                    checked={tmpChecked}
                    onChange={handleCheckBoxOnChange}
                    value={value}
                    className={`checkbox-field`}
                    type="checkbox"
                    readOnly={readOnly}
                    {...inputProps}
                />
                <span {...inputProps} className= {`checkbox-checkmark + ${color ? color : ''}`}/>
            </label>
            {(errorMessage && hasError) && (
                <ErrorTextComponent error={errorMessage}/>
            )}
        </div>
    );

};

export default CheckBoxComponent;
