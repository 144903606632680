export const GET_PROPERTY_LIST_LITE = 'GET_PROPERTY_LIST_LITE';
export const SET_PROPERTY_LIST_LITE = 'SET_PROPERTY_LIST_LITE';

export const GET_PROPERTY_FM_LIST = 'GET_PROPERTY_FM_LIST';
export const SET_PROPERTY_FM_LIST = 'SET_PROPERTY_FM_LIST';

export const getPropertyListLite = () => {
    return {type: GET_PROPERTY_LIST_LITE};
};

export const setPropertyListLite = (propertyList: any[]) => {
    return {
        type: SET_PROPERTY_LIST_LITE, payload: {
            propertyList
        }
    };
};

export const getPropertyFMList = (propertyId: string) => {
    return {
        type: GET_PROPERTY_FM_LIST, payload: {
            propertyId
        }
    };
};

export const setPropertyFMList = (propertyFMsList: any[]) => {
    return {
        type: SET_PROPERTY_FM_LIST, payload: {
            propertyFMsList
        }
    };
};
