import {IUser} from "../../shared/models/user.model";

export const GET_ALL_USERS_LIST = 'GET_ALL_USERS_LIST';
export const SET_ALL_USERS_LIST = 'SET_ALL_USERS_LIST';

export const getAllUsersList = () => {
    return {type: GET_ALL_USERS_LIST};
};

export const setAllUsersList = (allUsersList: IUser[]) => {
    return {
        type: SET_ALL_USERS_LIST, payload: {
            allUsersList
        }
    };
};
