import "./CommonHcpDetailsCard.scss";
import React, {useState} from "react";
import {ImageConfig} from "../../../../../../constants";
import ButtonComponent from "../../../../../../shared/components/button/ButtonComponent";
import {CommonService} from "../../../../../../shared/services";
import HorizontalLineComponent
    from "../../../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";


interface CommonHcpDetailsCardProps {
    hcpDetails?: any;
    innerShiftDetails?: any;
    getHcpDetails: any;
}

const CommonHcpDetailsCard = (props: CommonHcpDetailsCardProps) => {
    const {hcpDetails, innerShiftDetails, getHcpDetails} = props;
    const [isMarkingHcpAsFavorite, setIsMarkingHcpAsFavorite] = useState<boolean>(false);
    const [isDeMarkingHcpAsFavorite, setIsDeMarkingHcpAsFavorite] = useState<boolean>(false);
    
    return (
        <>
            <div className={'hcp-common-card-details dialog-card'}>
                <div className={'d-flex ts-align-items-center justify-content-space-between mrg-bottom-15'}>
                    <div className={'d-flex ts-align-items-center'}>
                        <div>
                            {hcpDetails?.hcp_profile_picture?.url &&
                                <img className={'profile-image-wrapper'} src={hcpDetails?.hcp_profile_picture?.url}
                                     loading="lazy" alt={'profile_image'}/>
                            }
                            {(!hcpDetails?.hcp_profile_picture?.url && hcpDetails?.gender === "female") &&
                                <ImageConfig.HCPFemaleIcon/>
                            }
                            {(!hcpDetails?.hcp_profile_picture?.url && hcpDetails?.gender === "male") &&
                                <ImageConfig.HCPMaleIcon/>
                            }
                        </div>
                        <div className={'mrg-left-15'}>
                            <div className={'d-flex ts-align-items-center mrg-bottom-5'}>
                                <div
                                    className={'hcp-name-text'}>{hcpDetails?.first_name + " " + hcpDetails?.last_name || '-'}</div>
                                <div className={'hcp-type-text mrg-left-10'}>( {hcpDetails?.hcp_type_details?.title} )
                                </div>
                            </div>
                            <div className={'d-flex'}>
                                <div
                                    className={'returning-text'}>{innerShiftDetails?.hcp_status === "Returning" ? "Returning" : "New"}&nbsp;</div>
                                <div
                                    className={'agency-name-text'}>{hcpDetails?.agency_details?.name ? ` | ${hcpDetails?.agency_details?.name}` : ""}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {(!hcpDetails?.is_favourite && !hcpDetails?.is_internal_hcp && !hcpDetails?.is_internal_hcp && !innerShiftDetails?.is_internal_hcp && !innerShiftDetails?.is_float) &&
                            <ButtonComponent disabled={isMarkingHcpAsFavorite} color={'empty-button'}
                                             isLoading={isMarkingHcpAsFavorite} onClick={() => {
                                CommonService.markHcpAsFavorite(setIsMarkingHcpAsFavorite, hcpDetails?.agency_details?._id, hcpDetails?._id, getHcpDetails);
                            }} prefixIcon={<ImageConfig.MarkAsFavoriteIcon/>}>
                            </ButtonComponent>
                        }
                    </div>
                    {hcpDetails?.is_favourite &&
                        <ButtonComponent color={'remove-as-favorite'}
                                         onClick={() => {
                                             CommonService.deMarkHcpAsFavorite(setIsDeMarkingHcpAsFavorite, hcpDetails?._id, getHcpDetails);
                                         }}
                                         isLoading={isDeMarkingHcpAsFavorite}
                                         disabled={isDeMarkingHcpAsFavorite}
                        >
                            Remove Favorite
                        </ButtonComponent>}
                </div>
                <HorizontalLineComponent/>
                <div className={'mrg-bottom-15 mrg-top-15 d-flex d-flex-space-between'}>
                    <div className={'d-flex'}>
                        <div className={'d-flex ts-align-items-center mrg-left-10'}>
                            <div>
                                <div><ImageConfig.GenderIcon/></div>
                            </div>
                            <div className={'gender-text mrg-left-10'}>
                                {CommonService.capitalizeFirstLetter(hcpDetails?.gender)}
                            </div>
                        </div>
                        <div className={'d-flex ts-align-items-center mrg-left-10'}>

                            <div><ImageConfig.FacilityCountIcon/></div>

                            <div className={'gender-text mrg-left-10'}>
                                0 Shifts
                            </div>
                        </div>
                    </div>
                    <div className={'differential-hcp-rate mrg-right-10'}>
                        Differential Rate : -
                    </div>
                </div>
                <HorizontalLineComponent/>
            </div>

        </>
    );
};

export default CommonHcpDetailsCard;

