import "./ShiftCardComponent.scss";
import {CommonService} from "../../../../shared/services";
import {ImageConfig} from "../../../../constants";
import React, {useEffect, useState} from "react";
import DialogComponent from "../../../../shared/components/dialog-component/DialogComponent";
import DrawerComponent from "../../../../shared/components/drawer/DrawerComponent";
import ApprovedShiftDetailsComponent
    from "../../../shift-requirement/shift-details/approved-shift-details/ApprovedShiftDetailsComponent";
import CompletedShiftDetailsComponent
    from "../../../shift-requirement/shift-details/completed-shift-details/CompletedShiftDetailsComponent";
import ConfirmedShiftDetailsComponent
    from "../../../shift-requirement/shift-details/confirmed-shift-details/ConfirmedShiftDetailsComponent";
import CancelledShiftDetailsComponent
    from "../../../shift-requirement/shift-details/cancelled-shift-details/CancelledShiftDetailsComponent";
import {Tooltip} from "@mui/material";
import OpenShiftViewDetailsCreationComponent
    from "../weekly-daily-open-shift-creation-view-details/OpenShiftCreationViewDetailsComponent";
import {shiftTypeLayoutRepresentation} from "../../../../data";
import PendingShiftDetailsComponent
    from "../../../shift-requirement/shift-details/pending-shift-details/PendingShiftDetails";

interface ShiftCardComponentProps {
    shiftDetails: any;
    setSchedulingListFilterState?: any;
    schedulingListFilterState?: any;
    isInternalView?: any;
    pendingShiftDetails?: any;
}

const ShiftCardComponent = (props: ShiftCardComponentProps) => {
    const {shiftDetails, schedulingListFilterState, setSchedulingListFilterState} = props;
    const [openDialog, setOpenDialog] = useState<any>(false);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    useEffect(() => {
        console.log(openDialog);
    }, [openDialog]);
    return (
        <>
            <div className={"shift-details-card-calender"}>
                <DrawerComponent onClose={() => {
                    CommonService._staticData.openDialogPopup(setOpenDrawer, false);
                }} isOpen={openDrawer}>
                    {
                        shiftDetails?.status === "approved" &&
                        <ApprovedShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                       setFilterData={setSchedulingListFilterState}
                                                       shiftDetails={shiftDetails} setOpenDrawer={setOpenDrawer}/>
                    }
                    {
                        shiftDetails?.status === "completed" &&
                        <CompletedShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                        setFilterData={setSchedulingListFilterState}
                                                        shiftDetails={shiftDetails} setOpenDrawer={setOpenDrawer}/>
                    }
                    {
                        shiftDetails?.status === "confirmed" &&
                        <ConfirmedShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                        setFilterData={setSchedulingListFilterState}
                                                        shiftDetails={shiftDetails} setOpenDrawer={setOpenDrawer}/>
                    }
                    {
                        shiftDetails?.status === "cancelled" &&
                        <CancelledShiftDetailsComponent filterData={schedulingListFilterState}
                                                        setFilterData={setSchedulingListFilterState} isOnCalender={true}
                                                        shiftDetails={shiftDetails} setOpenDrawer={setOpenDrawer}/>
                    }
                    {
                        shiftDetails?.status === "pending" &&
                        <PendingShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                      setFilterData={setSchedulingListFilterState}
                                                      shiftDetails={shiftDetails}
                                                      dayMonthView={true} setOpenDrawer={setOpenDrawer}
                        />
                    }


                </DrawerComponent>
                <DialogComponent maxWidth={'xl'} cancel={() => {
                    setOpenDialog(false);
                }} open={openDialog}>
                    <OpenShiftViewDetailsCreationComponent setSchedulingListFilterState={setSchedulingListFilterState}
                                                           schedulingListFilterState={schedulingListFilterState}
                                                           requirementId={shiftDetails?._id}
                                                           setOpenOpenShiftRequirementDialog={setOpenDialog}/>
                </DialogComponent>
                <div className={"pdd-10"}>
                    {
                        shiftDetails?.send_to &&
                        <div className={'shifts-created-for-text d-flex'}>(Shift created for :&nbsp;
                            {shiftDetails?.send_to?.is_internal_hcp &&
                                <div>Internal , &nbsp;</div>}
                            {shiftDetails?.send_to?.is_float &&
                                <div>Float {shiftDetails?.send_to?.specific_agencies && <span>,</span>}&nbsp;</div>}
                            {shiftDetails?.send_to?.specific_agencies &&
                                <div>Agency</div>})
                        </div>
                    }
                    <div className={'flexing'}>
                        <div className={'flex-2'}>
                            <div className={'d-flex'}>
                                <div>
                                    <ImageConfig.CalenderTimeIcon/>
                                </div>
                                <div
                                    className={'mrg-left-10'}>
                                    {(shiftDetails?.status === "open" || shiftDetails?.status === "partially_open" || shiftDetails?.status === "filled" || shiftDetails?.status === "unfilled") &&
                                        <>
                                            {CommonService.convertMinutesToTime(shiftDetails?.shift_timings?.start_time) + "-" + CommonService.convertMinutesToTime(shiftDetails?.shift_timings?.end_time)}
                                        </>
                                    }
                                    {(shiftDetails?.status === "pending") &&
                                        <>
                                            {CommonService.convertMinutesToTime(shiftDetails?.shift_timings?.start_time) + "-" + CommonService.convertMinutesToTime(shiftDetails?.shift_timings?.end_time)}
                                        </>
                                    }
                                    {(shiftDetails?.status !== "open" && shiftDetails?.status !== "partially_open" && shiftDetails?.status !== "filled" && shiftDetails?.status !== "unfilled" && shiftDetails?.status !== "pending") &&
                                        <>
                                            {CommonService.convertMinutesToTime(shiftDetails?.expected?.shift_start_time) + "-" + CommonService.convertMinutesToTime(shiftDetails?.expected?.shift_end_time)}
                                        </>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className={'flex-1'}>
                            <div className={'d-flex'}>
                                <div>
                                    <ImageConfig.CalenderHcpTypeIcon/>
                                </div>
                                <div className={'mrg-left-10'}>
                                    {shiftDetails?.hcp_type_details?.title}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={'flexing mrg-top-10'}>
                        {(shiftDetails?.status === "open" || shiftDetails?.status === "partially_open" || shiftDetails?.status === "filled" || shiftDetails?.status === "unfilled" || shiftDetails?.status === "pending") &&
                            <div className={'flex-2'}>
                                <div className={'d-flex'}>
                                    <div>
                                        <ImageConfig.CalenderHcpNameIcon/>
                                    </div>
                                    <div
                                        className={'mrg-left-10'}>{(shiftDetails?.approved_hcp_count || 0) + "/" + (shiftDetails?.required_hcp_count || 0)}
                                    </div>

                                </div>
                            </div>}


                        {(shiftDetails?.status !== "open" && shiftDetails?.status !== "partially_open" && shiftDetails?.status !== "filled" && shiftDetails?.status !== "unfilled" && shiftDetails?.status !== "pending") &&
                            <div className={'flex-2'}>
                                <div className={'d-flex'}>
                                    <div>
                                        <ImageConfig.CalenderHcpNameIcon/>
                                    </div>
                                    <div className={'mrg-left-10'}>
                                        {shiftDetails?.hcp_details?.first_name + " " + shiftDetails?.hcp_details?.last_name}
                                    </div>

                                </div>
                            </div>}


                        <div className={'flex-1 '}>
                            <div className={'d-flex'}>
                                <div>
                                    <ImageConfig.CalenderWingNameIcon/>
                                </div>
                                <Tooltip title={shiftDetails?.facility_wing_details?.name} placement={"top"}>
                                    <div
                                        className={'mrg-left-10 ellipses-for-calender-card-data'}>{shiftDetails?.facility_wing_details?.name}
                                    </div>
                                </Tooltip>
                            </div>
                        </div>


                    </div>
                    <div className={'flexing mrg-top-10'}>
                        <div className={'flex-2'}>
                            <div className={'d-flex ts-align-items-center'}>
                                {
                                    (() => {
                                        const foundShiftType = shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === shiftDetails?.shift_type);
                                        if (foundShiftType) {
                                            return <>
                                                <div
                                                    className={'mrg-right-10'}>
                                                    {foundShiftType.icon}
                                                </div>
                                                <div className={foundShiftType.color}>
                                                    {shiftDetails?.shift_type}
                                                </div>
                                            </>;
                                        } else {
                                            return null; // or some default icon or message if shift type is not found
                                        }
                                    })()
                                }

                            </div>
                        </div>
                        <div className={'flex-1'}>
                            <div className={'d-flex underline-text-view'} onClick={() => {
                                if (shiftDetails?.status === "open" || shiftDetails?.status === "partially_open" || shiftDetails?.status === "filled" || shiftDetails?.status === "unfilled") {
                                    setOpenDialog(true);
                                } else {
                                    CommonService._staticData.openDialogPopup(setOpenDrawer, true);
                                }
                            }}>
                                View Details
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ShiftCardComponent;
