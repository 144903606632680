import {Outlet} from "react-router-dom";

interface FacilityComponentProps {

}

const FacilityComponent = (props: FacilityComponentProps) => {

    return (
        <Outlet/>
    );

};

export default FacilityComponent;