import "./ModuleCardDetailsComponent.scss";

interface ModuleCardDetailsComponentProps {
    title?: string;
    headerIcon?: any;
    addressIcon?: any;
    phoneIcon?: any;
    emailIcon?: any;
    moduleName?: string;
    moduleDetails: any;
}

const ModuleCardDetailsComponent = (props: ModuleCardDetailsComponentProps) => {
    return (
        <>
            <div className={"module-card-details-component"}>
                <div className={"d-flex align-items-center mrg-bottom-20"}>
                    <div>
                        {props.headerIcon}
                    </div>
                    <div className={'mrg-left-10 title-text-configuration'}>
                        {props.title}
                    </div>
                </div>
                <div className={"d-flex justify-content-space-between"}>
                    <div className={'d-flex module-card-details'}>
                        <div>
                            {props?.addressIcon}
                        </div>
                        <div className={'mrg-left-10'}>
                            <div className={'module-card-text-header'}>{`${props?.moduleName} Address`}</div>
                            <div className={'mrg-top-10 module-card-text-details'}>
                                {`${props?.moduleDetails?.address?.street}, ${props?.moduleDetails?.address?.city}, ${props?.moduleDetails?.address?.state}, ${props?.moduleDetails?.address?.zip_code}, ${props?.moduleDetails?.address?.region}, ${props?.moduleDetails?.address?.country}` || '-'}
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex module-card-details'}>
                        <div>
                            {props?.phoneIcon}
                        </div>
                        <div className={'mrg-left-10'}>
                            <div className={'module-card-text-header'}>Phone Number</div>
                            <div className={'mrg-top-10 module-card-text-details'}>
                                {props?.moduleDetails?.contact_number}
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex module-card-details'}>
                        <div>
                            {props?.emailIcon}
                        </div>
                        <div className={'mrg-left-10'}>
                            <div className={'module-card-text-header'}>Email Address</div>
                            <div className={'mrg-top-10 module-card-text-details'}>{props?.moduleDetails?.email}</div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ModuleCardDetailsComponent;