import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {useCallback, useEffect, useState} from "react";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import * as Yup from "yup";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {IAccountForgetPassword, ILoginResponse} from "../../../shared/models/account.model";
import {ImageConfig, Misc} from "../../../constants";
import "./ForgotPassword.scss";
import FormikOtpComponent from "../../../shared/components/form-controls/formik-otp-control/FormikOtpComponent";
import _ from "lodash";
import FormikPasswordInputComponent
    from "../../../shared/components/form-controls/formik-password-input/FormikPasswordInputComponent";
import {useNavigate} from "react-router-dom";
import {LOGIN_ROUTE} from "../../../constants/RoutesConfig";

const ForgotPasswordFormValidationSchema = Yup.object({
    email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
});

const otpFormValidationSchema = Yup.object({
    otp: Yup.string().required('OTP is required').min(6, ''),
});

const ResetPasswordFormValidationSchema = Yup.object({
    newPassword: Yup.string().required('Password is required').min(6, 'Password must be 6 characters').max(16, 'Password must be max 16 characters'),
    confirmPassword: Yup.string().required('Confirm Password is required').min(6, 'Confirm Password must be 6 characters').max(16, 'Confirm Password must be max 16 characters').oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const ResetPasswordFormInitialValues = {
    newPassword: "",
    confirmPassword: "",
};

const OTPFormInitialValues = {
    otp: "",
    email: ""
};

const OTP_VALIDITY = 60;

const ForgotPasswordComponent = () => {
    const [ForgotPasswordFormInitialValues] = useState<IAccountForgetPassword>({
        email: "",
    });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [currentStep, setCurrentStep] = useState<"forgot-password-email" | "forgot-password-otp" | "forgot-password-reset-password">("forgot-password-email");
    const [otpFormInitialValues, setOTPFormInitialValues] = useState<any>(_.cloneDeep(OTPFormInitialValues));
    const [otpTimer, setOtpTimer] = useState<number>(0);
    const [isOTPBeingRequested, setIsOTPBeingRequested] = useState<boolean>(false);
    const [userEnteredEmail, setUserEnteredEmail] = useState<string>("");
    //  const [userEnteredOTP, setUserEnteredOTP] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
            if (otpTimer === 0) {
                setIsOTPBeingRequested(false);
                setOTPFormInitialValues(_.cloneDeep(OTPFormInitialValues));
            }
        }
        , [otpTimer]);


    const runOTPCountdown = useCallback(() => {
        let timer = OTP_VALIDITY;
        setOtpTimer(timer);
        const interval = setInterval(() => {
            timer--;
            setOtpTimer(timer);
            if (timer === 0) {
                clearInterval(interval);
            }
        }, 1000);
    }, []);

    const handleSendOtpPassword = useCallback((values: any, {setErrors}: FormikHelpers<any>) => {
        setIsLoggingIn(true);
        CommonService._account.sendOtpApiCall(values)
            .then((response: IAPIResponseType<ILoginResponse>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setCurrentStep("forgot-password-otp");
                runOTPCountdown();
                setUserEnteredEmail(values?.email);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setIsLoggingIn(false);
        });
    }, [runOTPCountdown]);

    const onOtpSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload: any = {
            ...values,
            email: userEnteredEmail
        };
        CommonService._account.verifyOTPAPICall(payload)
            .then((response: IAPIResponseType<ILoginResponse>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setCurrentStep('forgot-password-reset-password');
                setSubmitting(false);
                // setUserEnteredOTP(values?.code);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });

    }, [userEnteredEmail]);

    const onResetPasswordSubmit = useCallback((values: any, {setSubmitting, setErrors}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload: any = {
            ...values,
            email: userEnteredEmail,
            // code: userEnteredOTP,
        };
        CommonService._account.forgotPasswordAPICall(payload)
            .then((response: IAPIResponseType<ILoginResponse>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                navigate(LOGIN_ROUTE);
                setSubmitting(false);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [navigate, userEnteredEmail]);

    const handleResendOtp = useCallback(() => {
        const payload: any = {};
        payload.email = userEnteredEmail;
        CommonService._account.sendOtpApiCall(payload)
            .then((response: IAPIResponseType<ILoginResponse>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setCurrentStep("forgot-password-otp");
                runOTPCountdown();
            })
            .catch((error: any) => {
                CommonService._alert.showToast(error.message);
            }).finally(() => {
            setIsLoggingIn(false);
        });

    }, [runOTPCountdown, userEnteredEmail]);

    return (
        <>
            <div className="forgot-password-screen">
                <div className="forget-password-form-helper-text">
                    {currentStep !== 'forgot-password-reset-password' ? 'Forgot Password?' : "Reset Password"}
                </div>
                {
                    currentStep === "forgot-password-email" &&
                    <>
                        <div className={'forget-password-form-info-text'}>
                            Don’t worry, enter the email address associated with your account and we will email you a
                            OTP to
                            reset your password.
                        </div>

                        <Formik
                            validationSchema={ForgotPasswordFormValidationSchema}
                            initialValues={ForgotPasswordFormInitialValues}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={handleSendOtpPassword}
                        >
                            {(formik) => {
                                const {values, validateForm, isValid} = formik;
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                useEffect(() => {
                                    validateForm();
                                }, [validateForm, values]);
                                return (
                                    <Form className="t-form" noValidate={true}>
                                        <div className="t-form-controls">
                                            <Field name={'email'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikInputComponent
                                                            label={'Email ID'}
                                                            placeholder={'Enter Email ID'}
                                                            type={"email"}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                            id={"email_input"}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="t-form-actions">
                                            <ButtonComponent
                                                disabled={isLoggingIn || !isValid}
                                                style={{backgroundColor: "#0DB9D8"}}
                                                className={"mrg-top-100"}
                                                isLoading={isLoggingIn}
                                                type={"submit"}
                                                fullWidth={true}
                                                id={"login_btn"}
                                                prefixIcon={<ImageConfig.ArrowCircleRightIcon/>}
                                            >
                                                {isLoggingIn ? "Proceeding" : "Proceed"}
                                            </ButtonComponent>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                }
                {
                    currentStep === 'forgot-password-otp' && <Formik
                        validationSchema={otpFormValidationSchema}
                        initialValues={otpFormInitialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onOtpSubmit}
                    >
                        {(formik) => {
                            const {values, validateForm, isValid, isSubmitting} = formik;
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form otp-form" noValidate={true}>
                                    <div className="t-form-controls">
                                        <div className="otp-form-body">
                                            <div className={'forget-password-form-info-text center-element'}>
                                                Enter the OTP you have received
                                            </div>
                                            <div
                                                className={'forget-password-form-info-text center-element mrg-bottom-30'}>on
                                                your Email ID
                                            </div>
                                            <div className="otp-form-body-controls t-form-controls">
                                                <Field name={'otp'} className="t-form-control">
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikOtpComponent
                                                                // placeholder={'000000'}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div className="controls-resend-otp">
                                                    {
                                                        otpTimer > 0 ?
                                                            <div
                                                                className={'text-decoration-none'}>
                                                                {CommonService.getMinsAndSecondsFromSeconds(otpTimer).minutes + " : " + CommonService.getMinsAndSecondsFromSeconds(otpTimer).seconds}
                                                            </div>
                                                            :
                                                            <div className={'control-text'}
                                                                 onClick={handleResendOtp}
                                                                //   disabled={isOTPBeingRequested}
                                                            >
                                                                {isOTPBeingRequested ? "Sending OTP" : "Resend OTP"}
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="otp-form-body-controls-button">
                                                <ButtonComponent
                                                    className={'mrg-top-30'}
                                                    id={'submit_btn'}
                                                    type={"submit"}
                                                    fullWidth={true}
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isLoggingIn}
                                                >
                                                    Submit
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                }
                {
                    currentStep === 'forgot-password-reset-password' && <Formik
                        validationSchema={ResetPasswordFormValidationSchema}
                        initialValues={ResetPasswordFormInitialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onResetPasswordSubmit}
                    >
                        {(formik) => {
                            const {values, validateForm, isValid, isSubmitting} = formik;
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form otp-form" noValidate={true}>
                                    <div className="t-form-controls">
                                        <div className="otp-form-body">
                                            <div className={'forget-password-form-info-text center-element'}>
                                                Enter your new password
                                            </div>
                                            <div
                                                className={'forget-password-form-info-text center-element mrg-bottom-30'}>
                                                below.
                                            </div>
                                            <div className="otp-form-body-controls t-form-controls">
                                                <Field name={'newPassword'} className="t-form-control">
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikPasswordInputComponent
                                                                canToggle={true}
                                                                label={'New Password'}
                                                                placeholder={'Enter New Password'}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <Field name={'confirmPassword'} className="t-form-control">
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikPasswordInputComponent
                                                                canToggle={true}
                                                                label={'Confirm New Password'}
                                                                placeholder={'Confirm New Password'}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                            <div className="otp-form-body-controls-button">
                                                <ButtonComponent
                                                    className={'mrg-top-30'}
                                                    id={'submit_btn'}
                                                    type={"submit"}
                                                    fullWidth={true}
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isSubmitting}
                                                >
                                                    Submit
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                }

            </div>
        </>
    );
};
export default ForgotPasswordComponent;
