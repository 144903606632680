import "./DashboardScreen.scss";
import React from "react";

interface DashboardScreenProps {

}

const DashboardScreen = (props: DashboardScreenProps) => {

    return (
        <div className={'DashboardScreen'}>
            DashboardScreen
        </div>
    );

};

export default DashboardScreen;
