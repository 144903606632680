const POSITIVE_INTEGERS_PARTIAL = /^(\d+)?(\.)?(\d+)?$/;
const POSITIVE_INTEGERS = /^\d+(\.\d+)?$/;
const POSITIVE_WHOLE_NUMBERS = /^\d+?$/;
const NEGATIVE_WHOLE_NUMBERS = /^-?(\d+)?$/;
const NATURAL_INTEGER_DECIMALS = /^(?!0(\.0+)?$)\d+(\.\d+)?$/;


const Patterns = {
    POSITIVE_INTEGERS,
    POSITIVE_INTEGERS_PARTIAL,
    POSITIVE_WHOLE_NUMBERS,
    NEGATIVE_WHOLE_NUMBERS,
    NATURAL_INTEGER_DECIMALS
}

export default Patterns;



