import './ShiftCreationPopupComponent.scss'
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {ImageConfig} from "../../../constants";
import React, {useCallback,useRef,useEffect} from "react";
import {
    shiftTypeCreationOptions
} from '../../../data';


interface ShiftCreationPopupComponentProps{
    handleShiftCreationPopup:(value:string)=>void
    isPopupOpen:boolean;
    setIsPopupOpen:(value:boolean)=>void;
}

const ShiftCreationPopupComponent=(props:ShiftCreationPopupComponentProps)=>{
    const {handleShiftCreationPopup,isPopupOpen,setIsPopupOpen}=props
    const popupRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const handleClickOutside = (event:any) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [setIsPopupOpen]);

    const handleShiftOpenPopup=useCallback(()=>{
        setIsPopupOpen(true)
    },[setIsPopupOpen])


    return (
        <div className="shift-filter-popup">
            <ButtonComponent
                color={'primary'}
                prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                onClick={handleShiftOpenPopup}
            >
                Create Shift
            </ButtonComponent>
            {
                isPopupOpen &&
                <div className="shift-filter-popup-content" ref={popupRef}>
                    <div className='shift-creation-popup-wrapper'>
                        {shiftTypeCreationOptions?.map((type:any)=> (
                            <div key={type.value} className={'shift-creation-button'}
                                 onClick={() => handleShiftCreationPopup(type.value)}
                            >{type.label}</div>
                        ))
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ShiftCreationPopupComponent