import "./ShiftRequirementComponent.scss";
import {Outlet} from "react-router-dom";
import React from "react";

export interface ShiftRequirementComponentProps {
}

const ShiftRequirementComponent: React.FC<ShiftRequirementComponentProps> = () => {
    return (
        <Outlet/>
    );
};

export default ShiftRequirementComponent;