import {ImageConfig, Misc} from "../../../constants";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import * as Yup from "yup";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import {shift_type, showDropDownBelowField} from "../../../data";
import {MenuItem,TextField} from "@mui/material";
import FormikTimePickerComponent
    from "../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent";
import {v4 as uuidv4} from "uuid";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";

interface AddShiftTimingsProps {
    closeEditDrawer?: any;
    getFacilityDetails?: any;
}

const AddShiftTimingValidationSchema = Yup.object().shape({
    // name: Yup.string().required('ShiftTiming name is required'),
});


interface AddShiftTimingFormInitialValuesFormSchema {
    shift_timings: {
        shift_type: string;
        shift_start_time: string;
        shift_end_time: string;
    };

}

const AddShiftTimingFormInitialValues: AddShiftTimingFormInitialValuesFormSchema = {
    shift_timings: {
        shift_type: '',
        shift_start_time: '',
        shift_end_time: '',
    },
};

const AddShiftTimings = (props: AddShiftTimingsProps) => {
    const {closeEditDrawer, getFacilityDetails} = props;
    const [initialValues] = useState<AddShiftTimingFormInitialValuesFormSchema>(AddShiftTimingFormInitialValues);
    const [shiftTimeList, setShiftTimeList] = useState<any>([]);

    const ShiftTimeListColumn: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Shift Type',
                fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'wing-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'wing-name-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.shift_type}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.shift_type || 'N/A'}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="wing-name">
                                        {item?.shift_type || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },


            {
                title: 'Shift Start Time',
                align: 'center',
                width: 160,
                render: (item: any) => {

                    return <>
                        <div>
                            {CommonService?.convertMinutesToTime(item?.shift_start_time) || 'N/A'}
                        </div>
                    </>;
                }
            },
            {
                title: "Shift End Time",
                align: 'center',
                width: 160,
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService?.convertMinutesToTime(item?.shift_end_time) || 'N/A'}
                        </div>
                    </>;
                }
            },
            {
                title: "",
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        const updatedShiftTimeList = shiftTimeList.filter((setId: any) => setId.id !== item?.id);
                        setShiftTimeList(updatedShiftTimeList);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            }
        ];
    }, [shiftTimeList]);


    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
            shift_timings: shiftTimeList
        };
        let apiCall: any = {};
        apiCall = CommonService._facility.AddShiftTimingsAPICall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getFacilityDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getFacilityDetails, shiftTimeList]);

    return (
        <>
            <div className={'wing-add-facility-component'} style={{minWidth: "200px"}}>
                <div className={'edit-wing-header'}>
                    <div className={'form-header-text'}>Add Shift Timing</div>
                    <div onClick={closeEditDrawer}><ImageConfig.CrossIcon height={26} width={26}/>
                    </div>
                </div>
                <div className={'mrg-bottom-10'}>
                    <HorizontalLineComponent/>
                </div>
                <div className={'edit-wing-form-wrapper'}>
                    <Formik
                        validationSchema={AddShiftTimingValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, setErrors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder pdd-10" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container mrg-bottom-10'}>
                                            <div className="edit-component-content">
                                                <div className={'d-flex mrg-left-25 mrg-right-25'}>
                                                    <div className={"mrg-right-10 flex-1"}>
                                                        <LabelComponent title={'Shift Type'}/>
                                                        <Field
                                                            SelectProps={showDropDownBelowField}
                                                            id="input_shift_requirement_shift_types"
                                                            variant="outlined"
                                                            select
                                                            name="shift_timings.shift_type"
                                                            component={TextField}
                                                            value={values?.shift_timings.shift_type}
                                                            fullWidth
                                                            onChange={(e: any) => {
                                                                const selectedShiftTiming = e.target.value;
                                                                console.log(selectedShiftTiming);
                                                                setFieldValue("shift_timings.shift_type", selectedShiftTiming);
                                                            }}
                                                        >
                                                            <MenuItem value="">Select Shift Type</MenuItem>
                                                            {shift_type?.length > 0 &&
                                                                shift_type?.map((item: any, index: any) => {
                                                                    return (
                                                                        <MenuItem value={item.code} key={item.title}>
                                                                            {item.title}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </Field>


                                                    </div>
                                                    <div className={"mrg-right-10 flex-1"}>
                                                        <div>
                                                            <LabelComponent title={"Shift Start Time"} tooltip={""}/>
                                                            <Field name={'shift_timings.shift_start_time'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikTimePickerComponent
                                                                            placeholder={''}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={"mrg-right-10 flex-1"}>
                                                        <div>
                                                            <LabelComponent title={"Shift End Time"} tooltip={""}/>
                                                            <Field name={'shift_timings.shift_end_time'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikTimePickerComponent
                                                                            placeholder={''}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />


                                                                    )
                                                                }
                                                            </Field>
                                                            {/*<div className={'clock-icon'}>*/}
                                                            {/*    <ImageConfig.CalendarTimePicker/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={'mrg-top-37 mrg-left-5'}>
                                                        <ButtonComponent
                                                            variant={'outlined'}
                                                            disabled={values.shift_timings.shift_type === '' || values.shift_timings.shift_start_time === '' || values.shift_timings.shift_end_time === ''}
                                                            onClick={() => {
                                                                const uniqueId: string = uuidv4();
                                                                const newShiftTime = {
                                                                    shift_type: values.shift_timings?.shift_type,
                                                                    shift_start_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_start_time),
                                                                    shift_end_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_end_time),
                                                                    id: uniqueId,
                                                                };
                                                                setShiftTimeList([...shiftTimeList, newShiftTime]);
                                                                setFieldValue('shift_timings.shift_type', '');
                                                                setFieldValue('shift_timings.shift_start_time', '');
                                                                setFieldValue('shift_timings.shift_end_time', '');
                                                            }}
                                                            style={{
                                                                width: 0,
                                                                height: 0,
                                                                border: 0,
                                                                borderRadius: '100%',
                                                                backgroundColor: "#fff"
                                                            }}
                                                        >
                                                            <ImageConfig.SaveIcon width={35} height={35}/>
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                                {shiftTimeList && shiftTimeList?.length > 0 &&
                                                    <div
                                                        className={"mrg-left-10 mrg-right-10 mrg-top-10 wing-timing-table-wrapper"}>
                                                        {/*<TableComponent columns={ShiftTimeListColumn}*/}
                                                        {/*                data={shiftTimeList}/>*/}
                                                        <AntTableComponent
                                                            columns={ShiftTimeListColumn}
                                                            data={shiftTimeList}
                                                            extraPayload={shiftTimeList}
                                                            isRowSelection={false}
                                                            noDataText={"No Shift Timing Data"}
                                                        />
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-wing-form-action-button">
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddShiftTimings;