import "./AddAgencyComponent.scss";
import AddAgencyScreenComponent from "./add-agency-screen/AddAgencyScreenComponent";

interface AddPropertyComponentProps {

}

const AddAgencyComponent = (props: AddPropertyComponentProps) => {

    return (
        <div className={'add-agency-component'}>
            <>
                <AddAgencyScreenComponent/>
            </>
        </div>);
};

export default AddAgencyComponent;
