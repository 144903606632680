import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./HCPDetailsComponent.scss";
import Lottie from "react-lottie";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import {ITableColumn} from "../../../../../shared/models/table.model";
import TextWithIconComponent from "../../../../../shared/components/text-with-icon/TextWithIconComponent";
import {APIConfig, ImageConfig} from "../../../../../constants";
import ToolTipComponent from "../../../../../shared/components/tool-tip/ToolTipComponent";
import {shiftTypeLayoutRepresentation} from "../../../../../data";
import HorizontalLineComponent
    from "../../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import LoaderComponent from "../../../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../../../shared/components/status-card/StatusCardComponent";
import FavoriteJson from "../../../../../assets/animations/Favorite.json";
import CommonHcpDetailsCard from "./hcp-details-common-card/CommonHcpDetailsCard";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../../../shared/components/tabs/TabsComponent";
import CardComponent from "../../../../../shared/components/card/CardComponent";
import TableWrapperComponent from "../../../../../shared/components/table-wrapper/TableWrapperComponent";
import LinkComponent from "../../../../../shared/components/link/LinkComponent";
interface HCPDetailsComponentProps {
    setOpenDrawer: any;
    shiftHcpDetails: any;
    shiftRequirementDetails?: any;
}
const HCPDetailsComponent = (props: HCPDetailsComponentProps) => {
    const {setOpenDrawer, shiftHcpDetails, shiftRequirementDetails} = props;
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    const [isHcpDetailsLoadingFailed, setIsHcpDetailsLoadingFailed] = useState<boolean>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<any>("document");
    const [hcpFacilityShiftFilterData, setHcpFacilityShiftFilterData] = useState({});
    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (shiftHcpDetails?.hcp_details?._id) {
            CommonService._hcp.HcpDetailsAPICall(shiftHcpDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [shiftHcpDetails?.hcp_details?._id]);

    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (shiftHcpDetails?.hcp_details?._id) {
            CommonService._hcp.HcpDocumentAPICall(shiftHcpDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [shiftHcpDetails?.hcp_details?._id]);


    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    useEffect(() => {
        getHcpDocuments();
    }, [getHcpDocuments]);

    useEffect(() => {
        if (isHcpDetailsLoaded) {
            setHcpFacilityShiftFilterData({
                hcp_id: hcpDetails?._id,
                facility_id: shiftRequirementDetails?.facility_id
            });
        }
    }, [hcpDetails, shiftRequirementDetails, isHcpDetailsLoaded]);

    const shiftTimingsColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [

            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftDate/>}>Shift
                    Date</TextWithIconComponent>,
                key: 'shift_date',
                dataIndex: 'shift_date',
                //fixed: 'left',
                // sortable: true,
                width: 150,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return <>{CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftTime/>}>Shift
                    Time</TextWithIconComponent>,
                key: 'shift-time',
                dataIndex: 'shift-time',
                width: 150,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_timings) {
                        return <>{CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.start_time) + "-" + CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.end_time)}</>;
                    } else {
                        return <>{CommonService.convertMinutesToTime(item?.expected?.shift_start_time) + "-" + CommonService.convertMinutesToTime(item?.expected?.shift_end_time)}</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.Wing/>}>Wing</TextWithIconComponent>,
                key: 'wing',
                dataIndex: 'wing',
                width: 100,
                align: 'left',
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.facility_wing_details?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.facility_wing_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.facility_wing_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.facility_wing_details?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftType/>}>Shift
                    Type</TextWithIconComponent>,
                key: 'shift_type',
                dataIndex: 'shift_type',
                width: 200,
                render: (item: any) => {
                    return <div className={'d-flex justify-content-center align-items-center'}>
                        {
                            (() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === item?.shift_type);
                                if (foundShiftType) {
                                    return <>
                                        <div
                                            className={'mrg-right-5 mrg-top-5'}>
                                            {foundShiftType.icon}
                                        </div>
                                        <div className={foundShiftType.color}>
                                            {item?.shift_type}
                                        </div>
                                    </>;
                                } else {
                                    return null;
                                }
                            })()
                        }
                    </div>;
                }
            },

        ];
    }, []);

    return (
        <>
            <div className={"open-shift-hcp-view-details"}>
                <div className={"approved-header d-flex justify-content-space-between pdd-20"} style={{
                    backgroundImage: `url(${ImageConfig.ApprovedGradient}`,
                    backgroundRepeat: "no-repeat"
                }}>
                    <div className={'d-flex ts-align-items-center'}>
                        <div className={'form-header-text'}>Staff Details
                        </div>
                    </div>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}><ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>
                <div className={"pdd-right-25 pdd-left-25 pdd-bottom-15 pdd-top-15"}>
                    <div className={'hcp-details-wrapper'}>
                        {
                            isHcpDetailsLoading && <div style={{minWidth: "750px"}}>
                                <div style={{marginLeft:"45%"}}>
                                    <LoaderComponent type={'spinner'}/>
                                </div>
                            </div>
                        }
                        {
                            isHcpDetailsLoadingFailed &&
                            <div className="h-v-center">
                                <StatusCardComponent title={'Failed to Load'}/>
                            </div>
                        }
                        {
                            !isHcpDetailsLoading && <>
                                <div>
                                    <div className={'d-flex align-items-center height-hcp-details-wrapper'}>
                                        <div className={'hcp-details-header-text-drawer'}>Staff Details</div>
                                        <div>
                                            {hcpDetails?.is_favourite &&
                                                <Lottie width={50} height={50} speed={1}
                                                        options={{animationData: FavoriteJson}}/>}
                                        </div>
                                    </div>
                                    <CommonHcpDetailsCard hcpDetails={hcpDetails}
                                                          innerShiftDetails={shiftRequirementDetails}
                                                          getHcpDetails={getHcpDetails}
                                    />
                                </div>
                                <div className={'drawer-tab-wrapper mrg-top-20'}>
                                    <TabsWrapperComponent>
                                        <TabsComponent
                                            value={activeTab}
                                            allowScrollButtonsMobile={false}
                                            onUpdate={(e: any, v: any) => {
                                                setActiveTab(v);
                                            }}
                                        >
                                            <TabComponent label="Documents" value={"document"}/>
                                            <TabComponent label="Shifts in this facility" value={"facility-shift"}/>
                                        </TabsComponent>
                                        <TabContentComponent value={activeTab} selectedTab={"document"}>
                                            {(hcpDocumentsList?.length > 0 && isHcpDetailsLoaded) &&
                                                <CardComponent size={"sm"}
                                                               className={"mrg-top-10 shift-details-card-hcp"}>
                                                    <div className={'document-box'}>
                                                        <div
                                                            className={'document-text mrg-left-20 mrg-top-20 mrg-bottom-5'}>Document
                                                        </div>
                                                        {
                                                            hcpDocumentsList?.map((document: any, index: any) => {
                                                                let transformedName = "";
                                                                if (document?.attachment_type === "tb_test") {
                                                                    transformedName = "TB Test";
                                                                } else if (document?.attachment_type === "cpr_bls_card") {
                                                                    transformedName = "CPR BLS Card";
                                                                } else {
                                                                    transformedName = CommonService.transformName(document?.attachment_type);
                                                                }
                                                                return (
                                                                    <>
                                                                        <div
                                                                            className={'hcp-document-list-wrapper cursor-pointer'}
                                                                            onClick={() => {
                                                                                window.open(document?.url, '_blank');
                                                                            }}>
                                                                            <div className={'icon-button'}>
                                                                                <ImageConfig.FileUploadHcp/>
                                                                            </div>
                                                                            <div
                                                                                className={'underline-text mrg-left-10'}>
                                                                                {transformedName}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </CardComponent>}
                                            {(hcpDocumentsList?.length === 0 && isHcpDetailsLoaded) &&
                                                <CardComponent size={"sm"} className={"mrg-top-10"}>
                                                    <div className={'details-screen'}>
                                                        <div
                                                            className="details-screen-title-with-image-wrapper pdd-10">
                                                            <div
                                                                className={'details-screen-title-wrapper center-right-element'}>
                                                                <div className={'details-screen-title'}>Documents
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <HorizontalLineComponent/>
                                                        <div className={'no-content differential-hcp-rate'}>
                                                            No Document Uploaded yet..
                                                        </div>
                                                    </div>
                                                </CardComponent>}
                                        </TabContentComponent>
                                        <TabContentComponent value={activeTab} selectedTab={"facility-shift"}>
                                            <div className={'table-wrapper-box'}>
                                                <TableWrapperComponent url={APIConfig.FACILITY_HCP_SHIFTS.URL}
                                                                       method={APIConfig.FACILITY_HCP_SHIFTS.METHOD}
                                                                       isPaginated={true}
                                                                       extraPayload={hcpFacilityShiftFilterData}
                                                                       columns={shiftTimingsColumn}
                                                />
                                            </div>
                                        </TabContentComponent>

                                    </TabsWrapperComponent>
                                </div>
                            </>}
                        {(hcpDocumentsList?.length > 0 && isHcpDetailsLoaded) &&
                            <CardComponent size={"sm"} className={"mrg-top-10"}>
                                <div className={'details-screen'}>
                                    <div className="details-screen-title-with-image-wrapper pdd-10">
                                        <div className={'details-screen-title-wrapper center-right-element'}>
                                            <div className={'details-screen-title'}>Documents
                                            </div>
                                        </div>
                                    </div>
                                    <HorizontalLineComponent/>
                                    {
                                        hcpDocumentsList?.map((document: any, index: any) => {
                                            let transformedName = "";
                                            if (document?.attachment_type === "tb_test") {
                                                transformedName = "TB Test";
                                            } else if (document?.attachment_type === "cpr_bls_card") {
                                                transformedName = "CPR BLS Card";
                                            } else {
                                                transformedName = CommonService.transformName(document?.attachment_type);
                                            }
                                            return (
                                                <>
                                                    <div className={'document-list-wrapper'}>
                                                        <div>
                                                            {transformedName}
                                                        </div>
                                                        <div>
                                                            <LinkComponent onClick={() => {
                                                                window.open(document?.url, '_blank');
                                                            }}>
                                                                <ImageConfig.ArrowRightOutlinedIcon/>
                                                            </LinkComponent>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </CardComponent>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HCPDetailsComponent;
