import "./AddAdditionalTypeComponent.scss";
import {Misc} from "../../../../constants";
import {CommonService} from "../../../../shared/services";
import React, {useCallback, useState} from "react";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {getAdditionalRates} from "../../../../store/actions/meta.action";
import {useDispatch} from "react-redux";
import AddNewTypeComponent from "../../../../shared/components/new-type-creation/AddNewTypeComponent";


interface AdditionalTypesProps {
    setOpenDialog: any;
    setRateInnerNavigation: any;
    getSelectedAgencyDetails: any;
}
const AddAdditionalTypeComponent = (props: AdditionalTypesProps) => {
    const dispatch = useDispatch();
    const {setOpenDialog, getSelectedAgencyDetails, setRateInnerNavigation} = props;
    const [additionalTypeName,setAdditionalTypeName]=useState<any>(null);
    const [isAdditionalTypeLoading,setIsAdditionalTypeLoading]=useState<any>(false)

    const handleAdditionalTypeSubmit = useCallback(() => {
        setIsAdditionalTypeLoading(true)
        const payload: any = {};
        payload.name = additionalTypeName;
        CommonService._meta.addNewAdditionalRateAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setIsAdditionalTypeLoading(false)
                CommonService._staticData?.openDialogPopup(setOpenDialog, false);
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                dispatch(getAdditionalRates());
                getSelectedAgencyDetails();
                setRateInnerNavigation("additioinal-rate-form");
                // @ts-ignore
            }).catch((error: any) => {
            CommonService._staticData?.openDialogPopup(setOpenDialog, false);
            CommonService._alert.showToast(error[Misc.API_ERROR_MESSAGE_KEY], "error");
            setRateInnerNavigation("additioinal-rate-form");
        }).finally(() => {
            CommonService._staticData?.openDialogPopup(setOpenDialog, false);
            setIsAdditionalTypeLoading(false)
            setRateInnerNavigation("additioinal-rate-form");
        });
    }, [dispatch, getSelectedAgencyDetails, setOpenDialog,setRateInnerNavigation,additionalTypeName]);

    return (
        <>
            <AddNewTypeComponent addType={"Additional type"} onSubmit={handleAdditionalTypeSubmit} setOpenDialog={setOpenDialog} setTypeName={setAdditionalTypeName} typeName={additionalTypeName} isLoading={isAdditionalTypeLoading}/>
        </>
    );
};
export default AddAdditionalTypeComponent;