import "./DetailsHeaderComponent.scss";
import ButtonComponent from "../button/ButtonComponent";
import {ImageConfig} from "../../../constants";
import React, {useEffect, useState} from "react";

interface DetailsHeaderComponentProps {
    navigate?: any;
    details?: any;
    backAddress?: any;
    detailsModule?: any;
}

const DetailsHeaderComponent = (props: DetailsHeaderComponentProps) => {
    const {navigate, details, backAddress, detailsModule} = props;
    const [backDetailsModule, setBackDetailsModule] = useState<any>("Back");
    const [moduleHeaderName, setModuleHeaderName] = useState("");

    useEffect(() => {
        if (detailsModule === "Facility") {
            setBackDetailsModule("Back to Facility");
            setModuleHeaderName(details?.name);
        } else if (detailsModule === 'Internal-hcp') {
            setBackDetailsModule("Back to Internal Staff");
            setModuleHeaderName(details?.first_name + " " + details?.last_name);
        } else if (detailsModule === 'Agency') {
            setBackDetailsModule("Back to Agency");
            setModuleHeaderName(details?.name);
        } else if (detailsModule === 'Hcp') {
            setBackDetailsModule("Back to Staff");
            setModuleHeaderName(details?.first_name + " " + details?.last_name || '-');
        } else if (detailsModule === 'Open Shift Management') {
            setBackDetailsModule("Back to Shift");
            setModuleHeaderName(`${details?.requirementType ? details?.requirementType + " requirement" : "Open shift requirement "}`);
        } else {
            setBackDetailsModule("Back");
        }
    }, [details?.first_name, details?.last_name, details?.name, details?.title, detailsModule, details]);
    return (
        <>
            <div className={'details-header-component d-flex justify-content-space-between'}>
                <div className={'flex-1 details-header-back-button-wrapper'}>
                    <ButtonComponent
                        className={"mrg-right-10 button-height button-font-text back"}
                        variant={'outlined'}
                        id={"exit_btn"}
                        style={{
                            backgroundColor: "none !important",
                            color: "yellow !important",
                            border: "none"
                        }}
                        onClick={() => {
                            navigate(backAddress);
                        }}
                        prefixIcon={<ImageConfig.BackArrow/>}
                    >
                        {backDetailsModule}
                    </ButtonComponent>
                </div>
                <div className={'flex-2 justify-content-center details-header-name-wrapper '}>{moduleHeaderName}</div>
                <div className={'flex-1'}/>
            </div>
        </>
    );
};

export default DetailsHeaderComponent;