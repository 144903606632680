import "./AddShiftRequirementComponent.scss";
import AddShiftDetailsComponent from "./add-shift-details/AddShiftRequirementDetails";


const AddShiftRequirementComponent = () => {

    return (
        <>
            <div className={'add-hcp-component'}>
                <>
                    <div>
                        <div className={"mrg-top-10 add-form-wrapper-box"}>
                            <AddShiftDetailsComponent
                            />;
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

export default AddShiftRequirementComponent;