import {call, put, takeEvery} from "redux-saga/effects";

import {
    GET_AGENCY_LIST_LITE,
    GET_FACILITY_LIST_LITE,
    GET_REGION_LIST_API,
    GET_USER_ROLE_LIST,
    GET_WINGS_LIST_LITE,
    setAgencyListLite,
    setFacilityListLite,
    setRegionList,
    setUserRoleList,
    setWingsListLite
} from "../actions/static-data.action";

import {CommonService} from "../../shared/services";


function* getFacilityList() {
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.FacilityListLiteAPICall);
        const modifiedData = resp?.data?.map((item: any) => {
            const {_id, name, ...rest} = item;
            return {
                ...rest,
                title: name,
                code: _id,
            };
        });

        yield put(setFacilityListLite(modifiedData));
    } catch (error: any) {
        yield put(setFacilityListLite([]));
    }
}

function* getAgencyList(action: any) {
    console.log(action);
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.AgencyListLiteAPICall, action?.payload);
        const modifiedData = resp?.data?.map((item: any) => {
            const {_id, name, ...rest} = item;
            return {
                ...rest,
                title: name,
                code: _id,
            };
        });

        yield put(setAgencyListLite(modifiedData));
    } catch (error: any) {
        yield put(setAgencyListLite([]));
    }
}

function* getWingsList() {
    let payload: any = {};
    payload.is_active = true;
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.WingsListLiteAPICall, payload);
        yield put(setWingsListLite(resp?.data || []));
    } catch (error: any) {
        yield put(setWingsListLite([]));
    }
}


function* getUserRoleList() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._staticData.getUserRoleList);
        yield put(setUserRoleList(resp?.data));
    } catch (error: any) {
        yield put(setUserRoleList([]));
    }
}

function* getRegionList() {
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.RegionListAPICall);
        yield put(setRegionList(resp?.data || []));
    } catch (error: any) {
        yield put(setRegionList([]));
    }
}


export default function* staticDataSaga() {
    yield takeEvery(GET_USER_ROLE_LIST, getUserRoleList);
    yield takeEvery(GET_FACILITY_LIST_LITE, getFacilityList);
    yield takeEvery(GET_AGENCY_LIST_LITE, getAgencyList);
    yield takeEvery(GET_WINGS_LIST_LITE, getWingsList);
    yield takeEvery(GET_REGION_LIST_API, getRegionList);
}


