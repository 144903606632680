import React, {CSSProperties} from 'react';
import './LabelComponent.scss';
import ToolTipComponent from "../tool-tip/ToolTipComponent";
import {ImageConfig} from "../../../constants";

export interface LabelComponentProps {
    title: string;
    tooltip?: string;
    isOptional?: boolean;
    required?: boolean;
    hasError?: boolean;
    styles?: CSSProperties;
    isBold?: boolean;
}

const LabelComponent = (props: LabelComponentProps) => {

    const {isBold, title, required, tooltip, hasError, styles} = props;
    const isOptional = required === false ? true : props.isOptional;

    return (
        <div className={(hasError ? 'has-error' : '') + (isBold ? "bold-header" : "label-component")}
             style={styles}>
            <span>
                {title}
                {required &&
                    <span>*</span>
                }
                {
                    tooltip &&
                    <ToolTipComponent tooltip={tooltip}>
                        <span className={'tooltip-info'}> <ImageConfig.HelpTooltipIcon/> </span>
                    </ToolTipComponent>
                }
            </span>
            {isOptional && <span className={'label__optional'}>Optional</span>}
        </div>
    );

};

export default LabelComponent;
