import {ApiService} from "../index";
import {APIConfig} from "../../../constants";


//vars-health
const FacilityDetailsAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.FACILITY_DETAILS.METHOD](APIConfig.FACILITY_DETAILS.URL, payload);
};

const AgencyDetailsAPICall = (agencyId: string, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.AGENCY_DETAILS.METHOD](APIConfig.AGENCY_DETAILS.URL(agencyId), payload);
};

const AddFacilityAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_FACILITY.METHOD](APIConfig.ADD_FACILITY.URL, payload);
};

const AddWingAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_WING.METHOD](APIConfig.ADD_WING.URL, payload);
};

//vars-health-end


const TogglePropertyServiceAPICall = (propertyId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_FACILITY.METHOD](APIConfig.TOGGLE_FACILITY.URL(propertyId), payload);
};


const EditFacilityAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_FACILITY.METHOD](APIConfig.EDIT_FACILITY.URL, payload);
};


const GetPropertyFMList = (propertyId: string) => {
    // @ts-ignore
    return ApiService[APIConfig.PROPERTY_FM_LIST.METHOD](APIConfig.PROPERTY_FM_LIST.URL(propertyId));
};

const GetWingsList = () => {
    // @ts-ignore
    return ApiService[APIConfig.WING_LIST.METHOD](APIConfig.WING_LIST.URL);
};

const GetSelectedWingDetails = (wingId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.WING_DETAILS.METHOD](APIConfig.WING_DETAILS.URL(wingId));
};
const ActivateDeactivateFacilityAdmin = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_FACILITY_ADMIN.METHOD](APIConfig.TOGGLE_FACILITY_ADMIN.URL(facilityId), payload);
};
const EditFacilityAdminAPICall = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_FACILITY_ADMIN_DETAILS.METHOD](APIConfig.EDIT_FACILITY_ADMIN_DETAILS.URL(facilityId), payload);
};

const AddFacilityAdminApiCall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.ADD_FACILITY_ADMIN_UNDER_USER_MANAGEMENT.METHOD](APIConfig.ADD_FACILITY_ADMIN_UNDER_USER_MANAGEMENT.URL, payload);
};

const ActivteDeactivateWings = (wingId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.TOGGLE_WING.METHOD](APIConfig.TOGGLE_WING.URL(wingId), payload);
};

const AddShiftTimingsAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_SHIFT_TIMINGS.METHOD](APIConfig.ADD_SHIFT_TIMINGS.URL, payload);
};

const EditWingAPICall = (wingId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_WING.METHOD](APIConfig.EDIT_WING.URL(wingId), payload);
};

const deleteShiftTimings = (shiftTimingId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.DELETE_SHIFT_TIMINGS.METHOD](APIConfig.DELETE_SHIFT_TIMINGS.URL(shiftTimingId), payload);
};
const deleteWings = (wingId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.DELETE_WING.METHOD](APIConfig.DELETE_WING.URL(wingId), payload);
};
const FacilityService = {
    TogglePropertyServiceAPICall,
    EditFacilityAPICall,
    GetPropertyFMList,
    FacilityDetailsAPICall,
    AddFacilityAPICall,
    AgencyDetailsAPICall,
    GetWingsList,
    AddWingAPICall,
    GetSelectedWingDetails,
    ActivateDeactivateFacilityAdmin,
    EditFacilityAdminAPICall,
    AddFacilityAdminApiCall,
    ActivteDeactivateWings,
    EditWingAPICall,
    AddShiftTimingsAPICall,
    deleteShiftTimings,
    deleteWings
};

export default FacilityService;
