import {all, fork} from 'redux-saga/effects';
import staticDataSaga from "./static-data.saga";
import metaDataSaga from "./meta.saga";

export default function* rootSaga() {
    yield all([
        fork(staticDataSaga),
        fork(metaDataSaga)
    ]);
}
