import { useMemo } from 'react';
import { Checkbox, Typography } from '@mui/material';
import { CommonService } from '../../../shared/services';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import { ImageConfig } from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { shiftTypeLayoutRepresentation } from '../../../data';
import {TableColumnsType} from "antd";

export const useOpenShiftsListColumn = (filterData: any): TableColumnsType => {
    return useMemo(() => {
        return [
            // {
            //   title: 'Title',
            //   fixed: 'left',
            //   width: 320,
            //   render: (item: any) => {
            //     return (
            //         <div className={'hcp-details-wrapper'}>
            //           {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
            //           <div className={'hcp-name-tower-wrapper'}>
            //             {(
            //                 CommonService.convertDateFormat2(
            //                     item?.shift_date,
            //                     'MM-DD-YYYY',
            //                 ) +
            //                 ' | ' +
            //                 item?.hcp_type_details?.title +
            //                 ' | ' +
            //                 item?.shift_type
            //             )?.length > 20 ? (
            //                 <ToolTipComponent
            //                     tooltip={
            //                         CommonService.convertDateFormat2(
            //                             item?.shift_date,
            //                             'MM-DD-YYYY',
            //                         ) +
            //                         ' | ' +
            //                         item?.hcp_type_details?.title +
            //                         ' | ' +
            //                         item?.shift_type
            //                     }
            //                     showArrow={true}
            //                     position={'top'}
            //                 >
            //                   <div className={'ellipses-for-table-data property-name'}>
            //                     {CommonService.convertDateFormat2(
            //                             item?.shift_date,
            //                             'MM-DD-YYYY',
            //                         ) +
            //                         ' | ' +
            //                         item?.hcp_type_details?.title +
            //                         ' | ' +
            //                         item?.shift_type}
            //                   </div>
            //                 </ToolTipComponent>
            //             ) : (
            //                 <div className='facility-name'>
            //                   {CommonService.convertDateFormat2(
            //                           item?.shift_date,
            //                           'MM-DD-YYYY',
            //                       ) +
            //                       ' | ' +
            //                       item?.hcp_type_details?.title +
            //                       ' | ' +
            //                       item?.shift_type}
            //                 </div>
            //             )}
            //           </div>
            //         </div>
            //     );
            //   },
            // },
            {
                title: 'Shift Date',
                width: 150,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return (
                            <>
                                {CommonService.convertDateFormat2(
                                    item?.shift_date,
                                    'MM-DD-YYYY',
                                )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title:
                    <>
                        {filterData?.requirementType === "Home Health" ? "Visit Time" : "Shift Time"}
                    </>,
                width: 200,
                render: (item: any) => {
                    if (item?.shift_timings) {
                        return (
                            <>
                                {filterData.requirementType === "Home Health" &&
                                    <div>
                                        {CommonService.convertMinutesToTime(
                                            item?.hospice_details?.visit_patient_time,
                                        )}
                                    </div>
                                }
                                {filterData.requirementType !== "Home Health" &&
                                    <div>
                                        {CommonService.convertMinutesToTime(
                                                item?.shift_timings?.start_time,
                                            ) +
                                            ' - ' +
                                            CommonService.convertMinutesToTime(
                                                item?.shift_timings?.end_time,
                                            )}
                                    </div>
                                }
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: 'Staff Type',
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.hcp_type_details?.title?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.hcp_type_details?.title}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_type_details?.title}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_type_details?.title || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Shift Type',
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'d-flex justify-content-center align-items-center'}>
                            {(() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(
                                    (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                                );
                                if (foundShiftType) {
                                    return (
                                        <>
                                            <div className={'mrg-right-5 mrg-top-5'}>
                                                {foundShiftType.icon}
                                            </div>
                                            <div className={foundShiftType.color}>
                                                {item?.shift_type}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return 'N/A';
                                }
                            })()}
                        </div>
                    );
                },
            },
            // {
            //   title: 'Wing',
            //   align: 'left',
            //   width:250,
            //   render: (item: any) => {
            //     return (
            //         <div className={'hcp-details-wrapper'}>
            //           <div className={'hcp-name-tower-wrapper'}>
            //             {item?.facility_wing_details?.name?.length > 20 ? (
            //                 <ToolTipComponent
            //                     tooltip={item?.facility_wing_details?.name}
            //                     showArrow={true}
            //                     position={'top'}
            //                 >
            //                   <div className={'ellipses-for-table-data property-name'}>
            //                     {item?.facility_wing_details?.name}
            //                   </div>
            //                 </ToolTipComponent>
            //             ) : (
            //                 <div className='hcp-name'>
            //                   {item?.facility_wing_details?.name || 'N/A'}
            //                 </div>
            //             )}
            //           </div>
            //         </div>
            //     );
            //   },
            // },
            {
                title: "Status",
                width: 150,
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return (
                        <div className={'requirement-status-tag'}>
                            <div
                                onClick={() => {
                                    console.log(item?.status);
                                }}
                                className={`requirement-status requirement-${item?.status}-status`}
                            >
                                {item?.status === 'partially_open' && <div>Partially Open</div>}
                                {item?.status !== 'partially_open' &&
                                    CommonService.capitalizeFirstLetter(item?.status)}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Staff Filled',
                align: 'left',
                width: 140,
                render: (item: any) => {
                    let hcpCount = item?.approved_hcp_count;
                    let exceededHcpCount = 0;
                    if (hcpCount > item?.required_hcp_count) {
                        exceededHcpCount = hcpCount - item?.required_hcp_count;
                        hcpCount = item?.required_hcp_count;
                    }
                    return (
                        <>
                            <div
                                style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                            >
                                {/*<Box sx={{width: '80%'}}>*/}
                                {/*    <LinearProgress*/}
                                {/*        sx={{borderRadius: "5px", minHeight: "8px", backgroundColor: "#F2F4F7"}}*/}
                                {/*        variant="determinate" value={progress}/>*/}
                                {/*</Box>*/}
                                <div className={'mrg-left-20'}>
                                    <Typography>
                                        {item?.approved_hcp_count}/
                                        {Number(item?.required_hcp_count) +
                                            Number(exceededHcpCount)}
                                    </Typography>
                                </div>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "View Details",
                align: 'center',
                width: 130,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent
                                route={CommonService._routeConfig.ShiftRequirementDetailsRoute(filterData?.status, item?._id)}>
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ];
    }, [filterData]);
};

export const useOtherShiftsListColumn = (
    activeTab: string,
    handleSelectShifts: any,
    isSelected: any,
    setOpenDrawer: any,
    setShiftDetails: any,
): TableColumnsType => {
    return useMemo(() => {
        return [
            {
                title: '',
                fixed: 'left',
                width: 70,
                render: (item: any) => {
                    const isItemSelected = isSelected(item?._id);
                    return (
                        <>
                            {activeTab !== 'cancelled' ? (
                                <Checkbox
                                    style={{ color: '#202937' }}
                                    id={'cb_' + item?._id}
                                    checked={isItemSelected}
                                    onChange={(event) => handleSelectShifts(event, item?._id)}
                                />
                            ) : null}
                        </>
                    );
                },
            },
            {
                title: 'Title',
                fixed: 'left',
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {(
                                    CommonService.convertDateFormat2(
                                        item?.shift_date,
                                        'MM-DD-YYYY',
                                    ) +
                                    ' | ' +
                                    item?.hcp_type_details?.title +
                                    ' | ' +
                                    item?.shift_type
                                )?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {CommonService.convertDateFormat2(
                                                    item?.shift_date,
                                                    'MM-DD-YYYY',
                                                ) +
                                                ' | ' +
                                                item?.hcp_type_details?.title +
                                                ' | ' +
                                                item?.shift_type}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='facility-name'>
                                        {CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
                // render: (item: any) => {
                //   return (
                //     <div className={'hcp-details-wrapper'}>
                //       <div className={'hcp-name-tower-wrapper'}>
                //         <div className='hcp-name shift-title-text'>
                //           <div>
                //             <LinkComponent
                //               onClick={() => {
                //                 setOpenDrawer(true);
                //                 setShiftDetails(item);
                //               }}
                //             >
                //               <div className={'d-flex'}>
                //                 <div className={'mrg-right-10'}>
                //                   {CommonService.convertDateFormat2(
                //                     item?.shift_date,
                //                     'MM-DD-YYYY',
                //                   ) +
                //                     ' | ' +
                //                     (item?.hcp_type_details?.title || '') +
                //                     ' | ' +
                //                     item?.shift_type}
                //                 </div>
                //                 <ImageConfig.TitleViewIcon />
                //               </div>
                //             </LinkComponent>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   );
                // },
            },
            {
                title: 'Agency Name',
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.agency_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.agency_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.agency_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>{item?.agency_details?.name || 'N/A'}</div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Staff Name',
                width: 250,
                align: 'left',
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            <div className={'hcp-name-tower-wrapper'}>
                                {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)
                                    ?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_details?.first_name +
                                                ' ' +
                                                item?.hcp_details?.last_name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Shift Date',
                width: 120,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return (
                            <>
                                {CommonService.convertDateFormat2(
                                    item?.shift_date,
                                    'MM-DD-YYYY',
                                )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: 'Shift Time',
                width: 200,
                render: (item: any) => {
                    if (item?.expected) {
                        return (
                            <>
                                {CommonService.convertMinutesToTime(
                                        item?.expected?.shift_start_time,
                                    ) +
                                    '-' +
                                    CommonService.convertMinutesToTime(
                                        item?.expected?.shift_end_time,
                                    )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: 'Staff Type',
                width: 180,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            <div className={'hcp-name-tower-wrapper'}>
                                {(item?.hcp_type_details?.title)
                                    ?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.hcp_type_details?.title}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_type_details?.title}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_type_details?.title || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Shift Type',
                width:250,
                render: (item: any) => {
                    return (
                        <div className={'d-flex justify-content-center align-items-center'}>
                            {(() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(
                                    (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                                );
                                if (foundShiftType) {
                                    return (
                                        <>
                                            <div className={'mrg-right-5 mrg-top-5'}>
                                                {foundShiftType.icon}
                                            </div>
                                            <div className={foundShiftType.color}>
                                                {item?.shift_type}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return null;
                                }
                            })()}
                        </div>
                    );
                },
            },
            // {
            //   title: 'Wing',
            //   width: 250,
            //   align: 'left',
            //   render: (item: any) => {
            //     return (
            //         <div className={'hcp-details-wrapper'}>
            //           <div className={'hcp-name-tower-wrapper'}>
            //             {item?.facility_wing_details?.name?.length > 20 ? (
            //                 <ToolTipComponent
            //                     tooltip={item?.facility_wing_details?.name}
            //                     showArrow={true}
            //                     position={'top'}
            //                 >
            //                   <div className={'ellipses-for-table-data property-name'}>
            //                     {item?.facility_wing_details?.name}
            //                   </div>
            //                 </ToolTipComponent>
            //             ) : (
            //                 <div className='hcp-name'>
            //                   {item?.facility_wing_details?.name || 'N/A'}
            //                 </div>
            //             )}
            //           </div>
            //         </div>
            //     );
            //   },
            // },
            {
                title: 'Timesheet',
                width: 137,
                align: 'left',
                render: (item: any) => {
                    return (
                        <>
                            {item?.is_timesheet_uploaded && (
                                <div className={'uploaded'}>Uploaded</div>
                            )}
                            {!item?.is_timesheet_uploaded && (
                                <>
                                    <div className={'pending'}>Pending</div>
                                </>
                            )}
                        </>
                    );
                },
            },
            {
                title: "View Details",
                align: 'center',
                width: 130,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent onClick={() => {
                                setOpenDrawer(true);
                                setShiftDetails(item);
                            }}>
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ];
    }, [
        activeTab,
        handleSelectShifts,
        isSelected,
        setOpenDrawer,
        setShiftDetails,
    ]);
};

export const usePendingShiftListColumn = (
    setOpenDrawer: any,
    setShiftDetails: any,
): TableColumnsType => {
    return useMemo(() => {
        return [
            // {
            //   title: 'Title',
            //   fixed: 'left',
            //   // sortable: true,
            //   width: 250,
            //   render: (item: any) => {
            //     return (
            //         <div className={'hcp-details-wrapper'}>
            //           {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
            //           <div className={'hcp-name-tower-wrapper'}>
            //             {(
            //                 CommonService.convertDateFormat2(
            //                     item?.shift_date,
            //                     'MM-DD-YYYY',
            //                 ) +
            //                 ' | ' +
            //                 item?.hcp_type_details?.title +
            //                 ' | ' +
            //                 item?.shift_type
            //             )?.length > 20 ? (
            //                 <ToolTipComponent
            //                     tooltip={
            //                         CommonService.convertDateFormat2(
            //                             item?.shift_date,
            //                             'MM-DD-YYYY',
            //                         ) +
            //                         ' | ' +
            //                         item?.hcp_type_details?.title +
            //                         ' | ' +
            //                         item?.shift_type
            //                     }
            //                     showArrow={true}
            //                     position={'top'}
            //                 >
            //                   <div className={'ellipses-for-table-data property-name'}>
            //                     {CommonService.convertDateFormat2(
            //                             item?.shift_date,
            //                             'MM-DD-YYYY',
            //                         ) +
            //                         ' | ' +
            //                         item?.hcp_type_details?.title +
            //                         ' | ' +
            //                         item?.shift_type}
            //                   </div>
            //                 </ToolTipComponent>
            //             ) : (
            //                 <div className='facility-name'>
            //                   {CommonService.convertDateFormat2(
            //                           item?.shift_date,
            //                           'MM-DD-YYYY',
            //                       ) +
            //                       ' | ' +
            //                       item?.hcp_type_details?.title +
            //                       ' | ' +
            //                       item?.shift_type}
            //                 </div>
            //             )}
            //           </div>
            //         </div>
            //     );
            //   },
            // },
            {
                title: 'Agency Name',
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.agency_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.agency_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.agency_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.agency_details?.name || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Staff  Name',
                width: 250,
                align: 'left',
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            <div className={'hcp-name-tower-wrapper'}>
                                {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)
                                    ?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_details?.first_name +
                                                ' ' +
                                                item?.hcp_details?.last_name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Shift Date',
                //fixed: 'left',
                // sortable: true,
                width: 130,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_date) {
                        return (
                            <>
                                {CommonService.convertDateFormat2(
                                    item?.requirement_details?.shift_date,
                                    'MM-DD-YYYY',
                                )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: 'Shift Time',
                width: 200,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_timings) {
                        return (
                            <>
                                {CommonService.convertMinutesToTime(
                                        item?.requirement_details?.shift_timings?.start_time,
                                    ) +
                                    '-' +
                                    CommonService.convertMinutesToTime(
                                        item?.requirement_details?.shift_timings?.end_time,
                                    )}
                            </>
                        );
                    } else {
                        return (
                            <>
                                {CommonService.convertMinutesToTime(
                                        item?.expected?.shift_start_time,
                                    ) +
                                    '-' +
                                    CommonService.convertMinutesToTime(
                                        item?.expected?.shift_end_time,
                                    )}
                            </>
                        );
                    }
                },
            },
            {
                title: 'Staff Type',
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            <div className={'hcp-name-tower-wrapper'}>
                                {(item?.hcp_type_details?.title)
                                    ?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.hcp_type_details?.title}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_type_details?.title}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_type_details?.title || 'N/A'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Shift Type',
                width:250,
                render: (item: any) => {
                    return (
                        <div className={'d-flex justify-content-center align-items-center'}>
                            {(() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(
                                    (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                                );
                                if (foundShiftType) {
                                    return (
                                        <>
                                            <div className={'mrg-right-5 mrg-top-5'}>
                                                {foundShiftType.icon}
                                            </div>
                                            <div className={foundShiftType.color}>
                                                {item?.shift_type}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return null;
                                }
                            })()}
                        </div>
                    );
                },
            },
            // {
            //   title: 'Wing',
            //   width: 250,
            //   align: 'left',
            //   render: (item: any) => {
            //     return (
            //         <div className={'hcp-details-wrapper'}>
            //           <div className={'hcp-name-tower-wrapper'}>
            //             {item?.wing_details?.name?.length > 20 ? (
            //                 <ToolTipComponent
            //                     tooltip={item?.wing_details?.name}
            //                     showArrow={true}
            //                     position={'top'}
            //                 >
            //                   <div className={'ellipses-for-table-data property-name'}>
            //                     {item?.wing_details?.name}
            //                   </div>
            //                 </ToolTipComponent>
            //             ) : (
            //                 <div className='hcp-name'>
            //                   {item?.wing_details?.name || 'N/A'}
            //                 </div>
            //             )}
            //           </div>
            //         </div>
            //     );
            //   },
            // },
            {
                title: "View Details",
                align: 'center',
                width: 130,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent onClick={() => {
                                setOpenDrawer(true);
                                setShiftDetails(item);
                            }}>
                                <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ];
    }, [setOpenDrawer, setShiftDetails]);
};
