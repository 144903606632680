import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import NotFoundScreen from "../screens/not-found/notFoundScreen";
import AuthLayout from "../layouts/auth-layout/AuthLayout";
import LoginScreen from "../screens/auth/login/LoginScreen";
import AppLayout from "../layouts/app-layout/AppLayout";
import ComingSoonScreen from "../screens/coming-soon/ComingSoonScreen";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../store/reducers";
import {CommonService} from "../shared/services";
import {
    ADD_AGENCY,
    AGENCY,
    AGENCY_DETAILS,
    AGENCY_LIST,
    COMING_SOON_ROUTE,
    DASHBOARD,
    FORGOT_PASSWORD_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    ADD_HCP,
    SHIFT_REQUIREMENT,
    SHIFT_REQUIREMENT_LIST,
    ADD_SHIFT_REQUIREMENT,
    SHIFT_REQUIREMENT_VIEW_DETAILS,
    FACILITY,
    FACILITY_lIST,
    SCHEDULER,
    SCHEDULER_CALENDER_VIEW,
    USER_MANAGEMENT,
    USER_MANAGEMENT_LIST,
    ADD_USER_MANAGEMENT,
    HCP_FAVORITE,
    HCP_MANAGEMENT_FAVORITE_LIST,
    HCP_MANAGEMENT, HCP_MANAGEMENT_LIST, HCP_VIEW_DETAILS

} from "../constants/RoutesConfig";
import DashboardScreen from "../screens/dashboard/DashboardScreen";
import AddLayout from "../layouts/add-layout/AddLayout";
import ForgotPasswordComponent from "../screens/auth/forgot-password/ForgotPasswordComponent";
import AgencyComponent from "../screens/agency/AgencyComponent";
import AgencyListComponent from "../screens/agency/agency-list/AgencyListComponent";
import AgencyDetailsScreen from "../screens/agency/agency-details/AgencyDetailsComponent";
import AddAgencyComponent from "../screens/agency/add-agency/AddAgencyComponent";
// import HcpManagementComponent from "../screens/hcp-management/HcpManagementComponent";
// import HcpManagementListComponent from "../screens/hcp-management/hcp-management-list/HcpManagementListComponent";
import AddHcpComponent from "../screens/hcp-management/hcp-management-add/AddHcpComponent";
import ShiftRequirementComponent from "../screens/shift-requirement/ShiftRequirementComponent";
import ShiftRequirementList from "../screens/shift-requirement/shift-requirenment-list/ShiftRequirementList";
import AddShiftRequirementComponent from "../screens/shift-requirement/add-new-shift/AddShiftRequirementComponent";
import ShiftRequirementViewDetailsComponent
    from "../screens/shift-requirement/shift-details/ShiftRequirementViewDetailsComponent";
import FacilityListComponent from "../screens/facility/facility-list/FacilityListComponent";
import FacilityComponent from "../screens/facility/FacilityComponent";
import SchedulerComponent from "../screens/scheduler/SchedulerComponent";
import SchedulerCalenderComponent from "../screens/scheduler/scheduler-calender/SchedulerCalenderComponent";
import UserManagementComponent from "../screens/user-management/UserManagementComponent";
import UserManagementListComponent from "../screens/user-management/user-management-list/UserManagementListComponent";
import AddUserComponent from "../screens/user-management/add-user/AddUserComponent";
import HcpFavoriteManagementComponent from "../screens/hcp-favourite-list-management/HcpFavoriteManagementComponent";
import HcpFavoriteListComponent
    from "../screens/hcp-favourite-list-management/hcp-Favorite-list/HcpFavoriteListComponent";
import HcpManagementComponent from "../screens/hcp-management/HcpManagementComponent";
import HcpManagementListComponent from "../screens/hcp-management/hcp-management-list/HcpManagementListComponent";
import HcpDetailsComponent from "../screens/hcp-management/hcp-details/HcpDetailsComponent";
import DetailsLayout from "../layouts/details-layout/DetailsLayout";

const ProtectedRoute = (props: React.PropsWithChildren<any>) => {

    const {children} = props;
    const {token} = useSelector((state: IRootReducerState) => state.account);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!token) {
            navigate('/login?returnUrl=' + encodeURIComponent(location.pathname + location.search));
        }
    }, [token, navigate, location]);

    return children;
};

const UnProtectedRoute = (props: React.PropsWithChildren<any>) => {

    const {children} = props;
    const {token} = useSelector((state: IRootReducerState) => state.account);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let returnUrl = CommonService._routeConfig.DefaultRoute();
        if (!!token) {
            const query = CommonService.parseQueryString(location.search);
            if (Object.keys(query).includes('returnUrl')) {
                returnUrl = query.returnUrl;
            }
            navigate(returnUrl);
        }
    }, [token, navigate, location]);

    return children;
};

export interface NavigatorProps {

}

const Navigator = (props: NavigatorProps) => {

    return (
        <>
            <Routes>
                <Route element={<AppLayout/>}>
                    <Route
                        index
                        element={
                            <Navigate to={AGENCY_LIST}/>
                        }
                    />
                    <Route path={DASHBOARD} element={<ProtectedRoute>
                        <DashboardScreen/>
                    </ProtectedRoute>}/>
                    <Route path={FACILITY} element={<FacilityComponent/>}>
                        <Route
                            index
                            element={
                                <Navigate to={FACILITY_lIST}/>
                            }
                        />
                        <Route path={FACILITY_lIST} element={<ProtectedRoute>
                            <FacilityListComponent/>
                        </ProtectedRoute>}/>
                    </Route>
                    <Route path={AGENCY} element={<AgencyComponent/>}>
                        <Route
                            index
                            element={
                                <Navigate to={AGENCY_LIST}/>
                            }
                        />
                        <Route path={AGENCY_LIST} element={<ProtectedRoute>
                            <AgencyListComponent/>
                        </ProtectedRoute>}/>
                    </Route>
                    <Route path={HCP_FAVORITE} element={<HcpFavoriteManagementComponent/>}>
                        <Route
                            index
                            element={
                                <Navigate to={HCP_MANAGEMENT_FAVORITE_LIST}/>
                            }
                        />
                        <Route path={HCP_MANAGEMENT_FAVORITE_LIST} element={<ProtectedRoute>
                            <HcpFavoriteListComponent/>
                        </ProtectedRoute>}/>
                    </Route>
                    <Route path={USER_MANAGEMENT} element={<UserManagementComponent/>}>
                        <Route
                            index
                            element={
                                <Navigate to={USER_MANAGEMENT_LIST}/>
                            }
                        />
                        <Route path={USER_MANAGEMENT_LIST} element={<ProtectedRoute>
                            <UserManagementListComponent/>
                        </ProtectedRoute>}/>
                    </Route>
                    <Route path={SCHEDULER} element={<SchedulerComponent/>}>
                        <Route
                            index
                            element={
                                <Navigate to={SCHEDULER_CALENDER_VIEW}/>
                            }
                        />
                        <Route path={SCHEDULER_CALENDER_VIEW} element={<ProtectedRoute>
                            <SchedulerCalenderComponent/>
                        </ProtectedRoute>}/>
                    </Route>
                    <Route path={SHIFT_REQUIREMENT} element={<ShiftRequirementComponent/>}>
                        <Route
                            index
                            element={
                                <Navigate to={SHIFT_REQUIREMENT_LIST}/>
                            }
                        />
                        <Route path={SHIFT_REQUIREMENT_LIST} element={<ProtectedRoute>
                            <ShiftRequirementList/>
                        </ProtectedRoute>}/>
                    </Route>

                    <Route path={HCP_MANAGEMENT} element={<HcpManagementComponent/>}>
                        <Route index element={
                            <Navigate to={HCP_MANAGEMENT_LIST}/>
                        }/>
                        <Route path={HCP_MANAGEMENT_LIST} element={<ProtectedRoute>
                            <HcpManagementListComponent/>
                        </ProtectedRoute>}/>
                    </Route>
                </Route>

                <Route element={<AddLayout/>}>

                    <Route path={ADD_USER_MANAGEMENT} element={<ProtectedRoute>
                        <AddUserComponent/>
                    </ProtectedRoute>}/>
                    <Route path={ADD_AGENCY} element={<ProtectedRoute>
                        <AddAgencyComponent/>
                    </ProtectedRoute>}/>
                    <Route path={ADD_HCP} element={<ProtectedRoute>
                        <AddHcpComponent/>
                    </ProtectedRoute>}/>
                    <Route path={ADD_SHIFT_REQUIREMENT} element={<ProtectedRoute>
                        <AddShiftRequirementComponent/>
                    </ProtectedRoute>}/>
                </Route>

                <Route element={<DetailsLayout/>}>
                    <Route path={HCP_VIEW_DETAILS + '/:hcp_id'} element={
                        <ProtectedRoute>
                            <HcpDetailsComponent/>
                        </ProtectedRoute>
                    }/>
                    <Route path={`${AGENCY_DETAILS}/:agencyId`} element={
                        <ProtectedRoute>
                            <AgencyDetailsScreen/>
                        </ProtectedRoute>
                    }/>
                    <Route path={SHIFT_REQUIREMENT_VIEW_DETAILS + `/:shift_type/:requirementId`} element={
                        <ProtectedRoute>
                            <ShiftRequirementViewDetailsComponent/>
                        </ProtectedRoute>
                    }/>
                    {/*<Route path={`${AGENCY_DETAILS}/:agencyId`} element={*/}
                    {/*    <ProtectedRoute>*/}
                    {/*        <AgencyDetailsScreen/>*/}
                    {/*    </ProtectedRoute>*/}
                    {/*}/>*/}
                    {/*<Route path={SHIFT_REQUIREMENT_VIEW_DETAILS + `/:shift_type/:requirementId`} element={*/}
                    {/*    <ProtectedRoute>*/}
                    {/*        <ShiftRequirementViewDetailsComponent/>*/}
                    {/*    </ProtectedRoute>*/}
                    {/*}/>*/}
                    {/*<Route path={HCP_VIEW_DETAILS + '/:hcp_id'} element={*/}
                    {/*    <ProtectedRoute>*/}
                    {/*        <HcpDetailsComponent/>*/}
                    {/*    </ProtectedRoute>*/}
                    {/*}/>*/}
                </Route>

                <Route path={COMING_SOON_ROUTE} element={<ComingSoonScreen/>}/>
                <Route element={<AuthLayout/>}>
                    <Route index
                           element={
                               <UnProtectedRoute>
                                   <LoginScreen/>
                               </UnProtectedRoute>
                           }/>
                    <Route
                        path={LOGIN_ROUTE}
                        element={
                            <UnProtectedRoute>
                                <LoginScreen/>
                            </UnProtectedRoute>
                        }

                    />
                    <Route path={FORGOT_PASSWORD_ROUTE} element={
                        <UnProtectedRoute>
                            <ForgotPasswordComponent/>
                        </UnProtectedRoute>
                    }/>
                </Route>
                <Route path={NOT_FOUND_ROUTE} element={<NotFoundScreen/>}/>
                <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE}/>}/>
            </Routes>
        </>
    );

};

export default Navigator;
