import {ApiService} from "../index";
import {APIConfig} from "../../../constants";


//vars-health

const OnboardHcpAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ONBOARD_HCP.METHOD](APIConfig.ONBOARD_HCP.URL, payload);
};

const HcpDetailsAPICall = (agencyId: string, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DETAILS.METHOD](APIConfig.HCP_DETAILS.URL(agencyId), payload);
};

const HcpDocumentAPICall = (hcpId: string, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DOCUMENTS.METHOD](APIConfig.HCP_DOCUMENTS.URL(hcpId), payload);
};

const MarkHCPAsFavorite = (hcpId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.MARK_HCP_AS_FAVORITE.METHOD](APIConfig.MARK_HCP_AS_FAVORITE.URL(hcpId), payload);
};

const RemoveAsFavorite = (hcpId: any) => {
    //@ts-ignore
    return ApiService[APIConfig.REMOVE_AS_FAVORITE.METHOD](APIConfig.REMOVE_AS_FAVORITE.URL(hcpId));
};

const uploadHcpDocumentAPICall = (hcpId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.UPLOAD_HCP_DOCUMENT.METHOD](APIConfig.UPLOAD_HCP_DOCUMENT.URL(hcpId), payload);
};
const FacilityHcpContractUploadAPICall = (hcpId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.CONTRACT_UPLOAD_HCP.METHOD](APIConfig.CONTRACT_UPLOAD_HCP.URL(hcpId), payload);
};
const EditHCPDetailsAPICall = (hcpId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_HCP_DETAILS.METHOD](APIConfig.EDIT_HCP_DETAILS.URL(hcpId), payload);
};
// const DeleteHcpDocumentAPICall = (payload: any) => {
//     //@ts-ignore
//     return ApiService[APIConfig.HCP_DOCUMENT_DELETE.METHOD](APIConfig.HCP_DOCUMENT_DELETE.URL, payload);
// };

const DeleteHcpDocumentAPICall = (hcpId: any, documentId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DOCUMENT_DELETE.METHOD](APIConfig.HCP_DOCUMENT_DELETE.URL(hcpId, documentId), payload);
};

const DownloadHcpDocumentAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DOCUMENT_DOWNLOAD.METHOD](APIConfig.HCP_DOCUMENT_DOWNLOAD.URL, payload);
};
const downloadHCPDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_HCP_DATA_CSV.METHOD](APIConfig.DOWNLOAD_HCP_DATA_CSV.URL, payload);
};

const downloadInternalHCPDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_INTERNAL_HCP_DATA_CSV.METHOD](APIConfig.DOWNLOAD_INTERNAL_HCP_DATA_CSV.URL, payload);
};
const HcpListRequirementBasedAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_LIST_REQUIREMENT_BASED.METHOD](APIConfig.HCP_LIST_REQUIREMENT_BASED.URL, payload);
};

const ActivateDeactivateHCP = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_HCP.METHOD](APIConfig.TOGGLE_HCP.URL(facilityId), payload);
};

const HcpManagementService = {
    OnboardHcpAPICall,
    HcpDetailsAPICall,
    HcpDocumentAPICall,
    MarkHCPAsFavorite,
    RemoveAsFavorite,
    uploadHcpDocumentAPICall,
    FacilityHcpContractUploadAPICall,
    EditHCPDetailsAPICall,
    DeleteHcpDocumentAPICall,
    DownloadHcpDocumentAPICall,
    downloadHCPDataCSVAPICall,
    HcpListRequirementBasedAPICall,
    downloadInternalHCPDataCSVAPICall,
    ActivateDeactivateHCP
};


export default HcpManagementService;
