import "./AddWingsComponent.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {ImageConfig, Misc} from "../../../../constants";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
// import {useSelector} from "react-redux";
// import {IRootReducerState} from "../../../../store/reducers";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {v4 as uuidv4} from "uuid";
import LinkComponent from "../../../../shared/components/link/LinkComponent";

interface AddWingsComponentProps {
    closeEditDrawer: any;
    getFacilityDetails?: any;
}

const AddWingValidationSchema = Yup.object().shape({
    // name: Yup.string().required('Wing name is required'),
});


interface AddWingFormInitialValuesFormSchema {
    wings: {
        name: any;
    };
}

const AddWingFormInitialValues: AddWingFormInitialValuesFormSchema = {
    wings: {
        name: "",
    },
};


const AddWingComponent = (props: AddWingsComponentProps) => {
    const {closeEditDrawer, getFacilityDetails} = props;
    const [initialValues] = useState<AddWingFormInitialValuesFormSchema>(AddWingFormInitialValues);
    // const {wingsList} = useSelector((state: IRootReducerState) => state.staticData);
    const [wingNameList, setWingNameList] = useState<any>([]);
    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
            wings: wingNameList,
        };
        let apiCall: any = {};
        apiCall = CommonService._facility.AddWingAPICall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getFacilityDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getFacilityDetails, wingNameList]);

    // const onShiftTimingSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
    //     setIsAddShiftTimingsButton(false);
    //     const uniqueId: string = uuidv4();
    //     const newShiftTime = {
    //         ...values.shift_timings,
    //         shift_start_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_start_time),
    //         shift_end_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_end_time),
    //         // Spread existing properties from values.shift_timings
    //         id: uniqueId,           // Add the new key-value pair
    //     };
    //     setShiftTimeList([...shiftTimeList, newShiftTime]);
    //     setIsAddShiftTimingsButton(true);
    // }, [shiftTimeList]);


    return (
        <>
            <div className={'wing-add-facility-component'} style={{minWidth: "200px"}}>
                <div className={'edit-wing-header'}>
                    <div className={'form-header-text'}>Add Wings Details</div>
                    <div onClick={closeEditDrawer}><ImageConfig.CrossIcon height={26} width={26}/>
                    </div>
                </div>
                <div className={'mrg-bottom-10'}>
                    <HorizontalLineComponent/>
                </div>
                <div className={'edit-wing-form-wrapper'}>
                    <Formik
                        validationSchema={AddWingValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, setErrors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder pdd-10" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container mrg-bottom-10'}>
                                            <div className="edit-component-content d-flex">
                                                <Field name={'wings.name'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikInputComponent
                                                                label={'Wing Name'}
                                                                placeholder={'Enter Wing Name'}
                                                                type={"text"}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div
                                                    className={'mrg-top-37 mrg-left-5'}>
                                                    <ButtonComponent
                                                        variant={'outlined'}
                                                        style={{
                                                            width: 0,
                                                            height: 0,
                                                            border: 0,
                                                            borderRadius: '100%',
                                                            backgroundColor: "#fff"
                                                        }}
                                                        disabled={values.wings.name === ''}
                                                        onClick={() => {
                                                            const uniqueId: string = uuidv4();
                                                            const newWingName = {
                                                                name: values.wings.name,
                                                                id: uniqueId,
                                                            };
                                                            setWingNameList([...wingNameList, newWingName]);
                                                            setFieldValue('wings.name', '');

                                                        }}
                                                    >
                                                        <ImageConfig.SaveIcon width={35} height={35}/>
                                                    </ButtonComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'edit-add-wing-list-wrapper'}>
                                        {
                                            wingNameList?.length > 0 && wingNameList.map((wingName: any, index: number) => {
                                                return (
                                                    <div
                                                        className={'d-flex justify-content-space-between align-items-center wing-details-holder'}>
                                                        <div className="facility-add-wing-text">
                                                            {wingName?.name}
                                                        </div>
                                                        <LinkComponent onClick={() => {
                                                            // Filter the list to exclude the item with the specified ID
                                                            const updatedWingNameList = wingNameList.filter((setId: any) => setId.id !== wingName?.id);
                                                            setWingNameList(updatedWingNameList);
                                                        }
                                                        }>
                                                            <ImageConfig.DeleteIcon/>
                                                        </LinkComponent>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className="add-wing-form-action-button">
                                        <ButtonComponent
                                            disabled={isSubmitting || wingNameList.length === 0}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default AddWingComponent;




