import React, {useMemo} from 'react';
import {CommonService} from "../../../shared/services";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {ImageConfig} from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import {TableColumnsType} from "antd";

export const useAgencyListColumn = (onAgencyActivateDeactivate: any): TableColumnsType => {
    return useMemo(() => {
        return [
            {
                title:"Agency Name",
                fixed: 'left',
                width: 250,
                render: (item: any) => {
                    return <div className={'agency-details-wrapper'}>
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Location",
                width: 250,
                render: (item: any) => {
                    return <div className={'agency-details-wrapper'}>
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.address?.city)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.address?.city}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.address?.city}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.address?.city || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Onboarded On",
                width: 137,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>
                            <div className={'mrg-left-5'}>
                                {CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}
                            </div>
                        </>;
                    } else {
                        return <>
                            <div className={'mrg-left-5'}>
                                N/A
                            </div>
                        </>;
                    }
                }
            },
            {
                title:"Admin Name",
                width:250,
                render: (item: any) => {
                    return <div className={'agency-details-wrapper mrg-left-5'}>
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.admin_details?.first_name + " " + item?.admin_details?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.admin_details?.first_name + " " + item?.admin_details?.last_name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Admin Contact No",
                width: 200,
                render: (item: any) => {
                    return <>
                        <div className={'mrg-left-5'}>
                            {CommonService.formatPhoneNumber(item?.admin_details?.contact_number?.replace(/^\+1/, '')) || 'N/A'}
                        </div>
                    </>;
                }
            },
            {
                title: 'Active/Inactive',
                width: 150,
                render: (item: any) => {
                    return <div className={'display-center'}>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onAgencyActivateDeactivate(item);
                        }}/>
                    </div>;
                }
            },
            {
                title: "View Details",
                width: 130,
                fixed: 'right',
                align:'center',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent
                            route={CommonService._routeConfig.AgencyDetailsRoute(item?.admin_details?.agency_id)}>
                            <ImageConfig.ArrowRightOutlinedIcon style={{color: "#183258"}}/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onAgencyActivateDeactivate]);
};