import {useLocation, useNavigate} from "react-router-dom";
import {useCallback} from "react";
import "./AddUserComponent.scss";
import {CommonService} from "../../../shared/services";
import UserDetailsComponent from "./AddUserDetailsComponent";

interface AddUserComponentProps {

}

const AddUserComponent = (props: AddUserComponentProps) => {
    const navigate = useNavigate();
    const location: any = useLocation();
    const path = location.pathname;

    const handleBack = useCallback(() => {
        if (path.includes('/user-management/add')) {
            navigate(CommonService._routeConfig.UserManagementRoute());
        }
    }, [navigate, path]);

    return (
        <>
            <div className={'add-user-component'}>
                <>
                    <UserDetailsComponent
                        prev={handleBack}
                    />
                </>
            </div>
        </>
    );
};

export default AddUserComponent;