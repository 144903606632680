import {ApiService} from "../index";
import {APIConfig} from "../../../constants";

const hcpTypesListAPICall = () => {
    //@ts-ignore
    return ApiService[APIConfig.GET_HCP_TYPES.METHOD](APIConfig.GET_HCP_TYPES.URL);
};

const stateListAPICall = () => {
    //@ts-ignore
    return ApiService[APIConfig.GET_STATE_LIST_API.METHOD](APIConfig.GET_STATE_LIST_API.URL);
};


const additionalRatesListAPICall = () => {
    //@ts-ignore
    return ApiService[APIConfig.GET_ADDITIONAL_RATES_TYPE.METHOD](APIConfig.GET_ADDITIONAL_RATES_TYPE.URL);
}

const addNewHcpTypeAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.ADD_NEW_HCP_TYPE.METHOD](APIConfig.ADD_NEW_HCP_TYPE.URL, payload);
}

const addNewAdditionalRateAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.ADD_NEW_ADDITIONAL_RATE.METHOD](APIConfig.ADD_NEW_ADDITIONAL_RATE.URL, payload);
}
const DeleteDocumentAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.DOCUMENT_DELETE.METHOD](APIConfig.DOCUMENT_DELETE.URL, payload);
};

const DownloadDocumentAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.DOCUMENT_DOWNLOAD.METHOD](APIConfig.DOCUMENT_DOWNLOAD.URL, payload);
};

const MetaDataService = {
    hcpTypesListAPICall,
    stateListAPICall,
    additionalRatesListAPICall,
    addNewHcpTypeAPICall,
    addNewAdditionalRateAPICall, DeleteDocumentAPICall, DownloadDocumentAPICall

};
export default MetaDataService;
