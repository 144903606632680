import './SelectDropdownComponent.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, { InputActionMeta, StylesConfig } from 'react-select';
import { IAPIResponseType } from '../../../models/api.model';
import _ from 'lodash';
import { AXIOS_REQUEST_CANCELLED } from '../../../services/api.service';
import { CommonService } from '../../../services';
import LabelComponent from '../../label/LabelComponent';
import ErrorTextComponent from '../../error-text/ErrorTextComponent';
import HelperTextComponent from '../../helper-text/HelperTextComponent';
import { ISelectDropdownProps } from '../../../models/form-controls.model';
import { ImageConfig } from '../../../../constants';
import { MenuPlacement } from '../../../models/common.model';

export interface SelectDropdownComponentProps extends ISelectDropdownProps {
  value?: any;
  hasError?: boolean;
  errorMessage?: any;
  navigation?: () => void;
  buttonRequired?: boolean;
  noDataFoundText?: string;
}

const SelectDropdownComponent = (props: SelectDropdownComponentProps) => {
  const {
    className,
    label,
    value,
    hasError,
    required,
    errorMessage,
    onUpdate,
    onSelectUpdate,
    disabled,
    id,
    options,
    url,
    extraPayload,
    navigation,
    buttonRequired,
    // noDataFoundText,
  } = props;

  let {
    placeholder,
    noDataMessage,
    searchable,
    searchMode,
    multiple,
    method,
    isClearable,
    fullWidth,
    dataListKey,
    hideSelectedOptions,
    isDataLoading,
    isDataLoaded,
    isDataLoadingFailed,
    defaultData,
    displayWith,
    valueExtractor,
  } = props;

  const size = props.size ? props.size : 'medium';
  if (!placeholder) placeholder = label ? label : 'Select';
  if (!method) method = 'get';
  const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>('bottom');
  if (!searchMode) searchMode = 'clientSide';
  if (!dataListKey) dataListKey = 'data.docs';
  if (isClearable === undefined) isClearable = true;
  if (searchable === undefined) searchable = false;
  if (multiple === undefined) multiple = false;
  if (fullWidth === undefined) fullWidth = true;
  if (!displayWith) displayWith = (option: any) => option?.title;
  if (!valueExtractor) valueExtractor = (option: any) => option?.code;
  if (!noDataMessage)
    noDataMessage = (
      <div>
        <div>No Data</div>
        {/* <div className={'mrg-top-55 mrg-bottom-30'}>
            <div className={'mrg-bottom-10'}><ImageConfig.EmptyIcon width={24} height={24}/></div>
            <div>
                {noDataFoundText}
            </div>
        </div> */}
        {buttonRequired && (
          <div className={'center-element'} onClick={navigation}>
            <span className={'center-element add-button-text icon-button'}>
              <ImageConfig.AddOutlinedIcon className={'mrg-right-10'} />
              Add New Agency
            </span>
          </div>
        )}
      </div>
    );
  if (!defaultData) defaultData = [];

  const [isDropDownDataLoading, setIsDropDownDataLoading] =
    useState(isDataLoading);
  const [isDropDownDataLoaded, setIsDropDownDataLoaded] =
    useState(isDataLoaded);
  const [isDropDownDataLoadingFailed, setIsDropDownDataLoadingFailed] =
    useState(isDataLoadingFailed);
  const [dropDownData, setDropDownData] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const APICallSubscription = useRef<any>(null);
  const [renderList, setRenderList] = useState<any>([]);

  const onValueChange = useCallback(
    (value: any) => {
      console.log(value);
      if (onUpdate) {
        onUpdate(value);
      }
    },
    [onUpdate],
  );

  const customStyles: StylesConfig = {
    control: (styles, state) => ({
      ...styles,
      height: '40px',
      borderRadius: state?.isFocused ? '10px' : '10px',
      border: state?.isFocused ? '1px solid #182A39' : '',
      boxShadow: '1px #182A39',
      ':hover': {
        borderColor: '#182A39',
      },
      cursor: 'pointer',
      backgroundColor: state?.hasValue ? '#FAFAFA' : '',
      // Change this color to your desired focused border color
    }),
    option: (base: any, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? '#F3FEFF' : '',
      opacity: !isSelected ? '0.5' : '',
      color: isSelected ? '#4E535F' : '',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#F3FEFF',
      },
      ':active': {
        backgroundColor: 'red',
      },
    }),
  };

  const onBlur = useCallback(() => {
    if (onSelectUpdate) {
      onSelectUpdate();
    }
  }, [onSelectUpdate]);

  const transformRenderList = useCallback(
    (list: any) => {
      if (list?.length > 0) {
        const options =
          list &&
          list?.map((item: any) => {
            return {
              label:
                displayWith && displayWith(item) !== undefined
                  ? displayWith(item)
                  : item.label,
              value:
                valueExtractor && valueExtractor(item) !== undefined
                  ? valueExtractor(item)
                  : item.code,
              ...item,
            };
          });
        setRenderList(options);
      } else {
        setRenderList([]);
      }
    },
    [displayWith, valueExtractor],
  );

  useEffect(() => {
    if (renderList) {
      if (multiple) {
        setSelectedValue(
          renderList?.filter((item: any) => value?.includes(item?.value)),
        );
      } else {
        setSelectedValue(
          renderList?.find((item: any) => item?.value === value),
        );
      }
    } else {
      setSelectedValue(null);
    }
  }, [value, multiple, renderList]);

  useEffect(() => {
    const handleMenuPosition = () => {
      const selectWrapper = document.querySelector(
        '.select-dropdown-component',
      );
      const selectDropdown = document.querySelector('.select-dropdown');
      if (selectWrapper && selectDropdown) {
        const selectRect = selectWrapper.getBoundingClientRect();
        const spaceBelow = window.innerHeight - selectRect.bottom;
        const spaceAbove = selectRect.top;
        if (
          spaceBelow < selectDropdown.clientHeight &&
          spaceAbove >= selectDropdown.clientHeight
        ) {
          setMenuPlacement('bottom');
        } else {
          setMenuPlacement('bottom');
        }
      }
    };

    window.addEventListener('resize', handleMenuPosition);
    handleMenuPosition();

    return () => {
      window.removeEventListener('resize', handleMenuPosition);
    };
  }, []);

  useEffect(() => {
    setIsDropDownDataLoading(isDataLoading);
  }, [isDataLoading]);

  useEffect(() => {
    setIsDropDownDataLoaded(isDataLoaded);
  }, [isDataLoaded]);

  useEffect(() => {
    setIsDropDownDataLoadingFailed(isDataLoadingFailed);
  }, [isDataLoadingFailed]);

  useEffect(() => {
    // setNoDataMsg(noDataMessage);
  }, [noDataMessage]);

  useEffect(() => {
    //TODO: make it more fool proof
    setDropDownData(options);
    transformRenderList(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (searchMode === 'serverSide') {
      if (dropDownData?.length === 0) {
      }
    }
  }, [searchMode, dropDownData]);

  const getDataList = useCallback(
    (searchValue: string) => {
      if (!url) {
        console.warn('URL not provided to fetch dropdown list');
        return;
      }
      if (!method) {
        console.warn('METHOD not provided to fetch dropdown list');
        return;
      }
      const finalPayload = { ...extraPayload, search: searchValue };
      const cancelTokenSource = CommonService.getCancelToken();
      let request;
      if (method === 'get') {
        request = CommonService._api.get;
      } else {
        request = CommonService._api.post;
      }
      if (APICallSubscription && APICallSubscription.current) {
        APICallSubscription.current.cancel();
      }
      APICallSubscription.current = cancelTokenSource;
      setIsDropDownDataLoading(true);
      setIsDropDownDataLoaded(false);
      setIsDropDownDataLoadingFailed(false);
      let dropDownData: any[] = [...(defaultData || [])];
      request(url, finalPayload, {}, { cancelToken: cancelTokenSource.token })
        .then((response: IAPIResponseType<any>) => {
          if (dataListKey && _.get(response, dataListKey)) {
            dropDownData.unshift(..._.get(response, dataListKey));
          }
          setDropDownData(dropDownData);
          transformRenderList(dropDownData);
          setIsDropDownDataLoading(false);
          setIsDropDownDataLoaded(true);
          setIsDropDownDataLoadingFailed(false);
        })
        .catch((error: any) => {
          if (error.reason !== AXIOS_REQUEST_CANCELLED) {
            // if previous request got cancelled do not close loading state
            setDropDownData(dropDownData);
            transformRenderList(dropDownData);
            setIsDropDownDataLoading(false);
            setIsDropDownDataLoaded(false);
            setIsDropDownDataLoadingFailed(true);
          }
        });
    },
    [defaultData, transformRenderList, url, dataListKey, method, extraPayload],
  );

  const handleInputChange = useCallback(
    (newValue: string, actionMeta: InputActionMeta) => {
      const { action } = actionMeta;
      switch (action) {
        case 'input-change': {
          if (searchMode === 'serverSide') {
            getDataList(newValue);
          }
          break;
        }
        default:
          void 0;
      }
    },
    [searchMode, getDataList],
  );

  return (
    <div
      className={`select-dropdown-component ${searchable ? 'search' : ''} ${
        fullWidth ? 'fullWidth' : ''
      } ${disabled ? 'disabled' : ''} ${hasError ? 'has-error' : ''}  ${size}`}
    >
      {label && <LabelComponent title={label || ''} required={required} />}
      <Select
        id={id}
        className={`select-dropdown ${className}`}
        classNamePrefix={'select-dropdown'}
        closeMenuOnSelect={!multiple}
        isMulti={multiple}
        hideSelectedOptions={hideSelectedOptions}
        options={renderList}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        isLoading={isDropDownDataLoading}
        isClearable={isClearable}
        value={selectedValue || null}
        isSearchable={searchable}
        onBlur={onBlur}
        styles={customStyles}
        filterOption={(option: any, inputValue: string) => {
          const { label } = option;
          if (searchMode === 'serverSide') {
            return true;
          } else {
            if (searchable && inputValue.length > 0) {
              if (dropDownData) {
                const otherKey = dropDownData?.filter(
                  (opt: any) =>
                    opt?.label?.toLowerCase() === label.toLowerCase() &&
                    opt?.label
                      .toLowerCase()
                      ?.includes(inputValue.toLowerCase()),
                );
                return (
                  label.toLowerCase()?.includes(inputValue.toLowerCase()) ||
                  otherKey?.length > 0
                );
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        }}
        noOptionsMessage={() => {
          return noDataMessage;
        }}
        onInputChange={handleInputChange}
        onChange={(value) => {
          console.log(value);
          return value !== undefined
            ? multiple
              ? onValueChange(value?.map((item: any) => item.value))
              : onValueChange(value?.value)
            : onValueChange(null);
        }}
        menuPortalTarget={document.body}
        // theme={(theme) => ({
        //     ...theme,
        //     colors: {
        //         ...theme.colors,
        //         primary25: "#dddddd",
        //         primary: ColorConfig.info,
        //         zIndex: 9999
        //     },
        // })}
      />
      {errorMessage && hasError && <ErrorTextComponent error={errorMessage} />}
      {isDropDownDataLoading && !isDropDownDataLoaded && (
        <HelperTextComponent message={'Data loading'} />
      )}
      {isDropDownDataLoadingFailed && (
        <HelperTextComponent
          type={'error'}
          message={'Error loading the data'}
        />
      )}
    </div>
  );
};

export default SelectDropdownComponent;
