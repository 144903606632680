import './RadioButton.scss';
import {IRadioButtonProps} from "../../../models/form-controls.model";
import {useCallback} from "react";
import LabelComponent from "../../label/LabelComponent";

interface RadioButtonComponentProps extends IRadioButtonProps {

}

const RadioButtonComponent = (props: RadioButtonComponentProps) => {

    const {label, checked, name, disabled, onChange, value} = props;

    const handleRadioOnChange = useCallback((event: any) => {
        const selectedValue = event.target.value;
        if (onChange) {
            onChange(selectedValue);
        }
    }, [onChange]);

    return (
        <div className={`radio-component ${checked ? 'checked' : ''}`}>
            <label className={`radio-container ${(disabled ? 'disable' : '')}`}>
                {label && <LabelComponent title={label || ''}/>}
                <input
                    value={value}
                    onChange={handleRadioOnChange}
                    disabled={disabled}
                    name={name}
                    checked={checked}
                    className={'radio-field'}
                    type="radio"
                />
                <span className="radio-checkmark"/>
            </label>
        </div>
    )
}

export default RadioButtonComponent;
