import React from "react";
import {Outlet} from "react-router-dom";
import "./DetailsLayout.scss";

export interface AddLayoutProps {

}

const DetailsLayout = (props: AddLayoutProps) => {
    return (
        <div className="details-layout">
            <div className="details-layout-content-holder">
                <Outlet/>
            </div>
        </div>
    );
};


export default DetailsLayout;




